import axios from 'axios';
import React, { createContext, useContext, useEffect, useState } from 'react';
import Cookies from "js-cookie";
import { capitalize, getWithExpiry } from './utils';
import alert from '../asset/sound/alert.mp3'
import error from '../asset/sound/error.mp3'
import button from '../asset/sound/button.mp3'
import show from '../asset/sound/show.mp3'
import goodbye from '../asset/sound/goodbye.mp3'
import LZString from 'lz-string'

const MyContext = createContext();

export const GlobalData = ({ children }) => {
    const [cityOpt, setCityOpt] = useState(null)
    const [provOpt, setProvOpt] = useState(null)
    const [warehouseOpt, setWarehouseOpt] = useState([])
    const [showroomOpt, setShowroomOpt] = useState([])
    const [supplierOpt, setSupplierOpt] = useState([])
    const [entityOpt, setEntityOpt] = useState([])
    const [deptOpt, setDeptOpt] = useState([])
    const userProfile = getWithExpiry('_erp')
    ? JSON.parse(LZString.decompressFromBase64(getWithExpiry('_erp')))
    : null
    const timestamp = new Date()
    const [brandOpt, setBrandOpt] = useState([])
    const tokenX = LZString.compressToBase64('edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107');
    
    const getDept = async () => {
        await axios({
            method: 'GET',
            url: `${host}/ledger/index.php?action=getDept`,
            headers:{
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            }
        }).then (res => {
            if (res.data.status === 200){
                setDeptOpt(res.data.data.map(i => (
                    {
                        key:i.id,
                        value:i.id,
                        text:`${i.dept_code} - ${i.dept_name}`
                    }
                )))
            }
        })
    }

    const getEntity = async () => {
        await axios({
            method: 'GET',
            url: `${host}/finance/index.php?action=getEntity`,
            headers:{
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            }
        }).then (res => {
            if (res.data.status === 200){
                setEntityOpt(res.data.data.map(i => (
                    {
                        key:i.id,
                        value:i.id,
                        text:`${i.entity_code} - ${i.entity_name}`
                    }
                )))
            }
        })
    }

    const getCityAndProv = async () => {
        await axios({
            method: 'GET',
            url: `${host}/purchasing/index.php?action=cityAndProv`,
            headers:{
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            }
        }).then(res => {
            if (res.data.status === 200) {
                setCityOpt(
                    res.data.data.map((item, i) => (
                        {
                            key: item.id,
                            text: item.city,
                            value: item.id,
                        }
                    ))
                )

                setProvOpt(
                    res.data.data.map((item, i) => (
                        {
                            key: item.id,
                            text: item.province,
                            value: item.id,
                        }
                    ))
                )
            }
        })
    }

    const getVendorData = async () => {
        await axios({
            method: "GET",
            url: `${host}/purchasing/index.php?action=vendorMaster`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            }
        }).then(res => {
            if (res.data.status === 200) {
                setSupplierOpt(res.data.data.map(i => {
                    return { key: i.id_vendor, text: i.name, value: i.id_vendor }
                }))
            }
        })
    }

    const getWarehouseData = async () => {
        await axios({
            method: "GET",
            url: `${host}/showroom/index.php?action=warehouse`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            }
        }).then(res => {
            if (res.data.status === 200) {
                setWarehouseOpt(res.data.data.filter(w => w.type !== 'Damage').map(i => {
                    return { key: i.id_warehouse, text: capitalize(i.desc), value: i.id_warehouse }
                }))
            }
        })
    }

    const getShowroomData = async () => {
        await axios({
            method: "GET",
            url: `${host}/showroom/index.php?action=showroom`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            }
        }).then(res => {
            if (res.data.status === 200) {
                setShowroomOpt(res.data.data.filter(s => s.status === 'Y').map(i => {
                    return { key: i.id_showroom, text: capitalize(i.showroom_name), value: i.id_showroom }
                }))
            }
        })
    }

    const playSound = (soundType) => {
        let audioSound;
        switch (soundType) {
            case 'alert':
                audioSound = alert; break;
            case 'button':
                audioSound = button; break;
            case 'error':
                audioSound = error; break;
            case 'show':
                audioSound = show; break;
            case 'goodbye':
                audioSound = goodbye; break;
            default:
                audioSound = button; break;
        }
        const audio = new Audio(audioSound)
        audio.play()
    }

    const getBrand = () => {
        axios({
            method: "GET",
            url: `${host}/product/index.php?action=brand`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            }
        }).then(res => {
            if (res.data.status === 200) {
                setBrandOpt(res.data.data.map(i => {
                    return { key: i.brand_code, text: (i.brand_code) + ' ' + capitalize(i.description), value: i.brand_code }
                }))
            }
        })
    }


    const IDR = new Intl.NumberFormat('IN', {
        style: 'currency',
        currency: 'IDR',
        minimumFractionDigits: 0,
        currencyDisplay: 'symbol'
    });

    const host = "https://erp.hoopsdevteam.com/api_erp_prod"
    // const host = "http://localhost/api_erp/"

    useEffect(() => {
        getCityAndProv()
        getWarehouseData()
        getShowroomData()
        getVendorData()
        getBrand()
        getEntity()
        getDept()
    }, [])

    return (
        <MyContext.Provider value={{
            IDR,
            host,
            cityOpt,
            provOpt,
            userProfile,
            warehouseOpt,
            showroomOpt,
            supplierOpt,
            brandOpt,
            entityOpt,
            deptOpt,
            playSound,
        }}>
            {children}
        </MyContext.Provider>
    )
}

export const useMyGlobal = () => {
    return useContext(MyContext);
};