import React, { useEffect, useMemo, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import { useMyGlobal } from "../component/Global";
import { Reacttable } from "../component/Reacttable";
import { Button, ButtonGroup, Form, Popup } from "semantic-ui-react";
import Modal from 'rsuite/Modal';
import { Toaster } from "../component/Toaster";
import Toggle from "rsuite/Toggle";
import { capitalize } from "../component/utils"

export const MasterVendor = () => {
    const [dataVendor, setDataVendor] = useState([])
    const { host, cityOpt, provOpt, userProfile } = useMyGlobal()
    const [province, setProvince] = useState('')
    const [city, setCity] = useState('')
    const [name, setName] = useState('')
    const [desc, setDesc] = useState('')
    const [address, setAddress] = useState('')
    const [phone, setPhone] = useState('')
    const [cp, setCp] = useState('')
    const [bankAcc, setBankAcc] = useState('')
    const [bankName, setBankName] = useState('')
    const [isActive, setIsActive] = useState(false)
    const [idVendor, setIdVendor] = useState('')

    const [modalAdd, setModalAdd] = useState(false)
    const [modalEdit, setModalEdit] = useState(false)

    const columns = useMemo(() => [
        {
            Header: "ID",
            accessor: "id_vendor"
        },
        {
            Header: "Name",
            accessor: "name"
        },
        {
            Header: "Desc.",
            accessor: "desc",
            Cell: ({ cell }) => capitalize(cell.value)
        },
        {
            Header: "Address",
            accessor: "address",
            Cell: ({ cell }) => (
                <Popup content={cell.value} size="tiny" trigger={
                    <div style={{ width: '200px' }} className="text-truncate">
                        <div className="text-truncate">
                            <a href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(cell.value)}`} target="_blank" rel="noopener noreferrer" style={{ width: '200px' }} className="text-truncate">
                                {capitalize(cell.value)}
                            </a>
                        </div>
                    </div>
                } />
            )
        },
        {
            Header: "Phone",
            accessor: "phone"
        },
        {
            Header: "Bank Account",
            accessor: "bank_acc"
        },
        {
            Header: "Bank Name",
            accessor: "bank_name",
            Cell: ({ cell }) => capitalize(cell.value)
        },
        {
            Header: "Active",
            accessor: "vendor_state",
            Cell: ({ cell }) => (
                <Toggle size="sm" defaultChecked={cell.value === 'Y' ? true : false} onChange={() => handleChangeStatus(cell.value, cell.row.original.id_vendor)} />
            )
        },
        {
            Header: "Action",
            accessor: null,
            Cell: ({ cell }) => (
                userProfile.profile.isCashier == 0 ? (
                    <ButtonGroup size="mini" basic>
                        <Popup content="Edit" size="mini" trigger={
                            <Button icon={{ name: 'edit', color: 'blue' }} basic onClick={() => handleClickActEdit(cell.row.original)} />
                        } />
                    </ButtonGroup>
                ) : null
            )
        },
        
    ])

const handleChangeStatus = (value, idShowroom) => {
    const status = value === 'Y' ? 'N' : 'Y'
    axios({
        method: "PUT",
        url: `${host}/purchasing/index.php?action=changeStatusVendor`,
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
        },
        data: { id: idShowroom, status: status },
    }).then(res => {
        if (res.data.status === 200) {
            Toaster.fire({
                icon: 'success',
                text: 'Success change status',
                position: 'top-right'
            })
        } else {
            Toaster.fire({
                icon: 'error',
                text: res.data.msg,
                position: 'top-right'
            })
        }
    })
}


const handleClickActEdit = (row) => {
    setModalEdit(true)
    setName(row.name)
    setDesc(row.desc)
    setCity(cityOpt?.find(i => i.value === row.city).value)
    setProvince(row.prov)
    setAddress(row.address)
    setPhone(row.phone)
    setBankAcc(row.bank_acc)
    setBankName(row.bank_name)
    setCp(row.name)
    setIsActive(row.vendor_state === 'Y' ? true : false)
    setIdVendor(row.id_vendor)
}

const getVendorData = async () => {
    await axios({
        method: "GET",
        url: `${host}/purchasing/index.php?action=vendorMaster`,
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
        }
    }).then(res => {
        if (res.data.status === 200) {
            setDataVendor(res.data.data)
        }
    })
}

const handleClickAdd = () => {
    setModalAdd(true)
}

const handleChangeCity = (id) => {
    setCity(id)
    setProvince(provOpt?.find(item => item.value === id).text)
}

const handleSubmit = () => {
    const param = new FormData()
    param.append('name', name)
    param.append('desc', desc)
    param.append('city', city)
    param.append('prov', province)
    param.append('address', address)
    param.append('phone', phone)
    param.append('cp', cp)
    param.append('bank_acc', bankAcc)
    param.append('bank_name', bankName)
    param.append('active', isActive ? 'Y' : 'N')
    param.append('action', 'addVendor')

    axios({
        method: 'POST',
        url: `${host}/purchasing/index.php?action=addVendor`,
        headers: {
            "Content-Type": "application/application/x-www-form-urlencoded",
            "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
        },
        data: param
    }).then(res => {
        if (res.data.status === 200) {
            Toaster.fire({
                icon: 'success',
                text: 'Success add new vendor',
                position: 'top-right'
            })
            setModalAdd(false)
            getVendorData()
        } else {
            Toaster.fire({
                icon: 'error',
                text: res.data.msg,
                position: 'top-right'
            })
        }
    })
}

const handleEdit = () => {
    const param = {
        name: name,
        desc: desc,
        city: city,
        prov: province,
        address: address,
        phone: phone,
        cp: cp,
        bank_acc: bankAcc,
        bank_name: bankName,
        active: isActive ? 'Y' : 'N',
        id_vendor: idVendor,
    }

    axios({
        method: 'PUT',
        url: `${host}/purchasing/index.php?action=editVendor`,
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
        },
        data: param
    }).then(res => {
        if (res.data.status === 200) {
            Toaster.fire({
                icon: 'success',
                text: 'Success update data',
                position: 'top-right'
            })
            setModalEdit(false)
            getVendorData()
        } else {
            Toaster.fire({
                icon: 'error',
                text: res.data.msg,
                position: 'top-right'
            })
        }
    })

}

useEffect(() => {
    getVendorData()
}, [])

return (
    <>
        <Container>
            <div className="mb-3 mt-2">
                <div className="h4">Master Vendor</div>
            </div>
            <Row className="d-flex justify-content-center">
                <Col xl={10} lg={12} md={12} sm={12} className="mb-3">
                    <Button content="Add New Vendor" icon="add" onClick={handleClickAdd} color="blue" />
                </Col>
                <Col xl={10} lg={12} md={12} sm={12}>
                    <Reacttable data={dataVendor} columns={columns} pageRow={10} noData={dataVendor.length === 100 ? true : false} />
                </Col>
            </Row>
        </Container>

        <Modal open={modalAdd} overflow={false} onClose={() => setModalAdd(false)} backdrop='static'>
            <Modal.Header>
                <Modal.Title>Add New Vendor</Modal.Title>
            </Modal.Header>
            <Modal.Body >
                <Form widths="equal" onSubmit={handleSubmit}>
                    <Form.Group>
                        <Form.Input type="text" label="Vendor Name" value={name} onChange={(e) => setName(e.target.value)} required />
                        <Form.Input type="text" label="Description" value={desc} onChange={(e) => setDesc(e.target.value)} required />
                    </Form.Group>
                    <Form.Group>
                        <Form.Dropdown
                            selection
                            options={cityOpt}
                            label="City"
                            search
                            required
                            onChange={(e, { value }) => handleChangeCity(value)}
                        />
                        <Form.Input type="text" value={province} label="Province" readOnly />
                    </Form.Group>
                    <Form.Group>
                        <Form.Input type="address" value={address} onChange={(e) => setAddress(e.target.value)} label="Address" required />
                    </Form.Group>
                    <Form.Group>
                        <Form.Input type="phone" label="Phone" value={phone} onChange={(e) => setPhone(e.target.value)} />
                        <Form.Input type="text" label="Contact Person Name" value={cp} onChange={(e) => setCp(e.target.value)} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Input type="text" label="Bank Account" value={bankAcc} onChange={(e) => setBankAcc(e.target.value)} />
                        <Form.Input type="text" label="Bank Name" value={bankName} onChange={(e) => setBankName(e.target.value)} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Checkbox label="Active" checked={isActive} onChange={(e) => setIsActive(!isActive)} />
                    </Form.Group>
                    <div className="text-end mt-5">
                        <Button content="Save" icon="save outline" type="submit" />
                    </div>
                </Form>
            </Modal.Body>
        </Modal>

        <Modal open={modalEdit} overflow={false} onClose={() => setModalEdit(false)} backdrop='static'>
            <Modal.Header>
                <Modal.Title>Edit Vendor</Modal.Title>
            </Modal.Header>
            <Modal.Body >
                <Form widths="equal" onSubmit={handleEdit}>
                    <Form.Group Group>
                        <Form.Input type="text" label="Vendor Name" value={name} onChange={(e) => setName(e.target.value)} required />
                        <Form.Input type="text" label="Description" value={desc} onChange={(e) => setDesc(e.target.value)} required />
                    </Form.Group>
                    <Form.Group>
                        <Form.Dropdown
                            selection
                            options={cityOpt}
                            value={city}
                            label="City"
                            search
                            required
                            onChange={(e, { value }) => handleChangeCity(value)}
                        />
                        <Form.Input type="text" value={province} label="Province" readOnly />
                    </Form.Group>
                    <Form.Group>
                        <Form.Input type="address" value={address} onChange={(e) => setAddress(e.target.value)} label="Address" required />
                    </Form.Group>
                    <Form.Group>
                        <Form.Input type="phone" label="Phone" value={phone} onChange={(e) => setPhone(e.target.value)} />
                        <Form.Input type="text" label="Contact Person Name" value={cp} onChange={(e) => setCp(e.target.value)} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Input type="text" label="Bank Account" value={bankAcc} onChange={(e) => setBankAcc(e.target.value)} />
                        <Form.Input type="text" label="Bank Name" value={bankName} onChange={(e) => setBankName(e.target.value)} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Checkbox label="Active" checked={isActive} onChange={(e) => setIsActive(!isActive)} />
                    </Form.Group>
                    <div className="text-end mt-5">
                        <Button content="Update" icon="edit outline" type="submit" color="blue" />
                    </div>
                </Form>
            </Modal.Body>
        </Modal>

    </>
)
}