import React, { useEffect, useMemo, useState } from "react";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Button, Dimmer, Dropdown, Form, FormDropdown, FormField, FormGroup, FormInput, Icon, Input, Loader, Popup } from 'semantic-ui-react'
import { useMyGlobal } from "../component/Global";
import axios from "axios";
import { Toaster } from "../component/Toaster";
import Modal from "rsuite/Modal";
import { applyDefaultFilter, Reacttable } from "../component/Reacttable";
import { Stack } from "react-bootstrap";
import { curencyNoSym, currentDate } from "../component/utils";
import { setDate } from "rsuite/esm/utils/dateUtils";
import Table from 'react-bootstrap/Table'
import moment from "moment";
import { DateRangePicker } from "rsuite";

export const PosPettyCash = () => {
    const [modalAdd, setModalAdd] = useState(false)
    const [modalEdit, setModalEdit] = useState(false)
    const [modalCat, setModalCat] = useState(false)
    const { userProfile, host, showroomOpt } = useMyGlobal()
    const [loadData, setLoadData] = useState(false)
    const [loadProc, setLoadProc] = useState(false)
    const [pettyData, setPettyData] = useState([])
    const [categoryData, setCategoryData] = useState([])
    const [filteredSr, setFilteredSr] = useState([])
    const [showroom, setShowroom] = useState('')
    const [kasir, setKasir] = useState('')
    const [txnType, setTxnType] = useState('pay')
    const [typeOpt, setTypeOpt] = useState([])
    const [txnDate, setTxnDate] = useState(currentDate)
    const [ref, setRef] = useState('')
    const [itemData, setItemData] = useState([])
    const [newRow, setNewRow] = useState({ code: '', name: '', description: '', amount: 0 })
    const [isNewRowAdded, setIsNewRowAdded] = useState(false) // State untuk melacak penambahan baris baru
    const [selCat, setSelCat] = useState(null)
    const [selPetty, setSelPetty] = useState(null)
    const [dc, setDc] = useState(txnType == 'pay' ? 'C' : 'D')

    const [dateRange, setDateRange] = useState([
        new Date(moment().startOf('month')),
        new Date(moment()),
    ])


    const colCat = useMemo(() => applyDefaultFilter([
        {
            Header: "ID",
            accessor: "id"
        },
        {
            Header: "Description",
            accessor: "description"
        },
        {
            Header: "COA Code",
            accessor: "coa"
        },
        {
            Header: 'Action',
            accessor: null,
            Cell: ({ cell }) => (
                <Popup content="Select" size="tiny" trigger={
                    <Icon name="plus circle" color="blue" onClick={() => handleSelect(cell.row.original)} style={{ cursor: 'pointer' }} />
                } />
            )
        },
    ]))

    const colPettyCash = useMemo(() => applyDefaultFilter([
        {
            Header: "Date",
            accessor: "date"
        },
        {
            Header: "ID",
            accessor: "id"
        },
        {
            Header: "Abr",
            accessor: "showroom_abr"
        },
        {
            Header: "Showroom",
            accessor: "showroom_name"
        },
        {
            Header: "Txn Type",
            accessor: "txn_type"
        },
        {
            Header: "Description",
            accessor: "description",
            Cell: ({ cell }) => (
                <div style={{ width: '200px' }}>
                    <Popup content={cell.value} size="tiny" trigger={
                        <div className="text-truncate">{cell.value}</div>
                    } />
                </div>
            )
        },
        {
            Header: "Ref",
            accessor: "ref"
        },
        {
            Header: "COA ID",
            accessor: "code_coa"
        },
        {
            Header: "D/C",
            accessor: "d_c"
        },
        {
            Header: "Amount",
            accessor: "amount",
            Cell: ({ cell }) => curencyNoSym.format(cell.value)
        },
        {
            Header: "User",
            accessor: "user_name",
            Cell: ({ cell }) => (
                <Stack direction="vertical">
                    <div>{cell.value}</div>
                    <div className="text-muted" style={{ lineHeight: '0.8em' }}>{cell.row.original.role_name}</div>
                </Stack>
            )
        },
        {
            Header: "Update At",
            accessor: "updateAt"
        },
        {
            Header: "Action",
            accessor: null,
            Cell: ({ cell }) => (
                <Button.Group basic size="mini">
                    {cell.row.original.posted === 'N' ? (
                        <>
                            <Popup size="tiny" content="Edit" trigger={
                                <Button icon={{ name: 'edit', color: 'blue' }} onClick={() => handleEditSelected(cell.row.original)} />
                            } />
                            <Popup size="tiny" content="Edit" trigger={
                                <Button icon={{ name: 'trash alternate', color: 'blue' }} onClick={() => handleRemoveSelected(cell.row.original)} />
                            } />
                        </>)
                        : (<></>)
                    }
                </Button.Group>
            )
        },
    ]))

    const handleRemoveSelected = (data) => {
        const cfm = window.confirm("Are you sure?")

        if (cfm) {
            setSelPetty(data)
            axios({
                method: "DELETE",
                url: `${host}/finance/index.php?action=remPetty`,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
                },
                data: {
                    id: data.id
                }
            }).then(res => {
                if (res.data.status === 200) {
                    Toaster.fire({
                        icon: 'success',
                        text: res.data.msg,
                        position: 'top-right'
                    })
                    pettyCash()
                } else {
                    Toaster.fire({
                        icon: 'error',
                        text: res.data.msg,
                        position: 'top-right'
                    })
                }
            })
        }
    }

    const handleEditSelected = (data) => {
        setSelPetty(data)
        setShowroom(data.id_showroom)
        setTxnType(data.txn_type)
        setKasir(data.id_user)
        setTxnDate(data.date)
        setRef(data.ref)
        const sanitizedAmount = parseFloat(data.amount?.toString().replace(/-/g, '')) || 0;
        const updatedItemData = [
            {
                code: data.code_coa || '',
                name: data.name_coa || '',
                description: data.description || '',
                amount: sanitizedAmount,
            },
        ];
        setItemData(updatedItemData);
        setModalEdit(true)
    }

    const handleSelect = (data) => {
        setSelCat(data);

        setItemData((prevItemData) => {
            const updatedItemData = [...prevItemData];
            if (updatedItemData.length > 0) {
                updatedItemData[updatedItemData.length - 1].name = data.description;
                updatedItemData[updatedItemData.length - 1].code = data.id;
            }
            return updatedItemData;
        })

        setModalCat(false)
    }

    const handleClickAdd = () => {
        setModalAdd(true)
    }

    const pettyCash = async () => {
        setLoadProc(true)
        const formatDate = (date) => moment(date).format('YYYY-MM-DD');
        const [startDate, endDate] = dateRange.map(date => formatDate(date));
        const date_range = [startDate, endDate];

        await axios({
            method: "GET",
            url: `${host}/finance/index.php?action=getPettyCash`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            params: {
                date_range: date_range
            }
        }).then(res => {
            if (res.data.status === 200) {
                setPettyData(res.data.data)
            }
        }).finally(setLoadProc(false))
    }

    const category = async () => {
        await axios({
            method: "GET",
            url: `${host}/ledger/index.php?action=getPettyCashCat`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
        }).then(res => {
            if (res.data.status === 200) {
                setCategoryData(res.data.data)
            }
        })
    }

    const handleAddRow = () => {
        if (itemData.length > 0) {
            // Periksa apakah amount pada baris terakhir adalah 0
            const lastRow = itemData[itemData.length - 1];
            if (lastRow.amount === 0) {
                Toaster.fire({
                    icon: 'warning',
                    text: 'Amount cannot be 0, before adding a new row.',
                    position: 'top-right',
                });
                return; // Hentikan proses jika amount adalah 0
            }
        }

        if (ref !== '') {
            setItemData([...itemData, { ...newRow, id: itemData.length + 1 }])
            setNewRow({ code: '', name: '', description: '', amount: 0 }) // Reset input form
            setIsNewRowAdded(true); // Set flag bahwa baris baru ditambahkan
        } else {
            Toaster.fire({
                icon: 'warning',
                text: 'Reference / Ref cannot be empty',
                position: 'top-right',
            })
        }
    }

    const handleRemoveRow = (index) => {
        const updatedData = [...itemData]
        updatedData.splice(index, 1) // Hapus baris berdasarkan index
        setItemData(updatedData)
    }

    const handleEdit = (index, field, value, numeric) => {
        if (numeric) {
            let newVal = value
            newVal = Number(newVal.replace(/\D/g, ""))
            const updatedData = [...itemData]
            updatedData[index][field] = newVal
            setItemData(updatedData)
        } else {
            const updatedData = [...itemData]
            updatedData[index][field] = value
            setItemData(updatedData)
        }
    }

    const handleClickCat = () => {
        setModalCat(true)
    }

    const handleChangeDate = (e) => {
        setDateRange(e)
    }

    const clearForm = () => {
        setShowroom(userProfile?.group[0].id_showroom)
        setTxnDate(currentDate)
        setTxnType('pay')
        setRef('')
        setItemData([])
    }

    const handleSubmit = () => {
        const hasEmptyFields = itemData.some(item =>
            !item.code || !item.name || !item.description || item.amount <= 0
        )

        if (itemData.length === 0) {
            Toaster.fire({
                icon: 'warning',
                text: 'Please add at least one cost before submitting.',
                position: 'top-right',
            });
            return; // Hentikan proses submit jika itemData kosong
        }

        if (hasEmptyFields) {
            Toaster.fire({
                icon: 'warning',
                text: 'Please fill in all fields and ensure amount is greater than 0 for all cost.',
                position: 'top-right',
            });
            return; // Hentikan proses submit jika ada data kosong
        }

        const params = {
            header: {
                id_user: userProfile.profile.id_user,
                id_showroom: showroom,
                txn_type: txnType,
                txn_date: txnDate,
                ref: ref
            },
            details: itemData
        }
        setLoadProc(true)
        axios({
            method: "POST",
            url: `${host}/finance/index.php?action=createPettyCash`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            data: params
        }).then(res => {
            if (res.data.status === 200) {
                Toaster.fire({
                    icon: 'success',
                    text: res.data.msg,
                    position: 'top-right'
                })
                clearForm()
                setModalAdd(false)
                pettyCash()
            }
        }).finally(setLoadProc(false))
    }

    const handleSubmitEdit = () => {
        const params = {
            id: selPetty.id,
            id_user: userProfile.profile.id_user,
            id_showroom: showroom,
            txn_type: txnType,
            txn_date: txnDate,
            ref: ref,
            code_coa: itemData[0].code,
            name_coa: itemData[0].name,
            description: itemData[0].description,
            amount: itemData[0].amount
        }
        axios({
            method: "PUT",
            url: `${host}/finance/index.php?action=editPetty`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            data: params
        }).then(res => {
            if (res.data.status === 200) {
                Toaster.fire({
                    icon: 'success',
                    text: res.data.msg,
                    position: 'top-right',
                })
                setModalEdit(false)
                pettyCash()
            } else {
                Toaster.fire({
                    icon: 'error',
                    text: res.data.msg,
                    position: 'top-right',
                })
            }
        })
    }

    const handleGenerate = () => {
        pettyCash()
    }

    useEffect(() => {
        const groupShowrooms = userProfile.group.map((group) => group.id_showroom);
        const filtered = showroomOpt.filter((showroom) =>
            groupShowrooms.includes(showroom.value)
        )
        setFilteredSr(filtered)
        setShowroom(userProfile?.group[0].id_showroom)
        setKasir(userProfile.profile.id_user)
    }, [userProfile])

    useEffect(() => {
        category()
        setTypeOpt([
            { key: 1, text: 'Payment / Purchasing', value: 'pay' },
            { key: 2, text: 'Receive', value: 'rec' }
        ])
    }, [])
    
    return (
        <>
            <Container>
                <div className="mb-3 mt-2">
                    <div className="h4">Petty Cash</div>
                </div>
                <Row className="d-flex justify-content-center">
                    <Dimmer active={loadData} inverted>
                        <Loader inverted>Loading...</Loader>
                    </Dimmer>
                    <Col xl={10} lg={12} md={12} className="mb-3">
                        <DateRangePicker
                            className="me-3"
                            label="Date Range : "
                            format="yyyy-MM-dd"
                            placement="auto"
                            size="md"
                            cleanable={true}
                            defaultValue={dateRange}
                            value={dateRange}
                            onChange={handleChangeDate}
                            onClean={() => setDateRange([
                                new Date(moment().startOf('month')),
                                new Date(moment()),
                            ])}
                        />
                        <Button content="Generate Data" color="blue" loading={loadProc} disabled={loadProc} onClick={handleGenerate}/>
                    </Col>
                    <Col xl={10} lg={12} md={12} className="mb-4">
                        <Button content="Petty Cash Entry" icon="add" onClick={handleClickAdd} color="blue" />
                    </Col>
                    <Col xl={10} lg={12} md={12} className="mb-3">
                        <Reacttable
                            data={pettyData}
                            columns={colPettyCash}
                            pageRow={10}
                            noData={pettyData.length === 0 ? true : false}
                        />
                    </Col>
                </Row>
            </Container>

            <Modal open={modalAdd} onClose={() => setModalAdd(false)} overflow={false} backdrop="static" size='lg'>
                <Modal.Header>
                    <Modal.Title>Petty Cash Entry</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col lg={12} className="mb-4">
                            <Form id="frmCreate">
                                <Row>
                                    <Col lg={4} md={6}>
                                        <FormInput
                                            className="small"
                                            label="Showroom"
                                            readOnly
                                            value={`${showroom} - ${showroomOpt.find(i => i.value === showroom)?.text}`}
                                            onChange={(e) => setShowroom(e.target.value)}
                                        />
                                    </Col>
                                    <Col lg={2} md={4}>
                                        <FormInput
                                            fluid
                                            className="small"
                                            label="Cashier"
                                            value={kasir}
                                            readOnly
                                        />
                                    </Col>
                                    <Col lg={4} md={6}>
                                        <FormDropdown
                                            fluid
                                            className="small"
                                            options={typeOpt}
                                            selection
                                            value={txnType}
                                            label="Txn Type"
                                            onChange={(e, { value }) => setTxnType(value)}
                                        />
                                    </Col>
                                    <Col lg={2} md={4}>
                                        <FormInput
                                            className="small"
                                            type="date"
                                            label="Date"
                                            value={txnDate}
                                            onChange={(e) => setTxnDate(e.target.value)}
                                        />
                                    </Col>
                                    <Col lg={4} md={6}>
                                        <FormInput
                                            className="small"
                                            label="Ref"
                                            maxLength="20"
                                            value={ref}
                                            onChange={(e) => setRef(e.target.value)}
                                        />
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                        <Col lg={12}>
                            <Table className="table table-bordered" style={{ fontFamily: 'inter' }}>
                                <thead className="bg-light" style={{ fontSize: '0.9em' }}>
                                    <tr>
                                        <th>#</th>
                                        <th>Code</th>
                                        <th>Name</th>
                                        <th>Description</th>
                                        <th>Amount</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody style={{ fontSize: '0.9em' }}>
                                    {
                                        itemData.map((row, index) => (
                                            <tr key={index}>
                                                <td style={{ width: '50px' }}>{index + 1}</td>
                                                <td style={{ width: '100px' }}>
                                                    <span>
                                                        <Icon name="plus circle" color="blue" onClick={handleClickCat} style={{ cursor: 'pointer' }} />
                                                    </span>
                                                    <span>{row.code}</span>
                                                </td>
                                                <td style={{ width: '280px' }}>{row.name}</td>
                                                <td style={{ width: '250px' }}>
                                                    <input
                                                        value={row.description}
                                                        maxLength="50"
                                                        onChange={(e) => handleEdit(index, 'description', e.target.value, false)}
                                                        style={{ border: 'none', outline: 'none', width: '100%', padding: 2, borderRadius: '3px', textAlign: 'left', fontFamily: 'inherit' }}
                                                    />
                                                </td>
                                                <td style={{ width: '120px' }}>
                                                    <input
                                                        value={curencyNoSym.format(row.amount)}
                                                        onChange={(e) => handleEdit(index, 'amount', e.target.value, true)}
                                                        style={{ border: 'none', outline: 'none', width: '100%', padding: 2, borderRadius: '3px', textAlign: 'right', fontFamily: 'inherit' }}
                                                    />
                                                </td>
                                                <td className="text-center" style={{ width: '50px' }}>
                                                    <Popup content="Remove this item" size="tiny" trigger={
                                                        <Icon name="close" color="orange" style={{ cursor: 'pointer' }} onClick={() => handleRemoveRow(index)} />}
                                                    />
                                                </td>
                                            </tr>
                                        ))
                                    }
                                    <tr>
                                        <td colSpan={7} onClick={handleAddRow} style={{ cursor: 'pointer' }} className="text-center fw-bold">
                                            <span><Icon name="add" color="blue" /></span>
                                            <span>Add New Row</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>

                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Submit" form="frmCreate" icon="save" color="blue" onClick={handleSubmit} loading={loadProc} disabled={loadProc} />
                </Modal.Footer>
            </Modal>

            <Modal open={modalCat} onClose={() => setModalCat(false)} overflow={false} size="md">
                <Modal.Header>
                    <Modal.Title>Category List</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Reacttable
                        data={categoryData}
                        columns={colCat}
                        pageRow={10}
                        noData={categoryData.length === 0 ? true : false}
                    />
                </Modal.Body>
            </Modal>

            <Modal open={modalEdit} onClose={() => setModalEdit(false)} overflow={false} backdrop="static" size='lg'>
                <Modal.Header>
                    <Modal.Title>Petty Cash Edit</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col lg={12} className="mb-4">
                            <Form id="frmEdit">
                                <Row>
                                    <Col lg={4} md={6}>
                                        <FormDropdown
                                            fluid
                                            className="small"
                                            label="Showroom"
                                            options={filteredSr}
                                            value={showroom}
                                            selection
                                            onChange={(e, { value }) => setShowroom(value)}
                                        />
                                    </Col>
                                    <Col lg={2} md={4}>
                                        <FormInput
                                            fluid
                                            className="small"
                                            label="Cashier"
                                            value={kasir}
                                            readOnly
                                        />
                                    </Col>
                                    <Col lg={4} md={6}>
                                        <FormDropdown
                                            fluid
                                            className="small"
                                            options={typeOpt}
                                            selection
                                            value={txnType}
                                            label="Txn Type"
                                            onChange={(e, { value }) => setTxnType(value)}
                                        />
                                    </Col>
                                    <Col lg={2} md={4}>
                                        <FormInput
                                            className="small"
                                            type="date"
                                            label="Date"
                                            value={txnDate}
                                            onChange={(e) => setTxnDate(e.target.value)}
                                        />
                                    </Col>
                                    <Col lg={4} md={6}>
                                        <FormInput
                                            className="small"
                                            label="Ref"
                                            maxLength="20"
                                            value={ref}
                                            onChange={(e) => setRef(e.target.value)}
                                        />
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                        <Col lg={12}>
                            <Table className="table table-bordered" style={{ fontFamily: 'inter' }}>
                                <thead className="bg-light" style={{ fontSize: '0.9em' }}>
                                    <tr>
                                        <th>#</th>
                                        <th>Code</th>
                                        <th>Name</th>
                                        <th>Description</th>
                                        <th>Amount</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody style={{ fontSize: '0.9em' }}>
                                    {
                                        itemData.map((row, index) => (
                                            <tr key={index}>
                                                <td style={{ width: '50px' }}>{index + 1}</td>
                                                <td style={{ width: '100px' }}>
                                                    <span>
                                                        <Icon name="plus circle" color="blue" onClick={handleClickCat} style={{ cursor: 'pointer' }} />
                                                    </span>
                                                    <span>{row.code}</span>
                                                </td>
                                                <td style={{ width: '280px' }}>{row.name}</td>
                                                <td style={{ width: '250px' }}>
                                                    <input
                                                        value={row.description}
                                                        maxLength="50"
                                                        onChange={(e) => handleEdit(index, 'description', e.target.value, false)}
                                                        style={{ border: 'none', outline: 'none', width: '100%', padding: 2, borderRadius: '3px', textAlign: 'right', fontFamily: 'inherit' }}
                                                    />
                                                </td>
                                                <td style={{ width: '120px' }}>
                                                    <input
                                                        value={curencyNoSym.format(row.amount)}
                                                        onChange={(e) => handleEdit(index, 'amount', e.target.value, true)}
                                                        style={{ border: 'none', outline: 'none', width: '100%', padding: 2, borderRadius: '3px', textAlign: 'right', fontFamily: 'inherit' }}
                                                    />
                                                </td>
                                                <td className="text-center" style={{ width: '50px' }}>

                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>

                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Update" form="frmEdit" icon="save" color="blue" onClick={handleSubmitEdit} loading={loadProc} disabled={loadProc} />
                </Modal.Footer>
            </Modal>

        </>
    )
}