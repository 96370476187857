import React, { useEffect, useMemo, useState } from "react";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Button, Dimmer, Dropdown, Form, FormDropdown, FormField, FormGroup, FormInput, Icon, Input, Loader, Popup } from 'semantic-ui-react'
import { useMyGlobal } from "../../component/Global";
import axios from "axios";
import { Toaster } from "../../component/Toaster";
import Modal from "rsuite/Modal";
import { applyDefaultFilter, Reacttable } from "../../component/Reacttable";
import { Stack } from "react-bootstrap";

export const BankAcc = () => {
    const [modalAdd, setModalAdd] = useState(false)
    const [modalEdit, setModalEdit] = useState(false)
    const [modalCoaList, setModalCoaList] = useState(false)
    const { userProfile, host } = useMyGlobal()
    const [bankData, setBankData] = useState([])
    const [loadData, setLoadData] = useState(false)
    const [loadProc, setLoadProc] = useState(false)
    const [selBank, setSelBank] = useState(null)
    const [type, setType] = useState('1')
    const [bankCode, setBankCode] = useState("")
    const [bankDesc, setBankDesc] = useState("")
    const [rekCoa, setRekCoa] = useState('')
    const [coaDesc, setCoaDesc] = useState('')
    const [rekCoaList, setRekCoaList] = useState([])

    const columns = useMemo(() => applyDefaultFilter([
        {
            Header: "Type",
            accessor: "type"
        },
        {
            Header: "Code",
            accessor: "code_bank"
        },
        {
            Header: "Description",
            accessor: "description"
        },
        {
            Header: "Account",
            accessor: "akun",
            Cell:({cell}) => `${cell.value} - ${cell.row.original.akun_desc}`
        },
        {
            Header: "User",
            accessor: "user_name",
            Cell: ({ cell }) => (
                <Stack direction="vertical">
                    <div style={{ lineHeight: '0.8em' }}>{cell.value}</div>
                    <div className="text-muted" style={{ lineHeight: '1em' }}>{cell.row.original.role_name}</div>
                </Stack>
            )
        },
        {
            Header: "Create At",
            accessor: "createAt"
        },
        {
            Header: "Update At",
            accessor: "updateAt"
        },
        {
            Header: "Action",
            accessor: null,
            Cell: ({ cell }) => (
                <Button.Group basic size="mini">
                    <Popup size="tiny" content="Edit" trigger={
                        <Button icon={{ name: 'edit', color: 'blue' }} onClick={() => handleClickEdit(cell.row.original)} />
                    } />
                </Button.Group>
            )
        },
    ]))

    const colCoaAset = useMemo(() => applyDefaultFilter([
        {
            Header: "Group",
            accessor: "group_name"
        },
        {
            Header: "Coa Number",
            accessor: "coa"
        },
        {
            Header: "Description",
            accessor: "description"
        },
        {
            Header: "Action",
            accessor: null,
            Cell: ({ cell }) => (
                <>
                    <div className="text-center">
                        <Icon name="plus circle" color="blue" onClick={() => handleClickSelect(cell.row.original)} style={{ cursor: 'pointer' }} />
                    </div>
                </>
            )
        },
    ]))

    const handleClickSelect = (data) => {
        setRekCoa(data.coa)
        setCoaDesc(data.description)
        setModalCoaList(false)
    }

    const handleClickEdit = (data) => {
        setSelBank(data)
        setType(data.type)
        setBankCode(data.code_bank)
        setBankDesc(data.description)
        setRekCoa(data.akun)
        setCoaDesc(data.akun_desc)
        setModalEdit(true)
    }

    const handleClickAdd = () => {
        setModalAdd(true)
    }

    const getRekCoaAset = async () => {
        await axios({
            method: "GET",
            url: `${host}/finance/index.php`,
            headers: {
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            params: {
                action: 'getRekCoaAset'
            }
        }).then(res => {
            if (res.data.status === 200) {
                setRekCoaList(res.data.data)
            }
        })
    }

    const handleClickRek = () => {
        setModalCoaList(true)
    }

    const clearForm = () => {
        setType('1')
        setBankCode('')
        setBankDesc('')
        setRekCoa('')
        setCoaDesc('')
    }

    const handleSubmit = () => {
        const existCode = bankData.some(i => i.code_bank.toLowerCase() === bankCode.toLowerCase())
        const existName = bankData.some(i => i.description.toLowerCase() === bankDesc.toLowerCase())

        if (existCode) {
            Toaster.fire({
                icon: 'warning',
                text: 'Bank code exist',
                position: 'top-right',
            })
            setLoadProc(false)
        } else if (existName) {
            Toaster.fire({
                icon: 'warning',
                text: 'Bank description exist',
                position: 'top-right',
            })
            setLoadProc(false)
        } else {
            setLoadProc(true)
            const param = {
                type: type,
                code: bankCode,
                desc: bankDesc,
                akun: rekCoa,
                akun_desc:coaDesc,
                id_user: userProfile.profile.id_user,
            }

            axios({
                method: "POST",
                url: `${host}/finance/index.php?action=addBank`,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
                },
                data: param
            }).then(res => {
                if (res.data.status === 200) {
                    Toaster.fire({
                        icon: 'success',
                        text: res.data.msg,
                        position: 'top-right'
                    })
                    clearForm()
                    setModalAdd(false)
                    getBankAcc()
                } else {
                    Toaster.fire({
                        icon: 'error',
                        text: res.data.msg,
                        position: 'top-right'
                    })
                }
            }).finally(setLoadProc(false))
        }
    }

    const handleSubmitEdit = () => {
        const existCode = bankData.some(i => i.code_bank.toLowerCase() === bankCode.toLowerCase())
        const existName = bankData.some(i => i.description.toLowerCase() === bankDesc.toLowerCase())

        if (existCode && selBank.code_bank !== bankCode) {
            Toaster.fire({
                icon: 'warning',
                text: 'Bank code exist',
                position: 'top-right',
            })
            setLoadProc(false)
        } else if (existName && selBank.description !== bankDesc) {
            Toaster.fire({
                icon: 'warning',
                text: 'Bank description exist',
                position: 'top-right',
            })
            setLoadProc(false)
        } else {
            setLoadProc(true)
            const param = {
                id:selBank.id,
                type: type,
                code: bankCode,
                desc: bankDesc,
                akun: rekCoa,
                akun_desc: coaDesc,
                id_user: userProfile.profile.id_user,
            }

            axios({
                method: "PUT",
                url: `${host}/finance/index.php?action=editBank`,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
                },
                data: param
            }).then (res => {
                if (res.data.status === 200){
                    Toaster.fire({
                        icon:'success',
                        text:res.data.msg,
                        position:'top-right'
                    })
                    clearForm()
                    setModalEdit(false)
                    getBankAcc()
                } else {
                    Toaster.fire({
                        icon:'error',
                        text:res.data.msg,
                        position:'top-right'
                    })
                }
            }).finally(setLoadProc(false))
        }
    }

    const getBankAcc = async () => {
        await axios({
            method: "GET",
            url: `${host}/finance/index.php`,
            headers: {
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            params: {
                action: 'getBankAcc'
            }
        }).then(res => {
            if (res.data.status === 200) {
                setBankData(res.data.data)
            }
        })
    }

    useEffect(() => {
        getRekCoaAset()
        getBankAcc()
    }, [])

    return (
        <>
            <Container>
                <div className="mb-3 mt-2">
                    <div className="h4">Bank Account</div>
                </div>
                <Row className="d-flex justify-content-center">
                    <Dimmer active={loadData} inverted>
                        <Loader inverted>Loading...</Loader>
                    </Dimmer>
                    <Col xl={10} lg={12} md={12} className="mb-4">
                        <Button content="Add New Bank" icon="add" onClick={handleClickAdd} color="blue" />
                    </Col>
                    <Col xl={10} lg={12} md={12} className="mb-3">
                        <Reacttable
                            data={bankData}
                            columns={columns}
                            pageRow={10}
                            noData={bankData.length === 0 ? true : false}
                        />
                    </Col>
                </Row>
            </Container>

            <Modal open={modalCoaList} onClose={() => setModalCoaList(false)} overflow={false} size="md">
                <Modal.Header>
                    <Modal.Title>COA List</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Reacttable
                        data={rekCoaList}
                        columns={colCoaAset}
                        pageRow={10}
                        noData={rekCoaList.length === 0 ? true : false}
                    />
                </Modal.Body>
            </Modal>

            <Modal open={modalAdd} onClose={() => setModalAdd(false)} overflow={false} backdrop="static" size="xs">
                <Modal.Header>
                    <Modal.Title>Add new Bank</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="frmAdd" onSubmit={handleSubmit}>
                        <FormGroup grouped>
                            <FormDropdown
                                required
                                label="Type"
                                options={[{ key: '1', value: 'Bank', text: 'Bank' }, { key: '2', value: 'Kas', text: 'Kas' }]}
                                selection
                                value={type}
                                onChange={(e, { value }) => setType(value)}
                            />
                            <FormInput
                                label="Code"
                                value={bankCode}
                                required
                                maxLength="25"
                                onChange={(e) => setBankCode(e.target.value)}
                            />
                            <FormInput
                                label="Description"
                                value={bankDesc}
                                required
                                maxLength="30"
                                onChange={(e) => setBankDesc(e.target.value)}
                            />
                            <FormField>
                                <label>Rekening</label>
                                <Popup content={rekCoa} size="tiny" position="bottom left" trigger={
                                    <Input
                                        required
                                        value={`${rekCoa} - ${coaDesc}`}
                                        readOnly
                                        action={
                                            <Button icon={{ name: 'ellipsis horizontal', color: 'blue' }} type="button" basic onClick={handleClickRek} />
                                        }
                                    />
                                } />
                            </FormField>
                        </FormGroup>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Submit" icon="save" form="frmAdd" type="submit" loading={loadProc} disabled={loadProc} color="blue" />
                </Modal.Footer>
            </Modal>

            <Modal open={modalEdit} onClose={() => setModalEdit(false)} overflow={false} backdrop="static" size="xs">
                <Modal.Header>
                    <Modal.Title>Edit Bank</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="frmEdit" onSubmit={handleSubmitEdit}>
                        <FormGroup grouped>
                            <FormDropdown
                                required
                                label="Type"
                                options={[{ key: '1', value: 'Bank', text: 'Bank' }, { key: '2', value: 'Kas', text: 'Kas' }]}
                                selection
                                value={type}
                                onChange={(e, { value }) => setType(value)}
                            />
                            <FormInput
                                label="Code"
                                value={bankCode}
                                required
                                maxLength="25"
                                onChange={(e) => setBankCode(e.target.value)}
                            />
                            <FormInput
                                label="Description"
                                value={bankDesc}
                                required
                                maxLength="30"
                                onChange={(e) => setBankDesc(e.target.value)}
                            />
                            <FormField>
                                <label>Rekening</label>
                                <Popup content={rekCoa} size="tiny" position="bottom left" trigger={
                                    <Input
                                        required
                                        value={`${rekCoa} - ${coaDesc}`}
                                        readOnly
                                        action={
                                            <Button icon={{ name: 'ellipsis horizontal', color: 'blue' }} type="button" basic onClick={handleClickRek} />
                                        }
                                    />
                                } />
                            </FormField>
                        </FormGroup>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Submit" icon="save" form="frmEdit" type="submit" loading={loadProc} disabled={loadProc} color="blue" />
                </Modal.Footer>
            </Modal>

        </>
    )
}