import React, { useEffect, useMemo, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import axios from "axios";
import { useMyGlobal } from "../component/Global";
import Tabs from 'rsuite/Tabs';
import moment from "moment";
import DateRangePicker from "rsuite/DateRangePicker";
import { Button, ButtonGroup, Form, Popup } from "semantic-ui-react";
import { capitalize, curencyNoSym } from "../component/utils";
import { Reacttable } from "../component/Reacttable";

export const ReportSalesAgent = () => {
    const { host, userProfile, showroomOpt } = useMyGlobal()
    const [filteredShr, setFilteredShr] = useState([])
    const [showroom, setShowroom] = useState('')
    const [loadData, setLoadData] = useState({off:false, mp:false})
    const [salesOffline, setSalesOffline] = useState([])
    const [salesMp, setSalesMp] = useState([])

    const [dateRange, setDateRange] = useState([
        new Date(moment().startOf('month')),
        new Date(moment()),
    ])

    const columnOffStore = useMemo(() => [
        {
            Header: "ID",
            accessor: "id",
        },
        {
            Header: "Name",
            accessor: "name",
            Cell: ({ cell }) => (capitalize(cell.value))

        },
        {
            Header: "Address",
            accessor: "address",
            Cell: ({ cell }) => (
                <div style={{ width: '200px' }}>
                    <Popup content={capitalize(cell.value)} size="tiny" trigger={<span>{capitalize(cell.value)}</span>} />
                </div>
            ),
        },
        {
            Header: "DOB",
            accessor: "dob",
        },
        {
            Header: "Age",
            accessor: "age",
        },
        {
            Header: "Education",
            accessor: "last_edu",
        },
        {
            Header: "Qty",
            accessor: "total_qty",
            Cell: ({ cell }) => (curencyNoSym.format(cell.value))
        },
        {
            Header: "Total",
            accessor: "total",
            Cell: ({ cell }) => (curencyNoSym.format(cell.value))
        },
    ])

    const columnMp = useMemo(() => [
        {
            Header: "ID",
            accessor: "id",
        },
        {
            Header: "Name",
            accessor: "name",
            Cell: ({ cell }) => (capitalize(cell.value))

        },
        {
            Header: "Qty",
            accessor: "total_qty",
            Cell: ({ cell }) => (curencyNoSym.format(cell.value))
        },
        {
            Header: "Total",
            accessor: "total",
            Cell: ({ cell }) => (curencyNoSym.format(cell.value))
        },
    ])


    const handleChangeDate = (e) => {
        setDateRange(e)
    }

    const handleExc = () => {
        getSalesOffline(showroom)
        getSalesMp(showroom)
    }

    const handleSelectShr = (value) => {
        setShowroom(value)
    }

    const handleExport = () => {
        alert('Belum bisa...')
    }

    const getSalesOffline = async (idShowroom) => {
        setLoadData(prev => ({
            ...prev,
            off:true
        }))
        const formatDate = (date) => moment(date).format('YYYY-MM-DD')
        const [startDate, endDate] = dateRange.map(date => formatDate(date))
        const date_range = [startDate, endDate]

        await axios({
            method: "GET",
            url: `${host}/sales/index.php`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            params: {
                action: 'salesByAgent',
                start: date_range[0],
                end: date_range[1],
                id_showroom: idShowroom,
                type: 'offstore'
            }
        }).then(res => {
            if (res.data.status === 200) {
                setSalesOffline(res.data.data)
            }
        }).finally(() => setLoadData(prev => ({
            ...prev,
            off:false
        })))
    }

    const getSalesMp = async (idShowroom) => {
        setLoadData(prev => ({
            ...prev,
            mp:true
        }))
        const formatDate = (date) => moment(date).format('YYYY-MM-DD')
        const [startDate, endDate] = dateRange.map(date => formatDate(date))
        const date_range = [startDate, endDate]

        await axios({
            method: "GET",
            url: `${host}/sales/index.php`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            params: {
                action: 'salesByAgent',
                start: date_range[0],
                end: date_range[1],
                id_showroom: idShowroom,
                type: 'marketplace'
            }
        }).then(res => {
            if (res.data.status === 200) {
                setSalesMp(res.data.data)
            }
        }).finally(() => setLoadData(prev => ({
            ...prev,
            mp:false
        })))
    }

    useEffect(() => {
        const filteredShr = showroomOpt.filter(opt =>
            userProfile.group.some(group => group.id_showroom === opt.value)
        )
        setFilteredShr(filteredShr)
    }, [userProfile])
    console.log(loadData)
    return (
        <>
            <Container>
                <div className="mb-3 mt-2">
                    <div className="h4">Sales by Agent</div>
                </div>
                <Row className="d-flex justify-content-center">
                    <Col xl={9} lg={9} md={12} sm={12} >
                        <Row>
                            <Col xl={4} lg={12} md={12}>
                                <DateRangePicker
                                    style={{ width: '310px' }}
                                    className="mb-3 me-3"
                                    label="Date Range : "
                                    format="yyyy-MM-dd"
                                    placement="bottomStart"
                                    cleanable={true}
                                    defaultValue={dateRange}
                                    value={dateRange}
                                    onChange={handleChangeDate}
                                    onClean={() => setDateRange([
                                        new Date(moment().startOf('month')),
                                        new Date(moment()),
                                    ])}
                                />
                            </Col>
                            <Col xl={8} lg={12} md={12}>
                                <Form onSubmit={handleExc}>
                                    <Form.Group inline>
                                        <Form.Dropdown
                                            style={{ width: '300px' }}
                                            placeholder="Select Showroom"
                                            options={filteredShr}
                                            selection
                                            value={showroom}
                                            clearable
                                            search
                                            onChange={(e, { value }) => handleSelectShr(value)}
                                        />
                                        <Button content="Generate Data" loading={loadData.mp && loadData.off ? true : false} color="blue" icon="arrow alternate circle right" labelPosition="right" type="submit" disabled={showroom === '' || (loadData.mp && loadData.off ? true : false) ? true : false} />
                                    </Form.Group>
                                </Form>
                            </Col>
                        </Row>
                    </Col>
                    <Col xl={3} lg={3} md={12} sm={12} className="mb-3 text-end">
                        <ButtonGroup >
                            <Button onClick={handleExport} content="Export to Excel" icon="share" labelPosition="right" color="orange" disabled={showroom === ''} />
                        </ButtonGroup>
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={12}>
                        <Tabs defaultActiveKey="1" appearance="tabs">
                            <Tabs.Tab eventKey="1" title="Offline Agent">
                                <Reacttable
                                    data={salesOffline}
                                    columns={columnOffStore}
                                    pageRow={10}
                                    totals={['total_qty', 'total']}
                                    noData={salesOffline.length === 0 ? true : false}
                                />
                            </Tabs.Tab>
                            <Tabs.Tab eventKey="2" title="Online Agent" >
                                <Reacttable
                                    data={salesMp}
                                    columns={columnMp}
                                    pageRow={10}
                                    totals={['total_qty', 'total']}
                                    noData={salesOffline.length === 0 ? true : false}
                                />
                            </Tabs.Tab>
                        </Tabs>
                    </Col>
                </Row>
            </Container>
        </>
    )
}