
import React, { useEffect, useRef, useState, forwardRef } from "react";
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { useLocation, useNavigate } from "react-router-dom";
import { useReactToPrint } from 'react-to-print';
import { Button, Dimmer, Divider, Loader } from "semantic-ui-react";
import jfsLogo from "../../asset/javafootwear.png"
import { curencyNoSym, currentDateTime } from "../../component/utils";
import { useMyGlobal } from "../../component/Global";


export const InvoicePrintPage = () => {
    const data = useLocation().state
    const navigate = useNavigate()
    const componentRef = useRef()
    const [groupDetails, setGroupDetails] = useState(null)
    const { host, userProfile } = useMyGlobal()
    const [loaderPage, setLoaderPage] = useState(false)

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const formatDateLong = (dateString) => {
        const date = new Date(dateString);
        const options = { day: 'numeric', month: 'long', year: 'numeric' };
        return date.toLocaleDateString('id-ID', options);
    };

    const ComponentToPrint = forwardRef((props, ref) => {
        return (
            <div ref={ref} style={{ position: 'relative', minHeight: '87em', paddingBottom: '3rem' }}>
                {/* Company Info Section */}
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1rem', borderBottom: 'solid black 2px' }}>
                    <div style={{ fontWeight: 'bolder', fontSize: '35px', paddingTop: '2.5em' }}>INVOICE</div>
                    <div style={{ justifyItems: 'end', marginBottom: '5px' }}>
                        <img src={jfsLogo} width={100} style={{ marginBottom: '5px' }} />
                        <div style={{ fontWeight: 'bolder' }}>PT. JAVA FOOTWEAR SPORTINDO</div>
                        <div>Jl. Surapati No.61, Sadang Serang, Coblong, Kota Bandung</div>
                        <div>Phone : (022) 2506999</div>
                    </div>
                </div>

                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '2rem' }}>
                    <table>
                        <tr>
                            <td style={{ paddingRight: '20px', verticalAlign: 'top' }}>Customer</td>
                            <td style={{ paddingRight: '20px', verticalAlign: 'top' }}>:</td>
                            <td>{data.header.id_ds_customer} - {data?.header.customer_name}</td>
                        </tr>
                        <tr>
                            <td style={{ verticalAlign: 'top' }}>Alamat</td>
                            <td style={{ paddingRight: '20px', verticalAlign: 'top' }}>:</td>
                            <td style={{ width: '300px', overflow: 'clip' }}>{data?.header.address}</td>
                        </tr>
                        <tr>
                            <td style={{ paddingRight: '20px' }}>Mata Uang</td>
                            <td style={{ paddingRight: '20px' }}>:</td>
                            <td>IDR</td>
                        </tr>
                        <tr>
                            <td style={{ paddingRight: '20px' }}>NPWP</td>
                            <td style={{ paddingRight: '20px' }}>:</td>
                            <td>{data.header.npwp}</td>
                        </tr>
                        <tr>
                            <td style={{ paddingRight: '20px' }}>Nama Toko</td>
                            <td style={{ paddingRight: '20px' }}>:</td>
                            <td >{data.header.customer_name} {data?.header.reference ? <span>(Ref : {data?.header.reference})</span> : <></>}</td>
                        </tr>
                    </table>
                    <table>
                        <tr style={{ fontWeight: 'bold' }}>
                            <td style={{ paddingRight: '20px' }}>Invoice</td>
                            <td style={{ paddingRight: '20px' }}>:</td>
                            <td>{data.header.no_invoice}</td>
                        </tr>
                        <tr>
                            <td style={{ verticalAlign: 'top' }}>Tgl. Invoice</td>
                            <td style={{ paddingRight: '20px' }}>:</td>
                            <td>{formatDateLong(data.header.inv_date)}</td>
                        </tr>
                        <tr>
                            <td style={{ paddingRight: '20px' }}>Jatuh Tempo</td>
                            <td style={{ paddingRight: '20px' }}>:</td>
                            <td>{formatDateLong(data.header.due_date)}</td>
                        </tr>
                        <tr>
                            <td style={{ paddingRight: '20px' }}>Nomor DO</td>
                            <td style={{ paddingRight: '20px' }}>:</td>
                            <td>{data.header.do_number}</td>
                        </tr>
                        <tr>
                            <td style={{ paddingRight: '20px' }}>Nomor SO</td>
                            <td style={{ paddingRight: '20px' }}>:</td>
                            <td>{data.header.order_number}</td>
                        </tr>
                    </table>

                </div>


                {/* Table Section */}
                <div className="mb-4">
                    <table className="table table-bordered">
                        <thead >
                            <tr>
                                <th style={{ backgroundColor: 'whitesmoke' }}>No.</th>
                                <th style={{ backgroundColor: 'whitesmoke' }}>Artikel</th>
                                <th style={{ backgroundColor: 'whitesmoke' }}>Deskripsi</th>
                                <th style={{ backgroundColor: 'whitesmoke' }}>Size/Qty</th>
                                <th style={{ backgroundColor: 'whitesmoke', textAlign: 'end' }}>QTY</th>
                                <th style={{ backgroundColor: 'whitesmoke', textAlign: 'end' }}>Harga @</th>
                                <th style={{ backgroundColor: 'whitesmoke', textAlign: 'end' }}>Diskon</th>
                                <th style={{ backgroundColor: 'whitesmoke', textAlign: 'end' }}>Jumlah</th>
                            </tr>
                        </thead>
                        <tbody style={{ fontSize: '0.9em' }}>
                            {groupDetails?.map((item, i) => (
                                <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>{item.article}</td>
                                    <td>{item.description}</td>
                                    <td>{item.sizes}</td>
                                    <td style={{ textAlign: 'end' }}>{item.qty}</td>
                                    <td style={{ textAlign: 'end' }}>{curencyNoSym.format(item.price)}</td>
                                    <td style={{ textAlign: 'end' }}>{curencyNoSym.format(item.total_discount)}</td>
                                    <td style={{ textAlign: 'end' }}>{curencyNoSym.format(item.total)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '2rem' }}>
                    <table>
                        <tr>
                            <td style={{ paddingRight: '20px' }}>Pembayaran</td>
                            <td style={{ paddingRight: '20px' }}>:</td>
                            <td>Kredit {data.header.top} hari (Per SJ/Faktur)</td>
                        </tr>
                        <tr>
                            <td style={{ paddingRight: '20px' }}>Jatuh Tempo</td>
                            <td style={{ paddingRight: '20px' }}>:</td>
                            <td>{formatDateLong(data.header.due_date)}</td>
                        </tr>
                        <tr>
                            <td style={{ paddingRight: '20px', verticalAlign: 'top' }}>Mohon Dibayarkan Ke</td>
                            <td style={{ paddingRight: '20px', verticalAlign: 'top' }}>:</td>
                            <td>
                                <div>
                                    PT. JAVA FOOTWEAR SPORTINDO
                                    <table>
                                        <tr>
                                            <td style={{ paddingRight: '20px' }}>Nomor Rek.</td>
                                            <td style={{ paddingRight: '20px' }}>:</td>
                                            <td>5150-585-661</td>
                                        </tr>
                                        <tr>
                                            <td style={{ paddingRight: '20px' }}>Bank</td>
                                            <td style={{ paddingRight: '20px' }}>:</td>
                                            <td>BCA</td>
                                        </tr>
                                        <tr>
                                            <td style={{ paddingRight: '20px' }}>Cabang</td>
                                            <td style={{ paddingRight: '20px' }}>:</td>
                                            <td>KCU</td>
                                        </tr>
                                    </table>
                                </div>
                            </td>
                        </tr>
                    </table>
                    <table>
                        <tr style={{ fontWeight: 'bolder' }}>
                            <td style={{ paddingRight: '130px' }}>Total</td>
                            <td style={{ paddingRight: '20px' }}></td>
                            <td style={{ textAlign: 'end' }}>{curencyNoSym.format(data.header.total_gross)}</td>
                        </tr>
                        <tr style={{ borderBottom: '1px dashed black' }}>
                            <td style={{ paddingRight: '40px', paddingBottom: '5px' }}>Diskon</td>
                            <td style={{ paddingRight: '20px', paddingBottom: '5px' }}></td>
                            <td style={{ textAlign: 'end', paddingBottom: '5px' }}>{curencyNoSym.format(data.header.discount)}</td>
                        </tr>
                        <tr>
                            <td style={{ paddingRight: '20px' }}>DPP</td>
                            <td style={{ paddingRight: '20px' }}></td>
                            <td style={{ textAlign: 'end' }}>{curencyNoSym.format(data.header.total_dpp)}</td>
                        </tr>
                        <tr>
                            <td style={{ paddingRight: '20px' }}>DPP Lainya</td>
                            <td style={{ paddingRight: '20px' }}></td>
                            <td style={{ textAlign: 'end' }}>{curencyNoSym.format(data.header.total_dpp_other)}</td>
                        </tr>
                        <tr style={{ borderBottom: '1px solid black' }}>
                            <td style={{ paddingRight: '20px' }}>PPN</td>
                            <td style={{ paddingRight: '20px' }}></td>
                            <td style={{ textAlign: 'end' }}>{curencyNoSym.format(data.header.total_ppn)}</td>
                        </tr>
                        <tr style={{ fontWeight: 'bolder' }}>
                            <td style={{ paddingRight: '20px', paddingTop: '10px' }}>Netto</td>
                            <td style={{ paddingRight: '20px', paddingTop: '10px' }}></td>
                            <td style={{ textAlign: 'end', paddingTop: '10px' }}>{curencyNoSym.format(data.header.total_nett)}</td>
                        </tr>
                    </table>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '2rem' }}>
                    <div>
                        <div style={{ fontWeight: 'bold' }}>Perhatian :</div>
                        <div style={{ fontStyle: 'italic' }}>Pembayaran baru dianggap lunas setelah jumlah tersebut</div>
                        <div style={{ fontStyle: 'italic' }}>diterima oleh bank kami.</div>
                    </div>
                    <div style={{
                        width: '300px',
                        height: '130px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '10px'
                    }}>
                        <div style={{ fontWeight: 'bold' }}>Hormat Kami,</div>
                        <div style={{ alignSelf: 'stretch', textAlign: 'center' }}>---------------------------------</div>
                    </div>
                </div>
                <div style={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    borderTop: '1px solid grey',
                }}>
                    <div style={{ display: 'flex' }}>
                        <small style={{ color: 'black', fontStyle: 'italic', width: '50%' }}>Berkas ini dibuat oleh sistem.</small>
                        <small style={{ color: 'black', fontStyle: 'italic', width: '50%', textAlign: 'end' }}>Waktu cetak : {currentDateTime}</small>
                    </div>
                </div>
            </div>

        )
    })

    useEffect(() => {
        const groupedDet = data.detail.reduce((acc, item) => {
            if (!acc[item.article]) {
                acc[item.article] = {
                    article: item.article,
                    description: item.description,
                    sizes: [],
                    qty: 0,
                    price: item.price,
                    discount: parseInt(item.price) * parseInt(item.dsc_percent) / 100,
                    total_discount: 0,
                    total: 0,
                };
            }
            acc[item.article].sizes.push(`${item.size}/${item.qty_pickup}`)
            acc[item.article].qty += parseInt(item.qty_pickup) 
            acc[item.article].total += parseInt(item.total) 
            acc[item.article].total_discount += parseInt(item.total_discount)
            return acc;
        }, {});

        Object.keys(groupedDet).forEach(key => {
            groupedDet[key].sizes = groupedDet[key].sizes.join(", ");
        });

        setGroupDetails(Object.values(groupedDet))
    }, [])

    useEffect(() => {
        if (data) {
            setLoaderPage(false)
        } else {
            setLoaderPage(true)
        }
    }, [data])

    return (
        <>
            <Container className="pt-5" style={{ width: '70.6em' }}>
                <Dimmer active={loaderPage} inverted>
                    <Loader inverted>Loading...</Loader>
                </Dimmer>
                <Row className="d-flex justify-content-center pt-3 pb-5">
                    <Col lg={12}>
                        <Button content="Print" onClick={handlePrint} icon="print" color="blue" circular className="me-3" />
                        <Button content="Back" icon="reply" onClick={() => navigate('/directsales/so_invoice')} circular basic />
                    </Col>
                    <Col lg={12}>
                        <ComponentToPrint ref={componentRef} data={groupDetails} />
                    </Col>
                </Row>



            </Container>
        </>
    )
}