import React, { useEffect, useMemo, useState } from "react";
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Stack from "react-bootstrap/Stack"
import axios from "axios";
import { Button, Dimmer, Form, Loader, Popup } from "semantic-ui-react";
import { useMyGlobal } from "../component/Global";
import Modal from "rsuite/Modal"
import { Toaster } from "../component/Toaster";
import { Reacttable } from "../component/Reacttable";

export const MasterEdc = () => {
    const [modalAdd, setModalAdd] = useState(false)
    const [modalEdit, setMOdalEdit] = useState(false)
    const { host, userProfile, showroomOpt } = useMyGlobal()
    const [selectedEdit, setSelectedEdit] = useState(null)
    const [showroom, setShowroom] = useState('')
    const [bank, setBank] = useState('')
    const [desc, setDesc] = useState('')
    const [edcData, setEdcData] = useState([])
    const [loader, setLoader] = useState(false)

    const columns = useMemo(() => [
        {
            Header: "Showroom",
            accessor: "showroom_name"
        },
        {
            Header: "Bank",
            accessor: "bank"
        },
        {
            Header: "Description",
            accessor: "desc"
        },
        {
            Header: "Create",
            accessor: "createAt"
        },
        {
            Header: "User",
            accessor: "user_name",
            Cell: ({ cell }) => (
                <Stack direction="vertical">
                    <div>{cell.value}</div>
                    <div className="text-muted" style={{ lineHeight: '0.8em' }}>{cell.row.original.role_name}</div>
                </Stack>
            )
        },
        {
            Header: "Action",
            accessor: null,
            Cell: ({ cell }) => (
                <Button.Group size="mini" basic>
                    <Popup content="Edit" size="tiny" trigger={
                        <Button icon={{ name: 'edit', color: 'blue' }} onClick={() => handleClickEdit(cell.row.original)} />
                    } />
                    <Popup content="Delete" size="tiny" trigger={
                        <Button icon={{ name: 'trash alternate', color: 'blue' }} onClick={() => handleClickDelete(cell.row.original.id)} />
                    } />
                </Button.Group>
            )
        },

    ])

    const handleClickEdit = (data) => {
        setSelectedEdit(data)
        setShowroom(data.id_showroom)
        setBank(data.bank)
        setDesc(data.desc)
        setMOdalEdit(true)
    }

    const handleSelectShowroom = (value) => {
        setShowroom(value)
    }

    const handleClickAdd = () => {
        setModalAdd(true)
    }

    const handleSubmitEdc = () => {
        const param = new FormData()
        param.append('showroom', showroom)
        param.append('bank', bank)
        param.append('desc', desc)
        param.append('id_user', userProfile.profile.id_user)

        axios({
            method: "POST",
            url: `${host}/sales/index.php?action=CreateNewEdc`,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            data: param
        }).then(res => {
            if (res.data.status === 200) {
                Toaster.fire({
                    icon: 'success',
                    text: 'Success add new EDC',
                    position: 'top-right'
                })
                getEdc()
                setModalAdd(false)
                clearForm()
            } else {
                Toaster.fire({
                    icon: 'error',
                    text: res.data.msg,
                    position: 'top-right'
                })
            }
        })
    }

    const getEdc = async () => {
        setLoader(true)
        axios({
            method: "GET",
            url: `${host}/sales/index.php?action=edc`,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
        }).then(res => {
            if (res.data.status === 200) {
                setEdcData(res.data.data)
            }
        }).finally(() => {
            setLoader(false)
        })
    }

    const handleSubmitEdit = () => {
        const param = {
            bank: bank,
            desc: desc,
            id_user: userProfile.profile.id_user,
            id: selectedEdit.id,
            showroom: showroom,
        }

        axios({
            method: "PUT",
            url: `${host}/sales/index.php?action=EditEdc`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            data: param
        }).then(res => {
            if (res.data.status === 200) {
                Toaster.fire({
                    icon: 'success',
                    text: 'Success edit EDC',
                    position: 'top-right'
                })
                setMOdalEdit(false)
                getEdc()
                clearForm()
            } else {
                Toaster.fire({
                    icon: 'error',
                    text: res.data.msg,
                    position: 'top-right'
                })
            }
        })
    }

    const clearForm = () => {
        setShowroom('')
        setBank('')
        setDesc('')
        setSelectedEdit('')
    }

    const handleClickDelete = (id) => {
        const msg = window.confirm("Are you sure you want to delete this?")
        if (msg) {
            axios({
                method: "DELETE",
                url: `${host}/sales/index.php?action=DeleteEdc&id=${id}`,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
                },
            }).then(res => {
                if (res.data.status === 200) {
                    Toaster.fire({
                        icon: 'success',
                        text: 'Success delete EDC',
                        position: 'top-right'
                    })
                    getEdc()
                } else {
                    Toaster.fire({
                        icon: 'error',
                        text: res.data.msg,
                        position: 'top-right'
                    })
                }
            })
        }
    }

    useEffect(() => {
        getEdc()
    }, [])

    return (
        <>
            <Container>
                <div className="mb-3 mt-2">
                    <div className="h4">Master EDC</div>
                </div>
                <Dimmer active={loader} inverted>
                    <Loader inverted>Loading</Loader>
                </Dimmer>
                <Row className="d-flex justify-content-center">
                    <Col xl={10} lg={10} md={10} sm={12} className="mb-3">
                        <Button content="New EDC" icon="add" onClick={handleClickAdd} color="blue" />
                    </Col>
                    <Col xl={10} lg={10} md={10} sm={12}>
                        <Reacttable data={edcData} columns={columns} pageRow={10} noData={edcData.length === 0 ? true : false} />
                    </Col>
                </Row>
            </Container>

            <Modal open={modalAdd} onClose={() => setModalAdd(false)} overflow={false} backdrop="static" size="xs">
                <Modal.Header>
                    <Modal.Title>Add New EDC</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="frmAdd" onSubmit={handleSubmitEdc}>
                        <Form.Dropdown
                            options={showroomOpt}
                            value={showroom}
                            search
                            clearable
                            selection
                            label="Showroom"
                            onChange={(e, { value }) => handleSelectShowroom(value)}
                        />
                        <Form.Input type="text" label="Bank Name" placeholder="BCA" value={bank} onChange={(e) => setBank(e.target.value)} required />
                        <Form.Input type="text" label="Description" placeholder="BCA Master Card" value={desc} onChange={(e) => setDesc(e.target.value)} required />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Submit" icon="save" form="frmAdd" />
                </Modal.Footer>
            </Modal>

            <Modal open={modalEdit} onClose={() => setMOdalEdit(false)} overflow={false} backdrop="static" size="xs">
                <Modal.Header>
                    <Modal.Title>Edit EDC</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="frmEdit" onSubmit={handleSubmitEdit}>
                        <Form.Dropdown
                            options={showroomOpt}
                            value={showroom}
                            search
                            clearable
                            selection
                            label="Showroom"
                            onChange={(e, { value }) => handleSelectShowroom(value)}
                        />
                        <Form.Input type="text" label="Bank Name" placeholder="BCA" value={bank} onChange={(e) => setBank(e.target.value)} required />
                        <Form.Input type="text" label="Description" placeholder="BCA Master Card" value={desc} onChange={(e) => setDesc(e.target.value)} required />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button icon="edit" content="Save Change" form="frmEdit" color="blue" />
                </Modal.Footer>
            </Modal>
        </>
    )
}