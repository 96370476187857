import React, { useEffect, useMemo, useState } from "react";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Button, Dimmer, Dropdown, Form, FormCheckbox, FormDropdown, FormField, FormGroup, FormInput, FormRadio, Icon, Input, Loader, Popup } from 'semantic-ui-react'
import { useMyGlobal } from "../../component/Global";
import axios from "axios";
import { Toaster } from "../../component/Toaster";
import Modal from "rsuite/Modal";
import { applyDefaultFilter, Reacttable } from "../../component/Reacttable";
import Stack from "react-bootstrap/Stack";


export const MasterDept = () => {
    const [modalAdd, setModalAdd] = useState(false)
    const [modalEdit, setModalEdit] = useState(false)
    const { userProfile, host } = useMyGlobal()
    const [deptData, setDeptData] = useState([])
    const [deptCode, setDeptCode] = useState('')
    const [deptName, setDeptName] = useState('')
    const [loadData, setLoadData] = useState(false)
    const [loadProc, setLoadProc] = useState(false)
    const [selDept, setSelDept] = useState(null)

    const columns = useMemo(() => ([
        {
            Header: "Code",
            accessor: "dept_code"
        },
        {
            Header: "Dept. Name",
            accessor: "dept_name"
        },
        {
            Header: "User",
            accessor: "user_name",
            Cell: ({ cell }) => (
                <Stack direction="vertical">
                    <div style={{ lineHeight: '0.8em' }}>{cell.value}</div>
                    <div className="text-muted" style={{ lineHeight: '1em' }}>{cell.row.original.role_name}</div>
                </Stack>
            )
        },
        {
            Header: "Create At",
            accessor: "createAt"
        },
        {
            Header: "Update At",
            accessor: "updateAt"
        },
        {
            Header: "Action",
            accessor: null,
            Cell: ({ cell }) => (
                <Button.Group basic size="mini">
                    <Popup size="tiny" content="Edit" trigger={
                        <Button icon={{ name: 'edit', color: 'blue' }} onClick={() => handleClickEdit(cell.row.original)} />
                    } />
                </Button.Group>
            )
        },
    ]))

    const handleClickEdit = (data) => {
        setSelDept(data)
        setDeptCode(data.dept_code)
        setDeptName(data.dept_name)
        setModalEdit(true)
    }

    const handleClickAdd = () => {
        setModalAdd(true)
    }

    const clearForm = () => {
        setDeptCode('')
        setDeptName('')
    }

    const handleSubmit = () => {
        const existCode = deptData.some(i => i.dept_code.toLowerCase() === deptCode.toLowerCase())
        const existDesc = deptData.some(i => i.dept_name.toLowerCase() === deptName.toLowerCase())

        if (existCode) {
            Toaster.fire({
                icon: 'warning',
                text: 'Child code exist',
                position: 'top-right',
            })
            setLoadProc(false)
        } else if (existDesc) {
            Toaster.fire({
                icon: 'warning',
                text: 'Child Description exist',
                position: 'top-right',
            })
            setLoadProc(false)
        } else {
            setLoadProc(true)
            const param = {
                id_user: userProfile.profile.id_user,
                code: deptCode,
                name: deptName,
            }

            axios({
                method: "POST",
                url: `${host}/ledger/index.php?action=addDept`,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
                },
                data: param
            }).then(res => {
                if (res.data.status === 200) {
                    Toaster.fire({
                        icon: 'success',
                        text: res.data.msg,
                        position: 'top-right'
                    })
                    clearForm()
                    setModalAdd(false)
                    getDept()
                } else {
                    Toaster.fire({
                        icon: 'error',
                        text: res.data.msg,
                        position: 'top-right'
                    })
                }
            }).finally(() => setLoadProc(false))
        }
    }

    const handleSubmitEdit = () => {
        const existCode = deptData.some(i => i.dept_code.toLowerCase() === deptCode.toLowerCase())
        const existDesc = deptData.some(i => i.dept_name.toLowerCase() === deptName.toLowerCase())

        if (existCode && selDept.dept_code.toLowerCase() !== deptCode.toLowerCase()) {
            Toaster.fire({
                icon: 'warning',
                text: 'Child code exist',
                position: 'top-right',
            })
            setLoadProc(false)
        } else if (existDesc && selDept.dept_name.toLowerCase() !== deptName.toLowerCase()) {
            Toaster.fire({
                icon: 'warning',
                text: 'Child Description exist',
                position: 'top-right',
            })
            setLoadProc(false)
        } else {
            setLoadProc(true)
            const param = {
                id_user: userProfile.profile.id_user,
                code: deptCode,
                name: deptName,
                id : selDept.id
            }

            axios({
                method: "PUT",
                url: `${host}/ledger/index.php?action=editDept`,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
                },
                data: param
            }).then (res => {
                if (res.data.status === 200){
                    Toaster.fire({
                        icon: 'success',
                        text: res.data.msg,
                        position: 'top-right'
                    })
                    clearForm()
                    setModalEdit(false)
                    getDept()
                } else {
                    Toaster.fire({
                        icon: 'error',
                        text: res.data.msg,
                        position: 'top-right'
                    })
                }
            }).finally(setLoadProc(false))
        }
    }

    const getDept = async () => {
        setLoadData(true)

        await axios({
            method: "GET",
            url: `${host}/ledger/index.php`,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            params: {
                action: 'getDept'
            }
        }).then (res => {
            if (res.data.status === 200){
                setDeptData(res.data.data)
            } else {
                Toaster.fire({
                    icon:'error',
                    text:res.data.msg,
                    position:'top-right'
                })
            }
        }).finally(setLoadData(false))
    }

    useEffect(() => {
        getDept()
    },[])

    return (
        <>
            <Container>
                <div className="mb-3 mt-2">
                    <div className="h4">Departement</div>
                </div>
                <Row className="d-flex justify-content-center">
                    <Dimmer active={loadData} inverted>
                        <Loader inverted>Loading...</Loader>
                    </Dimmer>
                    <Col xl={8} lg={10} md={12} className="mb-4">
                        <Button content="Add New Departement" icon="add" onClick={handleClickAdd} color="blue" />
                    </Col>
                    <Col xl={8} lg={10} md={12} className="mb-3">
                        <Reacttable
                            data={deptData}
                            columns={columns}
                            pageRow={10}
                            noData={deptData.length === 0 ? true : false}
                        />
                    </Col>
                </Row>
            </Container>

            <Modal open={modalAdd} onClose={() => setModalAdd(false)} overflow={false} backdrop="static" size="xs">
                <Modal.Header>
                    <Modal.Title>Add New Departement</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="frmAdd" onSubmit={handleSubmit}>
                        <Form.Group grouped>
                            <FormInput
                                label="Dept. Code"
                                maxLength="5"
                                value={deptCode}
                                required
                                placeholder="HRD"
                                onChange={(e) => setDeptCode(e.target.value)}
                            />
                            <FormInput
                                label="Dept. Name"
                                maxLength="30"
                                value={deptName}
                                required
                                placeholder="Human Resource Development"
                                onChange={(e) => setDeptName(e.target.value)}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Submit" type="submit" form="frmAdd" color="blue" icon="save" loading={loadProc} disabled={loadProc} />
                </Modal.Footer>
            </Modal>

            <Modal open={modalEdit} onClose={() => setModalEdit(false)} overflow={false} backdrop="static" size="xs">
                <Modal.Header>
                    <Modal.Title>Edit Departement</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="frmEdit" onSubmit={handleSubmitEdit}>
                        <Form.Group grouped>
                            <FormInput
                                label="Dept. Code"
                                maxLength="5"
                                value={deptCode}
                                required
                                placeholder="HRD"
                                onChange={(e) => setDeptCode(e.target.value)}
                            />
                            <FormInput
                                label="Dept. Name"
                                maxLength="30"
                                value={deptName}
                                required
                                placeholder="Human Resource Development"
                                onChange={(e) => setDeptName(e.target.value)}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Submit" type="submit" form="frmEdit" color="blue" icon="save" loading={loadProc} disabled={loadProc} />
                </Modal.Footer>
            </Modal>

        </>
    )
}