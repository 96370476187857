import React, { useMemo, useState } from "react";
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import axios from "axios";
import { useMyGlobal } from "../component/Global";
import { Button, Form, FormField, FormGroup, FormInput, FormSelect, Popup, Radio } from "semantic-ui-react";
import Modal from "rsuite/modal"
import { Reacttable } from "../component/Reacttable";
import Badge from "rsuite/Badge";
import { Toaster } from "../component/Toaster";
import moment from "moment";
import Table from "react-bootstrap/Table";
import { curencyNoSym } from "../component/utils";

export const SaleThrough = () => {
    const [saleThroughData, setSaleThroughData] = useState([])
    const { userProfile,host, warehouseOpt, brandOpt } = useMyGlobal()
    const [selWH, setSelWH] = useState("")
    const [selBrand, setSelBrand] = useState("")
    const [loadGenerate, setLoadGenerate] = useState(false)
    const [dataSoldWeb, setDataSoldWeb] = useState([])
    const [modalSoldWeb, setModalSoldWeb] = useState(false)
    const [webWarehouse, setWebWarehouse] = useState('WH0001')
    const [selArticle, setSelArticle] = useState(null)
    const [selWeb, setSelWeb] = useState('rudrunner')
    const [loadWeb, setLoadWeb] = useState(false)

    const columns = useMemo(() => [
        {
            Header: "Brand",
            accessor: "brand"
        },
        {
            Header: "Article",
            accessor: "article"
        },
        {
            Header: "Description",
            accessor: "description",
            Cell:({cell}) => (
                <div style={{width:'120px'}}>
                    <Popup content={cell.value} size="tiny" trigger={
                        <div className="text-truncate">{cell.value}</div>
                    }/>
                </div>
            )
        },
        {
            Header: "Cogs",
            accessor: "cogs",
            Cell:({cell}) => (curencyNoSym.format(cell.value))
        },
        {
            Header: "Price",
            accessor: "price",
            Cell:({cell}) => (curencyNoSym.format(cell.value))   
        },
        {
            Header: "Season",
            accessor: "season",
            Cell:({cell}) => (
                <div style={{width:'70px'}}>
                    <Popup content={cell.value} size="tiny" trigger={
                        <div className="text-truncate">{cell.value}</div>
                    }/>
                </div>
            )
        },
        {
            Header: "Received",
            accessor: "qty_received"
        },
        {
            Header: "Sold",
            accessor: "qty_sold",
        },
        {
            Header: "Available",
            accessor: "qty_available"
        },
        {
            Header: "Percentage",
            accessor: "percentage_sold",
            Cell:({cell}) => (
                <div className="fw-bold">
                    <span className="me-1">{cell.value}</span>
                    <span>%</span>
                </div>
            )
        },
        {
            Header: "Status",
            accessor: "product_status",
            Cell:({cell}) => (
                <div>
                    <span className="me-2"><Badge color={cell.value == 'Slow Moving' ? 'orange' : 'green'}/></span>
                    <span>{cell.value}</span>
                </div>
            )
        },
        {
            Header: "Move In",
            accessor: "movement_in",
            Cell:({cell}) => (
                <Popup content="Stock comes In outside the transaction (Stock Transfer, Adjustment etc.)" size="tiny" trigger={<span>{cell.value}</span>}/>
            )
        },
        {
            Header: "Move Out",
            accessor: "movement_out",
            Cell:({cell}) => (
                <Popup content="Stock comes Out outside the transaction (Stock Transfer, Adjustment etc.)" size="tiny" trigger={<span>{cell.value}</span>}/>
            )
        },
        {
            Header: "Return",
            accessor: "movement_return",
            Cell:({cell}) => (
                <Popup content="Transaction return" size="tiny" trigger={<span>{cell.value}</span>}/>
            )
        },
        {
            Header: "Action",
            accessor: null,
            Cell: ({ cell }) => (
                <Button.Group basic size="mini">
                    <Popup content="Sold on Website" size="mini" trigger={
                        <Button icon={{ name: 'globe', color: 'blue' }} onClick={() => handleGetSoldOnWeb(cell.row.original)} />
                    } />
                </Button.Group>
            )
        },
    ])

    const columnsSoldWeb = useMemo(() => [
        {
            Header: "ID Order",
            accessor: "id_order"
        },
        {
            Header: "Date",
            accessor: "order_date"
        },
        {
            Header: "Customer",
            accessor: "customer"
        },
        {
            Header: "Barcode",
            accessor: "barcode"
        },
        {
            Header: "Size",
            accessor: "size"
        },
        {
            Header: "Dicount",
            accessor: "discount",
            Cell:({cell}) => (<>{cell.value} %</>)
        },
        {
            Header: "Final Price",
            accessor: "total_price",
            Cell:({cell}) => (<>{curencyNoSym.format(cell.value)}</>)
        },
        {
            Header: "Qty",
            accessor: "qty"
        },

    ])

    const handleGetSoldOnWeb = (row) => { 
        setDataSoldWeb([])
        setSelArticle(row.article)
        setModalSoldWeb(true)
    }
   
    const handleSubmitGetSoldWeb = () => { 
        if (selWeb === 'rudrunner'){
            getSoldOnWebRud(selArticle, webWarehouse)
        } else if (selWeb === 'hoopsindonesia'){
            setDataSoldWeb([])
            alert('Not available now...')
        } 
    }

    const getSoldOnWebRud = (article, warehouse) => {
        setLoadWeb(true)
        axios({
            method:'GET',
            url:'https://rudrunner.co.id/api_rudrunner/controler/order.php',
            headers:{
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            params:{
                index:18,
                article:article,
                id_warehouse:warehouse
            }
        }). then (res => {
            if (res.data.status === 200){
                setDataSoldWeb(res.data.data)
            }
        }). finally(() => setLoadWeb(false))
    }
   
    const getSaleThrough = () => {
        setLoadGenerate(true)
        axios({
            method: "GET",
            url: `${host}/sales/`,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            params:{
                id_warehouse:selWH,
                brand_code:selBrand,
                action:'saleThroughReport'
            }
        }).then (res => {
            if (res.data.status === 200){
                setSaleThroughData(res.data.data)
            }
        }).finally(() => setLoadGenerate(false))
    }
    
    const handleGenerateSale = () => {
        getSaleThrough()
    }

    const exportExcel = () => {
        const param = new FormData()
        param.append("id_warehouse", selWH)
        param.append("brand_code", selBrand)

        axios({
            method: "POST",
            url: `${host}/sales/?action=exportSaleThrough`,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            data:param,
            responseType: 'blob'
        }).then (res => {
                const url = window.URL.createObjectURL(new Blob([res.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', `Sale_through_${moment().format('YYYY-MM-DD HH:mm:ss')}.xlsx`);
                document.body.appendChild(link)
                link.click()
                window.URL.revokeObjectURL(url);
        })
    }

    

    return (
        <>
            <Container>
                <div className="mb-3 mt-2">
                    <div className="h4">Sale Through</div>
                </div>
                <Row className="d-flex justify-content-center">
                    <Col xl={6} lg={9} md={8} className="mb-3">
                        <Form id="frmGenerate">
                            <Form.Group>
                                <FormSelect
                                    required
                                    label="Warehouse"
                                    search
                                    options={warehouseOpt}
                                    value={selWH}
                                    onChange={(e, { value }) => setSelWH(value)}
                                />
                                <FormSelect
                                    required
                                    label="Brand"
                                    search
                                    options={brandOpt}
                                    value={selBrand}
                                    onChange={(e, { value }) => setSelBrand(value)}
                                />
                                <Form.Field>
                                    <label></label>
                                    <Button className="mt-4" loading={loadGenerate} disabled={loadGenerate} content="Generate Data" form="frmGenerate" type="submit" icon="magic" onClick={handleGenerateSale} color="blue" />
                                </Form.Field>
                            </Form.Group>
                        </Form>
                    </Col>
                    <Col xl={4} lg={3} md={4} className="text-end">
                        <Button content="Export to Excel" icon="file excel" color="orange" style={{marginTop:'1.8em'}} onClick={() => exportExcel()}/>
                    </Col>
                    <Col xl={10} lg={12} md={12}>
                        <Reacttable
                            columns={columns}
                            data={saleThroughData} 
                            pageRow={10}
                            noData={saleThroughData.length === 0 ? true : false}
                        />
                    </Col>
                </Row>
            </Container>

            <Modal open={modalSoldWeb} onClose={() => setModalSoldWeb(false)} overflow={false} backdrop='static' size="md">
                <Modal.Header>
                    <Modal.Title>Sold on Website</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={12} className="mb-3">
                            <Form id="frmSoldWeb" onSubmit={handleSubmitGetSoldWeb}>
                                <FormGroup inline>
                                <Radio 
                                    className="me-3"
                                    label="rudrunner.co.id"
                                    value="WH0001"
                                    checked={webWarehouse === 'WH0001'}
                                    onChange={(e,{value}) => {setWebWarehouse(value); setSelWeb('rudrunner')}}
                                />
                                <Radio 
                                    className="me-3"
                                    label="hoopsindonesia.co.id"
                                    value="WH0005"
                                    checked={webWarehouse === 'WH0005'}
                                    onChange={(e,{value}) => {setWebWarehouse(value); setSelWeb('hoopsindonesia')}}
                                />
                                <Button content="Get Data" type="submit" form="frmSoldWeb" icon="cloud download" size="tiny" color="orange" loading={loadWeb}/>
                               </FormGroup>
                            </Form>
                        </Col>
                        <Col md={12}>
                            <Reacttable data={dataSoldWeb} columns={columnsSoldWeb} pageRow={10} totals={['qty', 'total_price']} noData={dataSoldWeb.length > 0 ? false : true}/>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    )
}