import React, { useEffect, useMemo, useState } from "react";
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Stack from "react-bootstrap/Stack"
import { useMyGlobal } from "../component/Global";
import axios from "axios";
import Modal from "rsuite/Modal"
import { Button, Dropdown, Form, FormCheckbox, FormGroup, Input, Popup } from "semantic-ui-react";
import { Reacttable } from "../component/Reacttable";
import { Toaster } from "../component/Toaster";
import { FormCheck } from "react-bootstrap";
import Tabs from 'rsuite/Tabs';

export const Role = () => {
    const [modalGroup, setModalGroup] = useState(false)
    const [modalAdd, setModalAdd] = useState(false)
    const [modalEdit, setModalEdit] = useState(false)
    const { host, userProfile, showroomOpt } = useMyGlobal()
    const [showroom, setShowroom] = useState([])
    const [roleName, setRoleName] = useState('')
    const [roleDesc, setRoleDesc] = useState('')
    const [roleData, setRoleData] = useState([])
    const [selectedRole, setSelectedRole] = useState(null)
    const [modalAccess, setModalAccess] = useState(false)
    const [pageOpt, setPageOpt] = useState([])
    const [pageChildOpt, setPageChildOpt] = useState([])
    const [selectedPage, setSelectedPage] = useState([])
    const [selectedPageChild, setSelectedPageChild] = useState([])

    const [isCashier, setIsCashier] = useState(0)

    const columns = useMemo(() => [
        {
            Header: 'ID',
            accessor: "header.id_role",
        },
        {
            Header: 'Role Name',
            accessor: "header.role_name",
            Cell: ({ cell }) => (
                <div className="text-uppercase">{cell.value}</div>
            )
        },
        {
            Header: 'Description',
            accessor: "header.desc",
        },
        {
            Header: 'Create',
            accessor: "header.createAt",
        },
        {
            Header: 'Last Update',
            accessor: "header.updateAt",
        },
        {
            Header: 'Create By',
            accessor: "header.user_name",
            Cell: ({ cell }) => (
                <Stack direction="vertical">
                    <div>{cell.value}</div>
                    <div className="text-muted" style={{ lineHeight: '0.6em' }}>{cell.row.original.header.role_name_create}</div>
                </Stack>
            )
        },
        {
            Header: 'Action',
            accessor: null,
            Cell: ({ cell }) => (
                <Button.Group size="mini" basic>
                    <Popup size="tiny" content="Group" trigger={
                        <Button icon="group" onClick={() => handleClickGroup(cell.row.original)} />
                    } />
                    <Popup size="tiny" content="Edit" trigger={
                        <Button icon="edit" onClick={() => handleClickEdit(cell.row.original)} />
                    } />
                    <Popup size="tiny" content="Access Page" trigger={
                        <Button icon="lock" onClick={() => handleClickAccess(cell.row.original)} />
                    } />
                </Button.Group>
            )
        },
    ])

    const columnsGroup = useMemo(() => [
        {
            Header: 'Showroom',
            accessor: "showroom_name",
        },
        {
            Header: 'warehouse',
            accessor: "warehouse_name",
        },
        {
            Header: 'Category',
            accessor: "category",
        },
        {
            Header: 'Supervisor',
            accessor: "supervisor",
        },
        {
            Header: 'Manager',
            accessor: "manager",
        },
    ])

    const columnsAccess = useMemo(() => [
        {
            Header: 'ID',
            accessor: "id_access",
        },
        {
            Header: 'Page Name',
            accessor: "page_name",
        },
        {
            Header: 'Description',
            accessor: "description",
        },
        {
            Header: 'Type',
            accessor: "page_type",
            Cell: ({ cell }) => <div className="text-capitalize">{cell.value}</div>
        },
        {
            Header: 'Action',
            accessor: null,
            Cell: ({ cell }) => (
                <Popup content="Remove Access" size="tiny" trigger={
                    <Button icon="trash alternate" size="mini" basic onClick={() => removeAccess(cell.row.original.id_access, cell.row.original.id_role)} />
                } />
            )
        }
    ])

    const columnsAccessChild = useMemo(() => [
        {
            Header: 'ID',
            accessor: "id_access",
        },
        {
            Header: 'Name',
            accessor: "page_name",
        },
        {
            Header: 'Parent Name',
            accessor: "parent_name",
        },
        {
            Header: 'Description',
            accessor: "description",
        },
        {
            Header: 'Type',
            accessor: "type",
            Cell: ({ cell }) => <div className="text-capitalize">{cell.value}</div>
        },
        {
            Header: 'Action',
            accessor: null,
            Cell: ({ cell }) => (
                <Popup content="Remove Access" size="tiny" trigger={
                    <Button icon="trash alternate" size="mini" basic onClick={() => removeAccessChild(cell.row.original.id_access, cell.row.original.id_role)} />
                } />
            )
        }
    ])

    const handleClickAccess = (data) => { 
        setSelectedRole(data)
        getPage(data)
        getPageChild(data)
        setModalAccess(true)
    }

    const handleClickEdit = (data) => {
        setSelectedRole(data)
        setRoleName(data.header.role_name ? data.header.role_name : '')
        setRoleDesc(data.header.desc ? data.header.desc : '')
        setShowroom(data.detail.map(item => item.id_showroom))
        setIsCashier(data.header.isCashier)
        setModalEdit(true)
    }

    const handleClickCreate = () => {
        clearForm()
        setModalAdd(true)
    }

    const clearForm = () => {
        setIsCashier(0)
        setRoleName('')
        setRoleDesc('')
        setShowroom([])
    }

    const handleClickGroup = (data) => {
        setSelectedRole(data)
        setModalGroup(true)
    }

    const handleSubmitRole = () => {
        const param = new FormData()
        param.append('role_name', roleName)
        param.append('role_desc', roleDesc)
        param.append('id_user', userProfile.profile.id_user)
        param.append('showroom', JSON.stringify(showroom))
        param.append('isCashier', isCashier ? 1 : 0)

        axios({
            method: "POST",
            url: `${host}/user/index.php?action=createRole`,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            data: param
        }).then(res => {
            if (res.data.status === 200) {
                Toaster.fire({
                    icon: 'success',
                    text: 'Success create new role',
                    position: 'top-right',
                })
                getRole()
                setModalAdd(false)
                clearForm()
            } else {
                Toaster.fire({
                    icon: 'error',
                    text: res.data.msg,
                    position: 'top-right',
                })
            }
        })
    }
    
    const handleAddPage = async (idRole) => {
        const param = new FormData()
        param.append("id_role", idRole)
        param.append("page", JSON.stringify(selectedPage))
        await axios({
            method: "POST",
            url: `${host}/user/index.php?action=createRoleAccess`,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            data: param
        }).then(res => {
            if (res.data.status === 200) {
                Toaster.fire({
                    icon: 'success',
                    text: 'Success add new access page',
                    position: 'top-right'
                })
                setModalAccess(false)
                getRole()
            } else {
                Toaster.fire({
                    icon: 'error',
                    text: res.data.msg,
                    position: 'top-right'
                })
            }
        })
    }

    const handleAddPageChild = async (idRole) => {
        const param = new FormData()
        param.append("id_role", idRole)
        param.append("page", JSON.stringify(selectedPageChild))

        await axios({
            method: "POST",
            url: `${host}/user/index.php?action=createRoleAccessChild`,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            data: param
        }).then(res => {
            if (res.data.status === 200) {
                Toaster.fire({
                    icon: 'success',
                    text: 'Success add new access page',
                    position: 'top-right'
                })
                setModalAccess(false)
                getRole()
            } else {
                Toaster.fire({
                    icon: 'error',
                    text: res.data.msg,
                    position: 'top-right'
                })
            }
        })
    }

    const removeAccess = async (id_access) => {
        await axios({
            method: "DELETE",
            url: `${host}/user/index.php?action=removeAccess&id=${id_access}`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
        }).then(res => {
            if (res.data.status === 200) {
                Toaster.fire({
                    icon: 'success',
                    text: 'Success remove access',
                    position: 'top-right'
                })
                getRole()
                setModalAccess(false)
            } else {
                Toaster.fire({
                    icon: 'error',
                    text: res.data.msg,
                    position: 'top-right'
                })
            }
        })
    }

    const removeAccessChild = async (id_access) => {
        await axios({
            method: "DELETE",
            url: `${host}/user/index.php?action=removeAccessChild&id=${id_access}`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
        }).then(res => {
            if (res.data.status === 200) {
                Toaster.fire({
                    icon: 'success',
                    text: 'Success remove access child',
                    position: 'top-right'
                })
                getRole()
                setModalAccess(false)
            } else {
                Toaster.fire({
                    icon: 'error',
                    text: res.data.msg,
                    position: 'top-right'
                })
            }
        })
    }

    const getPage = async (accessData) => {
        await axios({
            method: "GET",
            url: `${host}/user/index.php?action=page`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
        }).then(res => {
            if (res.data.status === 200) {
                const data = res.data.data
                const idPage = accessData.access.map(item => item.id_page);
                const filteredData = data.filter(item => !idPage.includes(item.id_page));

                setPageOpt(filteredData.map(i => {
                    return { key: i.id_page, value: i.id_page, text: i.page_name }
                }))
            }
        })
    }

    const getPageChild = async (accessData) => { 
        await axios({
            method: "GET",
            url: `${host}/user/index.php?action=pageChild`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
        }).then(res => {
            if (res.data.status === 200) {
                const data = res.data.data
                const idPage = accessData.access_child.map(item => item.id_child);
                const filteredData = data.filter(item => !idPage.includes(item.id_child));
                setPageChildOpt(filteredData.map(i => {
                    return { key: i.id_child, value: i.id_child, text: `${i.parent_name} > ${i.page_name}` }
                }))
            }
        })
    }

    const getRole = async () => {
        await axios({
            method: "GET",
            url: `${host}/user/index.php?action=role`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
        }).then(res => {
            if (res.data.status === 200) {
                setRoleData(res.data.data)
            }
        })
    }

    const handleSubmitEditRole = () => {
        axios({
            method: "PUT",
            url: `${host}/user/index.php?action=editRole`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            data: {
                id_role: selectedRole.header.id_role,
                role_name: roleName,
                desc: roleDesc,
                showroom: showroom,
            }
        }).then(res => {
            if (res.data.status === 200) {
                Toaster.fire({
                    icon: 'success',
                    text: 'Success update role',
                    position: 'top-right'
                })
                getRole()
                setModalEdit(false)
            } else {
                Toaster.fire({
                    icon: 'error',
                    text: res.data.msg,
                    position: 'top-right'
                })
            }
        })
    }

    useEffect(() => {
        getRole()
    }, [])

    return (
        <>
            <Container>
                <div className="mb-3 mt-2">
                    <div className="h4">Role</div>
                </div>
                <Row className="d-flex justify-content-center">
                    <Col xl={10} lg={12} md={12} sm={12} className="mb-3">
                        <Button content="Create new Role" icon="add" onClick={handleClickCreate} color="blue" />
                    </Col>
                    <Col xl={10} lg={12} md={12} sm={12}>
                        <Reacttable data={roleData} columns={columns} pageRow={10} noData={roleData.length === 0 ? true : false} />
                    </Col>
                </Row>
            </Container>

            <Modal open={modalGroup} onClose={() => setModalGroup(false)} overflow={false} backdrop="static" >
                <Modal.Header>
                    <Modal.Title>Group List</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Reacttable data={selectedRole?.detail} columns={columnsGroup} rowPage={10} />
                </Modal.Body>
            </Modal>

            <Modal open={modalAdd} onClose={() => setModalAdd(false)} overflow={false} backdrop="static" size="xs">
                <Modal.Header>
                    <Modal.Title>Create New Role</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col lg={12}>
                            <Form id="frmAdd" onSubmit={handleSubmitRole}>
                                <Form.Input type="text" value={roleName} label="Role Name" onChange={(e) => setRoleName(e.target.value)} />
                                <Form.Input type="text" value={roleDesc} label="Description" onChange={(e) => setRoleDesc(e.target.value)} />
                                <Form.Dropdown
                                    options={showroomOpt}
                                    multiple
                                    selection
                                    search
                                    label="Group"
                                    clearable
                                    value={showroom}
                                    onChange={(e, { value }) => setShowroom(value)}
                                />
                                <FormCheckbox checked={isCashier} onChange={((e, data) => setIsCashier(data.checked))} label="Cashier" />
                            </Form>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Submit" icon="save" form="frmAdd" />
                </Modal.Footer>
            </Modal>

            <Modal open={modalEdit} onClose={() => setModalEdit(false)} overflow={false} backdrop="static" size="xs">
                <Modal.Header>
                    <Modal.Title>Edit Role</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col lg={12}>
                            <Form id="frmEdit" onSubmit={handleSubmitEditRole}>
                                <Form.Input type="text" value={roleName} label="Role Name" onChange={(e) => setRoleName(e.target.value)} />
                                <Form.Input type="text" value={roleDesc} label="Description" onChange={(e) => setRoleDesc(e.target.value)} />
                                <Form.Dropdown
                                    options={showroomOpt}
                                    multiple
                                    selection
                                    search
                                    label="Group"
                                    clearable
                                    value={showroom}
                                    onChange={(e, { value }) => setShowroom(value)}
                                />
                                <FormCheckbox checked={isCashier == '1' ? true : false} onChange={((e, data) => setIsCashier(data.checked))} label="Cashier" />
                            </Form>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" content="Submit" icon="save" form="frmEdit" />
                </Modal.Footer>
            </Modal>

            <Modal open={modalAccess} onClose={() => setModalAccess(false)} overflow={false}>
                <Modal.Header>
                    <Modal.Title>Access Page</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Stack direction="vertical" className="mb-3">
                        <div className="fw-bold text-capitalize">{selectedRole?.header.role_name}</div>
                        <div className="text-capitalize">{selectedRole?.header.desc}</div>
                    </Stack>
                    <Tabs defaultActiveKey="1" appearance="tabs">
                        <Tabs.Tab eventKey="1" title="Parent Page">
                            <div className="mb-3">
                                <Form >
                                    <Form.Group inline>
                                        <Dropdown
                                            className="me-2"
                                            labeled
                                            placeholder="Select Access Page"
                                            options={pageOpt}
                                            selection
                                            multiple
                                            clearable
                                            search
                                            onChange={(e, { value }) => setSelectedPage(value)}
                                        />
                                        <Button content="Add Page" icon="plus" onClick={() => handleAddPage(selectedRole?.header.id_role)} />
                                    </Form.Group>
                                </Form>
                            </div>
                            <Reacttable data={selectedRole?.access} columns={columnsAccess} pageRow={5} />
                        </Tabs.Tab>
                        <Tabs.Tab eventKey="2" title="Child Page">
                            <div className="mb-3">
                                <Form >
                                    <Form.Group inline>
                                        <Dropdown
                                            className="me-2"
                                            labeled
                                            placeholder="Select Access Page"
                                            options={pageChildOpt}
                                            selection
                                            multiple
                                            clearable
                                            search
                                            onChange={(e, { value }) => setSelectedPageChild(value)}
                                        />
                                        <Button content="Add Page" icon="plus" onClick={() => handleAddPageChild(selectedRole?.header.id_role)} />
                                    </Form.Group>
                                </Form>
                            </div>
                            <Reacttable data={selectedRole?.access_child} columns={columnsAccessChild} pageRow={5} />
                        </Tabs.Tab>
                    </Tabs>
                </Modal.Body>
            </Modal>
        </>
    )
}