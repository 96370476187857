import React, { useEffect, useMemo, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Stack from "react-bootstrap/Stack"
import { Button, Dimmer, Form, Loader, Popup } from "semantic-ui-react";
import { useMyGlobal } from "../component/Global";
import Modal from "rsuite/Modal"
import { Toaster } from "../component/Toaster";
import axios from "axios";
import { Reacttable } from "../component/Reacttable";

export const MasterCreditCard = () => {
    const { host, userProfile } = useMyGlobal()
    const [modalAdd, setModalAdd] = useState(false)
    const [modalEdit, setModalEdit] = useState(false)
    const [dataCc, setDataCc] = useState([])
    const [cardType, setCardType] = useState('')
    const [classified, setClassified] = useState('')
    const [selectedEdit, setSelectedEdit] = useState(null)
    const [bank, setBank] = useState('')
    const [desc, setDesc] = useState('')
    const [loader, setLoader] = useState(false)

    const classOpt = [
        { key: 0, text: 'Master Card', value: 'master card' },
        { key: 1, text: 'Visa', value: 'visa' },
        { key: 2, text: 'American Express', value: 'american express' },
        { key: 3, text: 'JCB', value: 'jcb' },
        { key: 4, text: 'Debit Card', value: 'debit card' },
        { key: 5, text: 'Digital Payment', value: 'digital payment' },
        { key: 6, text: 'Transfer Bank', value: 'transfer bank' },
        { key: 7, text: 'QRIS', value: 'qris' },
    ]
    const cardTypeOpt = [
        { key: 0, text: "Credit Card", value: 'CC' },
        { key: 1, text: "Debit Card", value: 'DC' },
        { key: 2, text: "Website", value: 'WS' },
        { key: 3, text: "Market Place", value: 'MP' },
        { key: 4, text: "Tiktok", value: 'TK' },
        { key: 5, text: "Transfer", value: 'TF' },
        { key: 6, text: "QRIS", value: 'qris' },
    ]

    const columns = useMemo(() => [
        {
            Header: "Card Type",
            accessor: "card_type",
            Cell: ({ cell }) => (<div className="fw-bold">{cell.value}</div>)
        },
        {
            Header: "Classified",
            accessor: "class",
            Cell: ({ cell }) => (<div className="text-capitalize">{cell.value}</div>)
        },
        {
            Header: "Bank",
            accessor: "bank"
        },
        {
            Header: "Description",
            accessor: "desc"
        },
        {
            Header: "Create",
            accessor: "createAt"
        },
        {
            Header: "User",
            accessor: "user_name",
            Cell: ({ cell }) => (
                <Stack direction="vertical">
                    <div>{cell.value}</div>
                    <div className="text-muted" style={{ lineHeight: '0.8em' }}>{cell.row.original.role_name}</div>
                </Stack>
            )
        },
        {
            Header: "Action",
            accessor: null,
            Cell: ({ cell }) => (
                <Button.Group size="mini" basic>
                    <Popup size="tiny" content="Edit" trigger={
                        <Button icon={{ name: 'edit', color: 'blue' }} onClick={() => handleClickEdit(cell.row.original)} />
                    } />
                    <Popup size="tiny" content="Delete" trigger={
                        <Button icon={{ name: 'trash alternate', color: 'blue' }} onClick={() => handleClickDelete(cell.row.original.id)} />
                    } />
                </Button.Group>
            )
        },
    ])

    const handleClickDelete = (id) => {
        axios({
            method: "DELETE",
            url: `${host}/sales/index.php?action=DeleteCC&id=${id}`,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
        }).then(res => {
            if (res.data.status === 200) {
                Toaster.fire({
                    icon: 'success',
                    text: 'Success delete CC',
                    position: 'top-right'
                })
                getCC()
            } else {
                Toaster.fire({
                    icon: 'error',
                    text: res.data.msg,
                    position: 'top-right'
                })
            }
        })
    }

    const handleClickAdd = () => {
        setModalAdd(true)
    }

    const handleSubmit = () => {
        const param = new FormData()

        param.append('card_type', cardType)
        param.append('class', classified)
        param.append('bank', bank)
        param.append('desc', desc)
        param.append('id_user', userProfile.profile.id_user)

        axios({
            method: "POST",
            url: `${host}/sales/index.php?action=CreateCC`,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            data: param
        }).then(res => {
            if (res.data.status === 200) {
                Toaster.fire({
                    icon: 'success',
                    text: 'Success adding new card',
                    position: 'top-right'
                })
                setModalAdd(false)
                getCC()
                clearForm()
            } else {
                Toaster.fire({
                    icon: 'error',
                    text: res.data.msg,
                    position: 'top-right'
                })
            }
        })
    }

    const getCC = async () => {
        setLoader(true)
        await axios({
            method: "GET",
            url: `${host}/sales/index.php?action=CC`,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
        }).then(res => {
            if (res.data.status === 200) {
                setDataCc(res.data.data)
            }
        }).finally(() => setLoader(false))
    }

    const clearForm = () => {
        setSelectedEdit(null)
        setCardType('')
        setClassified('')
        setBank('')
        setDesc('')
    }

    const handleClickEdit = (data) => {
        setSelectedEdit(data)
        setCardType(data.card_type)
        setClassified(data.class)
        setBank(data.bank)
        setDesc(data.desc)
        setModalEdit(true)
    }

    const handleSubmitEdit = () => {
        const param = {
            card_type: cardType,
            class: classified,
            bank: bank,
            desc: desc,
            id_user: userProfile.profile.id_user,
            id: selectedEdit.id
        }

        axios({
            method: "PUT",
            url: `${host}/sales/index.php?action=EditCC`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            data: param
        }).then(res => {
            if (res.data.status === 200) {
                Toaster.fire({
                    icon: 'success',
                    text: "Success edi CC",
                    position: 'top-right'
                })
                getCC()
                clearForm()
                setModalEdit(false)
            } else {
                Toaster.fire({
                    icon: 'error',
                    text: res.data.msg,
                    position: 'top-right'
                })
            }
        })
    }

    useEffect(() => {
        getCC()
    }, [])

    return (
        <>
            <Container>
                <div className="mb-3 mt-2">
                    <div className="h4">Master Credit Card</div>
                </div>
                <Dimmer active={loader} inverted>
                    <Loader inverted>Loading ...</Loader>
                </Dimmer>
                <Row className="d-flex justify-content-center">
                    <Col xl={10} lg={10} md={10} sm={12} className="mb-3">
                        <Button content="New Credit Card" icon="add" onClick={handleClickAdd} color="blue" />
                    </Col>
                    <Col xl={10} lg={10} md={10} sm={12}>
                        <Reacttable data={dataCc} columns={columns} pageRow={10} noData={dataCc.length === 0 ? true : false} />
                    </Col>
                </Row>
            </Container>

            <Modal open={modalAdd} onClose={() => setModalAdd(false)} overflow={false} backdrop="static" size="xs">
                <Modal.Header>
                    <Modal.Title>Add Credit Card</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="frmAdd" onSubmit={handleSubmit}>
                        <Form.Dropdown
                            options={cardTypeOpt}
                            selection
                            clearable
                            value={cardType}
                            label="Card Type"
                            onChange={(e, { value }) => setCardType(value)}
                        />
                        <Form.Dropdown
                            options={classOpt}
                            value={classified}
                            clearable
                            label="Classified"
                            selection
                            onChange={(e, { value }) => setClassified(value)}
                        />
                        <Form.Input type="text" value={bank} onChange={(e) => setBank(e.target.value)} label="Bank" required />
                        <Form.Input type="text" value={desc} onChange={(e) => setDesc(e.target.value)} required label="Description" />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Submit" icon="save" form="frmAdd" color="blue" />
                </Modal.Footer>
            </Modal>

            <Modal open={modalEdit} onClose={() => setModalEdit(false)} overflow={false} backdrop="static" size="xs">
                <Modal.Header>
                    <Modal.Title>Edit Credit Card</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="frmEdit" onSubmit={handleSubmitEdit}>
                        <Form.Dropdown
                            options={cardTypeOpt}
                            selection
                            clearable
                            value={cardType}
                            label="Card Type"
                            onChange={(e, { value }) => setCardType(value)}
                        />
                        <Form.Dropdown
                            options={classOpt}
                            value={classified}
                            clearable
                            label="Classified"
                            selection
                            onChange={(e, { value }) => setClassified(value)}
                        />
                        <Form.Input type="text" value={bank} onChange={(e) => setBank(e.target.value)} label="Bank" required />
                        <Form.Input type="text" value={desc} onChange={(e) => setDesc(e.target.value)} required label="Description" />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Save Change" icon="save" form="frmEdit" color="blue" />
                </Modal.Footer>
            </Modal>
        </>
    )
}