import React, { useEffect, useMemo, useState } from "react";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Button, Dimmer, Dropdown, Form, FormCheckbox, FormDropdown, FormField, FormGroup, FormInput, FormRadio, Icon, Input, Loader, Popup } from 'semantic-ui-react'
import { useMyGlobal } from "../../component/Global";
import axios from "axios";
import { Toaster } from "../../component/Toaster";
import Modal from "rsuite/Modal";
import { applyDefaultFilter, Reacttable } from "../../component/Reacttable";
import Stack from "react-bootstrap/Stack";

export const CostCenter = () => {
    const [modalAdd, setModalAdd] = useState(false)
    const [modalEdit, setModalEdit] = useState(false)
    const { userProfile, host } = useMyGlobal()
    const [costData, setCostData] = useState([])
    const [parentOpt, setParentOpt] = useState([])
    const [loadData, setLoadData] = useState(false)
    const [loadProc, setLoadProc] = useState(false)
    const [costParent, setCostParent] = useState('')
    const [childCode, setChildCode] = useState('')
    const [childDesc, setChildDesc] = useState('')
    const [active, setActive] = useState('Y')
    const [childId, setChildId] = useState('')
    const [selCost, setSelCost] = useState(null)

    const columns = useMemo(() => applyDefaultFilter([
        {
            Header: "Code",
            accessor: "child_code"
        },
        {
            Header: "Description",
            accessor: "description"
        },
        {
            Header: "Active",
            accessor: "is_active"
        },
        {
            Header: "User",
            accessor: "user_name",
            Cell: ({ cell }) => (
                <Stack direction="vertical">
                    <div style={{ lineHeight: '0.8em' }}>{cell.value}</div>
                    <div className="text-muted" style={{ lineHeight: '1em' }}>{cell.row.original.role_name}</div>
                </Stack>
            )
        },
        {
            Header: "Create At",
            accessor: "createAt"
        },
        {
            Header: "Update At",
            accessor: "updateAt"
        },
        {
            Header: "Action",
            accessor: null,
            Cell: ({ cell }) => (
                <Button.Group basic size="mini">
                    <Popup size="tiny" content="Edit" trigger={
                        <Button icon={{ name: 'edit', color: 'blue' }} onClick={() => handleClickEdit(cell.row.original)} />
                    } />
                </Button.Group>
            )
        },
    ]))

    const handleClickEdit = (data) => {
        setSelCost(data)
        setChildId(data.id)
        setCostParent(data.id_parent)
        setChildCode(data.child_code)
        setChildDesc(data.description)
        setActive(data.is_active)
        setModalEdit(true)
    }

    const handleClickAdd = () => {
        setModalAdd(true)
    }

    const getCostParent = async () => {
        await axios({
            method: "GET",
            url: `${host}/ledger/index.php`,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            params: {
                action: 'getCostParent'
            }
        }).then(res => {
            if (res.data.status === 200) {
                const data = res.data.data
                const opt = data.map(i => (
                    {
                        key: i.id,
                        value: i.id,
                        text: `${i.parent_code} - ${i.name}`
                    }
                ))
                setParentOpt(opt)
            }
        })
    }

    const clearForm = () => {
        setCostParent('')
        setChildCode('')
        setChildDesc('')
        setActive('Y')
    }

    const getCostChild = async () => {
        setLoadData(true)
        await axios({
            method: "GET",
            url: `${host}/ledger/index.php`,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            params: {
                action: 'getCostChild'
            }
        }).then(res => {
            if (res.data.status === 200) {
                setCostData(res.data.data)
            }
        }).finally(setLoadData(false))
    }

    const handleSubmit = () => {
        const existCode = costData.some(i => i.child_code.toLowerCase() === childCode.toLowerCase())
        const existDesc = costData.some(i => i.description.toLowerCase() === childDesc.toLowerCase())

        if (existCode) {
            Toaster.fire({
                icon: 'warning',
                text: 'Child code exist',
                position: 'top-right',
            })
            setLoadProc(false)
        } else if (existDesc) {
            Toaster.fire({
                icon: 'warning',
                text: 'Child Description exist',
                position: 'top-right',
            })
            setLoadProc(false)
        } else {
            setLoadProc(true)
            const param = {
                id_parent: costParent,
                code: childCode,
                desc: childDesc,
                id_user: userProfile.profile.id_user,
                active: active,
            }

            axios({
                method: "POST",
                url: `${host}/ledger/index.php?action=addCostChild`,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
                },
                data: param
            }).then(res => {
                if (res.data.status === 200) {
                    Toaster.fire({
                        icon: 'success',
                        text: res.data.msg,
                        position: 'top-right'
                    })
                    clearForm()
                    setModalAdd(false)
                    getCostChild()
                } else {
                    Toaster.fire({
                        icon: 'error',
                        text: res.data.msg,
                        position: 'top-right'
                    })
                }
            }).finally(() => setLoadProc(false))
        }
    }

    const handleSubmitEdit = () => {
        const existCode = costData.some(i => i.child_code.toLowerCase() === childCode.toLowerCase())
        const existDesc = costData.some(i => i.description.toLowerCase() === childDesc.toLowerCase())

        if (existCode && selCost.child_code.toLowerCase() !== childCode.toLowerCase()) {
            Toaster.fire({
                icon: 'warning',
                text: 'Child code exist',
                position: 'top-right',
            })
            setLoadProc(false)
        } else if (existDesc && selCost.description.toLowerCase() !== childDesc.toLowerCase()) {
            Toaster.fire({
                icon: 'warning',
                text: 'Child Description exist',
                position: 'top-right',
            })
            setLoadProc(false)
        } else {
            setLoadProc(true)
            const param = {
                id:childId,
                id_parent: costParent,
                code: childCode,
                desc: childDesc,
                id_user: userProfile.profile.id_user,
                active: active,
            }
            axios({
                method: "PUT",
                url: `${host}/ledger/index.php?action=editCostChild`,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
                },
                data: param
            }).then (res => {
                if (res.data.status === 200){
                    Toaster.fire({
                        icon:'success',
                        text:res.data.msg,
                        position:'top-right'
                    })
                    setModalEdit(false)
                    getCostChild()
                } else {
                    Toaster.fire({
                        icon:'error',
                        text:res.data.msg,
                        position:'top-right'
                    })
                }
            }).finally(() => setLoadProc(false))
        }

    }

    useEffect(() => {
        getCostParent()
        getCostChild()
    }, [])
   
    return (
        <>
            <Container>
                <div className="mb-3 mt-2">
                    <div className="h4">Cost Center</div>
                </div>
                <Row className="d-flex justify-content-center">
                    <Dimmer active={loadData} inverted>
                        <Loader inverted>Loading...</Loader>
                    </Dimmer>
                    <Col xl={8} lg={10} md={12} className="mb-4">
                        <Button content="Add Cost Center" icon="add" onClick={handleClickAdd} color="blue" />
                    </Col>
                    <Col xl={8} lg={10} md={12} className="mb-3">
                        <Reacttable
                            data={costData}
                            columns={columns}
                            pageRow={10}
                            noData={costData.length === 0 ? true : false}
                        />
                    </Col>
                </Row>
            </Container>

            <Modal open={modalAdd} onClose={() => setModalAdd(false)} overflow={false} backdrop="static" size="xs">
                <Modal.Header>
                    <Modal.Title>Add Cost Center</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="frmAdd" onSubmit={handleSubmit}>
                        <Form.Group grouped>
                            <FormDropdown
                                selection
                                options={parentOpt}
                                value={costParent}
                                label="Cost Parent"
                                required
                                onChange={(e, { value }) => setCostParent(value)}
                            />
                            <FormInput
                                label="Child Code"
                                maxLength="10"
                                value={childCode}
                                required
                                onChange={(e) => setChildCode(e.target.value)}
                            />
                            <FormInput
                                label="Description"
                                value={childDesc}
                                maxLength="30"
                                required
                                onChange={(e) => setChildDesc(e.target.value)}
                            />
                            <FormField>
                                <FormCheckbox
                                    label="Active"
                                    onChange={(e, data) => setActive(data.checked ? 'Y' : 'N')}
                                    checked={active === 'Y' ? true : false}
                                />
                            </FormField>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Submit" icon="save" color="blue" form="frmAdd" type="submit" loading={loadProc} disabled={loadProc} />
                </Modal.Footer>
            </Modal>

            <Modal open={modalEdit} onClose={() => setModalEdit(false)} overflow={false} backdrop="static" size="xs">
                <Modal.Header>
                    <Modal.Title>Edit Cost Center</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="frmEdit" onSubmit={handleSubmitEdit}>
                        <Form.Group grouped>
                            <FormDropdown
                                selection
                                options={parentOpt}
                                value={costParent}
                                label="Cost Parent"
                                required
                                onChange={(e, { value }) => setCostParent(value)}
                            />
                            <FormInput
                                label="Child Code"
                                maxLength="10"
                                value={childCode}
                                required
                                onChange={(e) => setChildCode(e.target.value)}
                            />
                            <FormInput
                                label="Description"
                                value={childDesc}
                                maxLength="30"
                                required
                                onChange={(e) => setChildDesc(e.target.value)}
                            />
                            <FormField>
                                <FormCheckbox
                                    label="Active"
                                    onChange={(e, data) => setActive(data.checked ? 'Y' : 'N')}
                                    checked={active === 'Y' ? true : false}
                                />
                            </FormField>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Submit" icon="save" color="blue" form="frmEdit" type="submit" loading={loadProc} disabled={loadProc} />
                </Modal.Footer>
            </Modal>

        </>
    )
}