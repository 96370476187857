import React, { useEffect, useMemo, useState } from "react";
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import axios from "axios";
import { useMyGlobal } from "../component/Global";
import { Button, Form, FormField, FormGroup, FormInput, FormRadio, FormSelect, Popup, Radio } from "semantic-ui-react";
import Modal from "rsuite/modal"
import { applyDefaultFilter, Reacttable } from "../component/Reacttable";
import { Toaster } from "../component/Toaster";
import moment from "moment";
import { curencyNoSym } from "../component/utils";
import { DateRangePicker } from "rsuite";

export const ReportSalesBrand = () => {
    const { userProfile, host, warehouseOpt, brandOpt, showroomOpt } = useMyGlobal()
    const [dataSales, setDataSales] = useState([])
    const [showroom, setShowroom] = useState([])
    const [selectedBrand, setSelectedBrand] = useState('')
    const [loadGenerate, setLoadGenerate] = useState(false)

    const [dateRange, setDateRange] = useState([
        new Date(moment().startOf('month')),
        new Date(moment()),
    ])

    const columns = useMemo(() => applyDefaultFilter([
        {
            Header: "Group",
            accessor: "group_name"
        },
        {
            Header: "Showroom",
            accessor: "showroom_name"
        },
        {
            Header: "Brand Code",
            accessor: "brand_code"
        },
        {
            Header: "Brand",
            accessor: "description"
        },
        {
            Header: "Qty",
            accessor: "total_qty"
        },
        {
            Header: "Sales",
            accessor: "total_sales",
            Cell: ({ cell }) => curencyNoSym.format(cell.value)
        },
    ]))

    const handleGenerateSale = () => {
        if (showroom.length > 0) {
            getSalesPerBrand()
        } else {
            Toaster.fire({
                icon: 'warning',
                text: 'Showroom cannot be empty',
                position: 'top-right'
            })
        }
    }

    const handleChangeDate = (e) => {
        setDateRange(e)
    }

    const getSalesPerBrand = async () => {
        const formatDate = (date) => moment(date).format('YYYY-MM-DD')
        const [startDate, endDate] = dateRange.map(date => formatDate(date))
        const date_range = [startDate, endDate]
        setLoadGenerate(true)

        await axios({
            method: "GET",
            url: `${host}/sales/index.php`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            params: {
                action: 'salesByBrand',
                showroom: showroom,
                brand: selectedBrand,
                date_range: date_range,
            }
        }).then(res => {
            if (res.data.status === 200) {
                setDataSales(res.data.data)
                Toaster.fire({
                    icon: 'success',
                    text: res.data.msg,
                    position: 'top-right'
                })
            } else {
                Toaster.fire({
                    icon: 'error',
                    text: res.data.msg,
                    position: 'top-right'
                })
            }
        }).finally(() => setLoadGenerate(false))
    }

    return (
        <>
            <Container>
                <div className="mb-3 mt-2">
                    <div className="h4">Sales By Brand</div>
                </div>
                <Row className="d-flex justify-content-center">
                    <Col xl={10} lg={12} md={12} className="mb-3">
                        <DateRangePicker
                            className="mb-3"
                            label="Date Range : "
                            format="yyyy-MM-dd"
                            placement="bottomStart"
                            cleanable={true}
                            defaultValue={dateRange}
                            value={dateRange}
                            onChange={handleChangeDate}
                            onClean={() => setDateRange([
                                new Date(moment().startOf('month')),
                                new Date(moment()),
                            ])}
                        />
                        <Form id="frmGenerate">
                            <Form.Group>
                                <FormSelect
                                    required
                                    label="Showroom"
                                    search
                                    options={showroomOpt}
                                    value={showroom}
                                    multiple
                                    onChange={(e, { value }) => setShowroom(value)}
                                />
                                <FormSelect
                                    required
                                    label="Brand"
                                    search
                                    options={brandOpt}
                                    value={selectedBrand}
                                    onChange={(e, { value }) => setSelectedBrand(value)}
                                />
                                <Form.Field>
                                    <label></label>
                                    <Button className="mt-4" loading={loadGenerate} disabled={loadGenerate} content="Generate Data" form="frmGenerate" type="submit" icon="magic" onClick={handleGenerateSale} color="blue" />
                                </Form.Field>
                            </Form.Group>
                        </Form>
                    </Col>
                    {/* <Col xl={4} lg={3} md={4} className="text-end">
                        <Button content="Export to Excel" icon="file excel" color="orange" style={{marginTop:'1.8em'}} onClick={() => exportExcel()}/>
                    </Col> */}
                    <Col xl={10} lg={12} md={12}>
                        <Reacttable
                            columns={columns}
                            data={dataSales}
                            pageRow={10}
                            noData={dataSales.length === 0 ? true : false}
                            filterColumn
                            totals={['total_qty', 'total_sales']}
                        />
                    </Col>
                </Row>
            </Container>
        </>
    )
}