import React, { useEffect, useMemo, useState } from "react";
import ReactApexChart from "react-apexcharts";
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import DateRangePicker from "rsuite/DateRangePicker";
import moment from "moment";
import { Button, Dimmer, Dropdown, Form, Icon, Loader, Popup } from "semantic-ui-react";
import { useMyGlobal } from "../component/Global";
import Stack from "react-bootstrap/Stack";
import { Toaster } from "../component/Toaster";
import axios from "axios";
import { curency, curencyNoSym, currentDate } from "../component/utils";
import Card from "react-bootstrap/Card"
import Modal from "rsuite/Modal"
import { Reacttable } from "../component/Reacttable";


export const Dashboard = () => {
    const [dateRange, setDateRange] = useState([
        new Date(moment().startOf('month')),
        new Date(moment()),
    ])
    const [selectedDate, setSelectedDate] = useState('')
    const { host, showroomOpt, playSound, userProfile } = useMyGlobal()
    const [salesData, setSalesData] = useState([])
    const [salesSeriesData, setSalesSeriesData] = useState([])
    const [showroom, setShowroom] = useState([])
    const [chartSalesOpt, setChartSalesOpt] = useState({})
    const [bestSeler, setBestSeler] = useState([])
    const [salesPerMonth, setSalesPerMonth] = useState([])
    const [salesPerBrand, setSalesPerBrand] = useState([])
    const [trxByDate, setTrxByDate] = useState([])
    const [modalTrx, setModalTrx] = useState(false)
    const [modalTrxDetail, setModalTrxDetail] = useState(false)
    const [selectedTrxDetail, setSelectedTrxDetail] = useState(null)
    const [salesDatePercentage, setSalesDatePercentage] = useState([])
    const [salesPerBrandGroup, setSalesPerBrandGroup] = useState([])
    const [salesPerTarget, setSalesPerTarget] = useState([])
    const [salesPerAgent, setSalesPerAgent] = useState([])
    const [loadSalesDate, setLoadSalesDate] = useState(false)
    const [visitor, setVisitor] = useState([])
    const [globalData, setGlobalData] = useState(null)
    const [nettBrutoData, setNettBrutoData] = useState(null)
    const [percentTrx, setPercentTrx] = useState(null)
    const [salesAllSr, setSalesAllSr] = useState([])
    const [salesRr, setSalesRr] = useState([])
    const [salesHps, setSalesHps] = useState([])

    const monthNames = [
        'Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni',
        'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'
    ];

    const columnsTrxData = useMemo(() => [
        {
            Header: "Trx Number",
            accessor: "header.trx_code"
        },
        {
            Header: "Date",
            accessor: "header.trx_date"
        },
        {
            Header: "Time",
            accessor: "header.trx_time"
        },
        {
            Header: "Cashier",
            accessor: "header.user_name"
        },
        {
            Header: "Sales",
            accessor: "header.sales_name"
        },
        {
            Header: "Qty",
            accessor: "header.total_qty"
        },
        {
            Header: "Total",
            accessor: "header.total_payment",
            Cell: ({ cell }) => curencyNoSym.format(cell.value)
        },
        {
            Header: "Action",
            accessor: "",
            Cell: ({ cell }) => (
                <Button.Group size="mini" basic>
                    <Popup content="Transaction Detail" size="tiny" trigger={
                        <Button icon={{ name: 'list layout', color: 'blue' }} onClick={() => handleClickDetail(cell.row.original)} />
                    } />
                </Button.Group>
            )
        },
    ])

    const columnsTrxDataDetail = useMemo(() => [
        {
            Header: "Article",
            accessor: "article"
        },
        {
            Header: "Description",
            accessor: "description",
            Cell: ({ cell }) => (
                <div style={{ width: '120px' }}>
                    <Popup content={cell.value} size="tiny" trigger={
                        <div className="text-truncate">{cell.value}</div>
                    } />
                </div>
            )
        },
        {
            Header: "Size",
            accessor: "size"
        },
        {
            Header: "Qty",
            accessor: "qty_order"
        },
        {
            Header: "Price",
            accessor: "price",
            Cell: ({ cell }) => (curencyNoSym.format(cell.value))
        },
        {
            Header: "Add Discount",
            accessor: "additional_discount_value",
            Cell: ({ cell }) => (curencyNoSym.format(cell.value))
        },
        {
            Header: "Final Price",
            accessor: "final_price",
            Cell: ({ cell }) => (curencyNoSym.format(cell.value))
        },
        {
            Header: "Total",
            accessor: "",
            Cell: ({ cell }) => (curencyNoSym.format(cell.row.original.final_price * cell.row.original.qty_order))
        },
    ])

    const handleClickDetail = (data) => {
        setSelectedTrxDetail(data)
        setModalTrxDetail(true)
    }

    const handleChangeDate = (e) => {
        setDateRange(e)
    }

    const getSalesData = async () => {
        setLoadSalesDate(true)
        const formatDate = (date) => moment(date).format('YYYY-MM-DD')
        const [startDate, endDate] = dateRange.map(date => formatDate(date))
        const date_range = [startDate, endDate]

        await axios({
            method: "GET",
            url: `${host}/sales/index.php`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            params: {
                action: 'posTransactionByShowroom',
                date_range: date_range,
                id_showroom: showroom,
            }
        }).then(res => {
            if (res.data.status === 200) {
                setSalesData(res.data.data)
                // setSalesSeriesData(
                //     Object.entries(res.data.data.reduce((acc, sale) => {
                //         const { trx_date, total_trx } = sale.header;
                //         if (!acc[trx_date]) {
                //             acc[trx_date] = 0;
                //         }
                //         acc[trx_date] += parseFloat(total_trx);
                //         return acc;
                //     }, {}))
                //         .sort(([dateA], [dateB]) => new Date(dateA) - new Date(dateB))
                //         .map(([date, totalTrx]) => ({ date, totalTrx }))
                // )

                const groupedData = res.data.data.reduce((acc, sale) => {
                    const { trx_date, total_payment, sales_type } = sale.header;

                    if (!acc[trx_date]) {
                        acc[trx_date] = {};
                    }

                    if (!acc[trx_date][sales_type]) {
                        acc[trx_date][sales_type] = 0;
                    }

                    acc[trx_date][sales_type] += parseFloat(total_payment);
                    return acc;
                }, {});

                // Format data untuk ApexCharts
                const dates = Object.keys(groupedData).sort((a, b) => new Date(a) - new Date(b));
                const salesTypes = Array.from(new Set(res.data.data.map(sale => sale.header.sales_type)));

                const series = salesTypes
                    .sort((a, b) => a === 'offstore' ? -1 : b === 'offstore' ? 1 : 0) // Menempatkan 'offstore' di posisi pertama
                    .map(type => ({
                        name: type,
                        data: dates.map(date => groupedData[date][type] || 0),
                    }));

                // Hitung total untuk setiap tanggal, untuk persentase.
                setSalesDatePercentage(dates.map((date, index) =>
                    series.reduce((acc, series) => acc + series.data[index], 0)
                ))

                setSalesSeriesData(series)
            } else {
                Toaster.fire({
                    icon: 'error',
                    text: res.data.msg,
                    position: 'top-right'
                })
            }
        }).finally(() => setLoadSalesDate(false))
    }

    const bestSelerItem = async () => {
        const formatDate = (date) => moment(date).format('YYYY-MM-DD')
        const [startDate, endDate] = dateRange.map(date => formatDate(date))
        const date_range = [startDate, endDate]

        await axios({
            method: "GET",
            url: `${host}/dashboard/index.php`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            params: {
                action: 'bestSellerItem',
                date_range: date_range,
                id_showroom: showroom,
            }
        }).then(res => {
            if (res.data.status === 200) {
                setBestSeler(res.data.data)
            }
        })
    }

    const salesPerMonthData = async () => {

        await axios({
            method: "GET",
            url: `${host}/dashboard/index.php`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            params: {
                action: 'salesPerMonth',
                id_showroom: showroom,
            }
        }).then(res => {
            if (res.data.status === 200) {
                setSalesPerMonth(res.data.data)
            }
        })
    }

    const getSalesPerBrand = async () => {
        const formatDate = (date) => moment(date).format('YYYY-MM-DD')
        const [startDate, endDate] = dateRange.map(date => formatDate(date))
        const date_range = [startDate, endDate]

        await axios({
            method: "GET",
            url: `${host}/dashboard/index.php`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            params: {
                action: 'salesPerBrand',
                id_showroom: showroom,
                date_range: date_range,
            }
        }).then(res => {
            if (res.data.status === 200) {
                setSalesPerBrand(res.data.data)
            }
        })
    }

    const getSalesPerBrandGroup = async () => {
        const formatDate = (date) => moment(date).format('YYYY-MM-DD')
        const [startDate, endDate] = dateRange.map(date => formatDate(date))
        const date_range = [startDate, endDate]

        await axios({
            method: "GET",
            url: `${host}/dashboard/index.php`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            params: {
                action: 'salesPerBrandGroup',
                id_showroom: showroom,
                date_range: date_range,
            }
        }).then(res => {
            if (res.data.status === 200) {
                setSalesPerBrandGroup(res.data.data)
            }
        })
    }

    const getSalesPerTarget = async () => {
        const formatDate = (date) => moment(date).format('YYYY-MM-DD')
        const [startDate, endDate] = dateRange.map(date => formatDate(date))
        const date_range = [startDate, endDate]

        await axios({
            method: "GET",
            url: `${host}/dashboard/index.php`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            params: {
                action: 'salesPerTarget',
                id_showroom: showroom,
                date_range: date_range,
            }
        }).then(res => {
            if (res.data.status === 200) {
                setSalesPerTarget(res.data.data)
            }
        })
    }

    const getVisitorData = async () => {
        const formatDate = (date) => moment(date).format('YYYY-MM-DD')
        const [startDate, endDate] = dateRange.map(date => formatDate(date))
        const date_range = [startDate, endDate]

        await axios({
            method: "GET",
            url: `${host}/dashboard/index.php`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            params: {
                action: 'visitor',
                id_showroom: showroom,
                date_range: date_range,
            }
        }).then(res => {
            if (res.data.status === 200) {
                setVisitor(res.data.data)
            }
        })
    }

    const handleSelectShowroom = (value) => {
        setShowroom(value)
    }

    const handleGenerate = () => {
        // playSound('show')
        if (showroom.length > 0) {
            getSalesData()
            bestSelerItem()
            salesPerMonthData()
            getSalesPerBrand()
            getSalesPerBrandGroup()
            getSalesPerTarget()
            salesByAgent()
            getVisitorData()
            getNettBruto()
            getPercentageTrxQty()
        }
    }


    // const getTransactionByDate = async (date) => { 
    //     const date_range = [date, date]

    //     await axios({
    //         method: "GET",
    //         url: `${host}/sales/index.php`,
    //         headers: {
    //             "Content-Type": "application/json",
    //             "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
    //         },
    //         params: {
    //             action: 'posTransactionByShowroom',
    //             date_range: date_range,
    //             id_showroom: showroom,
    //         }
    //     }).then(res => {
    //         if (res.data.status === 200) {
    //             setTrxByDate(res.data.data)
    //         } else {
    //             Toaster.fire({
    //                 icon: 'error',
    //                 text: res.data.msg,
    //                 position: 'top-right'
    //             })
    //         }
    //     }).finally(() => {
    //         setModalTrx(true)
    //     })

    // }

    const salesByAgent = async () => {
        const formatDate = (date) => moment(date).format('YYYY-MM-DD')
        const [startDate, endDate] = dateRange.map(date => formatDate(date))
        const date_range = [startDate, endDate]

        await axios({
            method: "GET",
            url: `${host}/dashboard/index.php`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            params: {
                action: 'salesByAgent',
                date_range: date_range,
                id_showroom: showroom,
            }
        }).then(res => {
            if (res.data.status === 200) {
                setSalesPerAgent(res.data.data)
            } else {
                Toaster.fire({
                    icon: 'error',
                    text: res.data.msg,
                    position: 'top-right'
                })
            }
        })
    }

    const getPercentageTrxQty = async () => {
        const formatDate = (date) => moment(date).format('YYYY-MM-DD')
        const [startDate, endDate] = dateRange.map(date => formatDate(date))
        const date_range = [startDate, endDate]

        await axios({
            method: "GET",
            url: `${host}/dashboard/index.php`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            params: {
                action: 'getPercentageTrxQty',
                date_range: date_range,
                id_showroom: showroom,
            }
        }).then((res) => {
            if (res.data.status === 200) {
                setPercentTrx(res.data.data)
            }
        })
    }

    const getGlobalData = async (dates) => {
        const formatDate = (date) => moment(date).format('YYYY-MM-DD')
        const [startDate, endDate] = dates.map(date => formatDate(date))
        const date_range = [startDate, endDate]

        await axios({
            method: "GET",
            url: `${host}/sales/index.php`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            params: {
                action: 'salesByDscAndWithoutDsc',
                date_range: date_range,
            }
        }).then(res => {
            if (res.data.status === 200) {
                setGlobalData(res.data.data)
            }
        })
    }

    const getNettBruto = async () => {
        const formatDate = (date) => moment(date).format('YYYY-MM-DD')
        const [startDate, endDate] = dateRange.map(date => formatDate(date))
        const date_range = [startDate, endDate]

        await axios({
            method: "GET",
            url: `${host}/dashboard/index.php`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            params: {
                action: 'getNettBruto',
                date_range: date_range,
                id_showroom: showroom
            }
        }).then(res => {
            if (res.data.status === 200) {
                setNettBrutoData(res.data.data)
            }
        })
    }

    const globalSeries = (group) => {
        if (globalData) {
            const filteredData = globalData?.dsc_and_no.filter(item => item.group_name === group);
            const withDiscountSeries = filteredData?.map(item => parseInt(item.with_discount_qty));
            const withoutDiscountSeries = filteredData?.map(item => parseInt(item.without_discount_qty));
            const series = withDiscountSeries?.concat(withoutDiscountSeries)
            return series
        } else {
            return []
        }
    }

    const salesAllShowroom = async () => {
        const formatDate = (date) => moment(date).format('YYYY-MM-DD')
        const [startDate, endDate] = dateRange.map(date => formatDate(date))
        const date_range = [startDate, endDate]

        await axios({
            method: "GET",
            url: `${host}/dashboard/index.php`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            params: {
                action: 'salesAllShowroom',
                date_range: date_range,
            }
        }).then(res => {
            if (res.data.status === 200) {
                const data = res.data.data
                setSalesAllSr(data)
                const rr = data.filter(g => g.group_name == 'Rudrunner')
                const hps = data.filter(g => g.group_name == 'Hoops Indonesia')
                setSalesRr(rr)
                setSalesHps(hps)
            }
        })
    }

    useEffect(() => {
        const test = (sales, date) => {
            const getTransactionsByDate = (sales, targetDate) => {
                return sales.filter(sale => sale.header.trx_date === targetDate);
            }

            const targetDate = date;
            const transactionsOnTargetDate = getTransactionsByDate(sales, targetDate);
            setTrxByDate(transactionsOnTargetDate)
            setModalTrx(true)
        }
        if (selectedDate !== '') {
            test(salesData, selectedDate)
        }
    }, [selectedDate])

    useEffect(() => {
        getGlobalData(dateRange)
        salesAllShowroom(dateRange)
    }, [dateRange])

    return (
        <>
            <Container fluid>
                <div className="mb-3 mt-2">
                    <div className="h4">Dashboard</div>
                </div>
                <Row className="d-flex justify-content-left mb-4">
                    <Col xl={12} lg={12} md={12} sm={12} xs={12} className="mb-4">
                        <Row>
                            <Col xl={12} lg={12} md={12} className="d-flex justify-content-end">
                                <DateRangePicker
                                    appearance="default"
                                    disabled={showroom !== '' ? false : true}
                                    label="Date Range : "
                                    format="yyyy-MM-dd"
                                    placement="auto"
                                    cleanable={true}
                                    defaultValue={dateRange}
                                    value={dateRange}
                                    onChange={handleChangeDate}
                                    onClean={() => setDateRange([
                                        new Date(moment().startOf('month')),
                                        new Date(moment()),
                                    ])}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={12} xs={12} className="mb-4">
                        <Row>
                            <Col xl={4} lg={6} md={12} sm={12} xs={12} className="mb-3">
                                <Card>
                                    <Card.Body>
                                        <Row>
                                            <Col xl={8} lg={8}>
                                                <Stack direction="vertical" gap={2}>
                                                    <div className="mb-2"><Icon name="shopping bag" color="orange" />Hoops Indonesia</div>
                                                    <div className="fs-3">{globalData ? curencyNoSym.format(globalData?.total_trx?.find(t => t.group_name === 'Hoops Indonesia')?.total_trx) : '-'}</div>
                                                    <Stack direction="horizontal" gap={3}>
                                                        <div style={{ color: 'burlywood' }}>From {globalData?.showroom_by_group?.find(s => s.group_name === 'Hoops Indonesia')?.count} Stores</div>
                                                        <div style={{ color: 'burlywood' }}>{curencyNoSym.format(globalData?.visitor_and_expense?.find(s => s.group_name === 'Hoops Indonesia')?.total_visitor)} Visitor</div>
                                                    </Stack>
                                                    <div className="text-muted">Store Expense : <span style={{ color: 'lightcoral' }}>{curencyNoSym.format(globalData?.visitor_and_expense?.find(s => s.group_name === 'Hoops Indonesia')?.total_expense)}</span></div>
                                                    {/* <p className="small text-muted">This is a summary of data for the current month per store group.</p> */}
                                                </Stack>
                                            </Col>
                                            <Col xl={4} lg={4} className="d-flex justify-content-center align-items-center h-100">
                                                <ReactApexChart
                                                    type="donut"
                                                    options={
                                                        {
                                                            chart: {
                                                                redrawOnParentResize: true,
                                                                redrawOnWindowResize: true,
                                                            },
                                                            dataLabels: {
                                                                enabled: true,
                                                            },
                                                            labels: ['Discounted', 'Normal Price'],
                                                            legend: {
                                                                show: false,
                                                            },
                                                            grid: {
                                                                padding: {
                                                                    bottom: 0,
                                                                    top: 0,
                                                                    left: 0,
                                                                    right: 0
                                                                },
                                                            },
                                                            plotOptions: {
                                                                pie: {
                                                                    donut: {
                                                                        size: '50%' // Menentukan ketebalan donut
                                                                    }
                                                                }
                                                            },
                                                            tooltip: {
                                                                custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                                                                    const label = w.globals.labels[seriesIndex];
                                                                    const value = curencyNoSym.format(series[seriesIndex]);
                                                                    return `<div style="padding: 10px; font-size:0.9em">
                                                                                <span>${label}: ${value} Item's</span><br/>
                                                                            </div>`;
                                                                }
                                                            },
                                                        }
                                                    }
                                                    series={globalSeries('Hoops Indonesia')}
                                                    height={120}
                                                    width={140}
                                                />
                                            </Col>
                                            <Col xl={12} lg={12}>
                                                {salesHps.length > 0 ?
                                                    <ReactApexChart
                                                        type="bar"
                                                        options={
                                                            {
                                                                chart: {
                                                                    type: "bar",
                                                                    toolbar: {
                                                                        show: false
                                                                    },
                                                                    stacked: true,
                                                                },
                                                                tooltip: {
                                                                    enabled: true,
                                                                    custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                                                                        const showroom = salesHps[dataPointIndex].showroom_name; // Ambil nama showroom
                                                                        const salesType = w.globals.seriesNames[seriesIndex]; // Nama seri (offstore/marketplace)
                                                                        const value = series[seriesIndex][dataPointIndex]; // Nilai saat ini

                                                                        return `
                                                                    <div style="padding: 10px; background: #fff; border: 1px solid #ccc; border-radius: 5px;">
                                                                        <strong>Showroom:</strong> ${showroom}<br>
                                                                        <strong>Sales Type:</strong> ${salesType}<br>
                                                                        <strong>Amount:</strong> <span style="color:#008ffb">${value.toLocaleString()}</span> <!-- Format angka -->
                                                                    </div>
                                                                `;
                                                                    }
                                                                },
                                                                plotOptions: {
                                                                    bar: {
                                                                        borderRadius: 0,
                                                                        horizontal: false,
                                                                    },
                                                                },
                                                                xaxis: {
                                                                    labels: {
                                                                        show: false
                                                                    },
                                                                    categories: []
                                                                },
                                                                legend: {
                                                                    show: false
                                                                },
                                                                yaxis: {
                                                                    show: false,
                                                                    labels: {
                                                                        formatter: function (val) {
                                                                            return curency.format(val);
                                                                        },
                                                                    },
                                                                },
                                                                dataLabels: {
                                                                    enabled: false,
                                                                    formatter: function (val, { dataPointIndex, seriesIndex }) {
                                                                        const totalSales = parseFloat(salesAllSr[dataPointIndex].offstore_sales) + parseFloat(salesAllSr[dataPointIndex].marketplace_sales)
                                                                        const percentage = (val / totalSales) * 100;
                                                                        return `${percentage.toFixed(1)}%`; // Format ke persentase 1 desimal
                                                                    },
                                                                    style: {
                                                                        fontSize: "8px",
                                                                        fontFamily: "inherit",
                                                                    },
                                                                },

                                                            }
                                                        }
                                                        series={[
                                                            {
                                                                name: "All", // Gabungkan OS dan MP menjadi satu
                                                                data: salesHps?.map(item =>
                                                                    parseInt(item.offstore_sales || 0) + parseInt(item.marketplace_sales || 0) // Jumlahkan OS dan MP
                                                                ),
                                                            },
                                                        ]}
                                                        
                                                        height={100}
                                                    />
                                                    : <>Loading</>
                                                }
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xl={4} lg={6} md={12} sm={12} xs={12} className="mb-3">
                                <Card>
                                    <Card.Body>
                                        <Row>
                                            <Col xl={8} lg={8}>
                                                <Stack direction="vertical" gap={2}>
                                                    <div className="mb-2"><Icon name="shopping bag" color="orange" />Rudrunner</div>
                                                    <div className="fs-3">{globalData ? curencyNoSym.format(globalData?.total_trx?.find(t => t.group_name === 'Rudrunner')?.total_trx) : '-'}</div>
                                                    <Stack direction="horizontal" gap={3}>
                                                        <div style={{ color: 'burlywood' }}>From {globalData?.showroom_by_group?.find(s => s.group_name === 'Rudrunner')?.count} Stores</div>
                                                        <div style={{ color: 'burlywood' }}>{curencyNoSym.format(globalData?.visitor_and_expense?.find(s => s.group_name === 'Rudrunner')?.total_visitor)} Visitor</div>
                                                    </Stack>
                                                    <div className="text-muted">Store Expense : <span style={{ color: 'lightcoral' }}>{curencyNoSym.format(globalData?.visitor_and_expense?.find(s => s.group_name === 'Rudrunner')?.total_expense)}</span></div>
                                                    {/* <p className="small text-muted">This is a summary of data for the current month per store group.</p> */}
                                                </Stack>
                                            </Col>
                                            <Col xl={4} lg={4} className="d-flex justify-content-center align-items-center h-100">
                                                <ReactApexChart
                                                    type="donut"
                                                    options={
                                                        {
                                                            chart: {
                                                                redrawOnParentResize: true,
                                                                redrawOnWindowResize: true,
                                                            },
                                                            dataLabels: {
                                                                enabled: true,
                                                            },
                                                            labels: ['Discounted', 'Normal Price'],
                                                            legend: {
                                                                show: false,
                                                            },
                                                            grid: {
                                                                padding: {
                                                                    bottom: 0,
                                                                    top: 0,
                                                                    left: 0,
                                                                    right: 0
                                                                },
                                                            },
                                                            plotOptions: {
                                                                pie: {
                                                                    donut: {
                                                                        size: '50%' // Menentukan ketebalan donut
                                                                    }
                                                                }
                                                            },
                                                            tooltip: {
                                                                custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                                                                    const label = w.globals.labels[seriesIndex];
                                                                    const value = curencyNoSym.format(series[seriesIndex]);
                                                                    return `<div style="padding: 10px; font-size:0.9em">
                                                                                <span>${label}: ${value} Item's</span><br/>
                                                                            </div>`;
                                                                }
                                                            },
                                                        }
                                                    }
                                                    series={globalSeries('Rudrunner')}
                                                    height={120}
                                                    width={140}
                                                />
                                            </Col>
                                            <Col xl={12} lg={12}>
                                                {salesRr.length > 0 ?
                                                    <ReactApexChart
                                                        type="bar"
                                                        options={
                                                            {
                                                                chart: {
                                                                    type: "bar",
                                                                    toolbar: {
                                                                        show: false
                                                                    },
                                                                    stacked: true
                                                                },
                                                                tooltip: {
                                                                    enabled: true,
                                                                    custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                                                                        const showroom = salesRr[dataPointIndex].showroom_name; // Ambil nama showroom
                                                                        const salesType = w.globals.seriesNames[seriesIndex]; // Nama seri (offstore/marketplace)
                                                                        const value = series[seriesIndex][dataPointIndex]; // Nilai saat ini

                                                                        return `
                                                                    <div style="padding: 10px; background: #fff; border: 1px solid #ccc; border-radius: 5px;">
                                                                        <strong>Showroom:</strong> ${showroom}<br>
                                                                        <strong>Sales Type:</strong> ${salesType}<br>
                                                                        <strong>Amount:</strong> <span style="color:#008ffb">${value.toLocaleString()}</span> <!-- Format angka -->
                                                                    </div>
                                                                `;
                                                                    }
                                                                },
                                                                plotOptions: {
                                                                    bar: {
                                                                        borderRadius: 0,
                                                                        horizontal: false,
                                                                    },
                                                                },
                                                                xaxis: {
                                                                    labels: {
                                                                        show: false
                                                                    },
                                                                    categories: []
                                                                },
                                                                yaxis: {
                                                                    labels: {
                                                                        show: false,
                                                                        formatter: function (val) {
                                                                            return curency.format(val);
                                                                        },
                                                                    },
                                                                },
                                                                legend: {
                                                                    show: false
                                                                },
                                                                dataLabels: {
                                                                    enabled: false,
                                                                    formatter: function (val, { dataPointIndex, seriesIndex }) {
                                                                        const totalSales = parseFloat(salesAllSr[dataPointIndex].offstore_sales) + parseFloat(salesAllSr[dataPointIndex].marketplace_sales)
                                                                        const percentage = (val / totalSales) * 100;
                                                                        return `${percentage.toFixed(1)}%`; // Format ke persentase 1 desimal
                                                                    },
                                                                    style: {
                                                                        fontSize: "8px",
                                                                        fontFamily: "inherit",
                                                                    },
                                                                },

                                                            }
                                                        }
                                                        series={[
                                                            {
                                                                name: "All", // Gabungkan OS dan MP menjadi satu
                                                                data: salesRr?.map(item =>
                                                                    parseInt(item.offstore_sales || 0) + parseInt(item.marketplace_sales || 0) // Jumlahkan OS dan MP
                                                                ),
                                                            },
                                                        ]}
                                                        height={100}
                                                    />
                                                    : <>Loading</>
                                                }
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={12} xs={12} className="mb-2 d-flex justify-content-end">
                        <Form >
                            <Form.Group inline>
                                <label>By Showroom</label>
                                <Dropdown
                                    className="me-3"
                                    placeholder="Select Showroom"
                                    selection
                                    options={showroomOpt.filter(s => userProfile.group.some(g => g.id_showroom === s.value))}
                                    clearable
                                    multiple
                                    search={true}
                                    onChange={(e, { value }) => handleSelectShowroom(value)}
                                />
                                <Button content="Generate Data" color="orange" icon="magic" onClick={handleGenerate} disabled={showroom.length > 0 ? false : true}/>
                            </Form.Group>
                        </Form>
                    </Col>
                    {/* <Col xl={12} lg={12} md={12} sm={12} xs={12} className="mb-4">
                        <Card>
                            <Card.Body>
                                <ReactApexChart
                                    type="bar"
                                    options={
                                        {
                                            chart: {
                                                type: "bar",
                                                events: {
                                                    dataPointSelection: (event, chartContext, config) => {
                                                        const { dataPointIndex } = config
                                                        const dates = salesData
                                                            .map(sale => sale.header.trx_date)
                                                            .reduce((acc, date) => {
                                                                if (!acc.includes(date)) acc.push(date);
                                                                return acc;
                                                            }, [])
                                                            .sort((a, b) => new Date(a) - new Date(b));
                                                        // getTransactionByDate(dates[dataPointIndex])
                                                        setSelectedDate(dates[dataPointIndex])
                                                    }
                                                },
                                                stacked: true
                                            },
                                            title: {
                                                text: 'Sales By Showroom',
                                                align: 'left'
                                            },
                                            subtitle: {
                                                text: 'Data shows sales for each showroom.',
                                                align: 'left',
                                                offsetY: 20
                                            },
                                            tooltip: {
                                                enabled: true,
                                                custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                                                    const showroom = salesAllSr[dataPointIndex].showroom_name; // Ambil nama showroom
                                                    const salesType = w.globals.seriesNames[seriesIndex]; // Nama seri (offstore/marketplace)
                                                    const value = series[seriesIndex][dataPointIndex]; // Nilai saat ini

                                                    return `
                                                    <div style="padding: 10px; background: #fff; border: 1px solid #ccc; border-radius: 5px;">
                                                        <strong>Showroom:</strong> ${showroom}<br>
                                                        <strong>Sales Type:</strong> ${salesType}<br>
                                                        <strong>Amount:</strong> ${value.toLocaleString()} <!-- Format angka -->
                                                    </div>
                                                `;
                                                }
                                            },
                                            plotOptions: {
                                                bar: {
                                                    borderRadius: 0,
                                                    horizontal: false,
                                                },
                                            },
                                            xaxis: {
                                                categories: salesAllSr?.map(item => item.showroom_abr)
                                            },
                                            yaxis: {
                                                labels: {
                                                    formatter: function (val) {
                                                        return curency.format(val);
                                                    },
                                                },
                                            },
                                            dataLabels: {
                                                enabled: true,
                                                formatter: function (val, { dataPointIndex, seriesIndex }) {
                                                    const totalSales = parseFloat(salesAllSr[dataPointIndex].offstore_sales) + parseFloat(salesAllSr[dataPointIndex].marketplace_sales)
                                                    const percentage = (val / totalSales) * 100;
                                                    return `${percentage.toFixed(1)}%`; // Format ke persentase 1 desimal
                                                },
                                                style: {
                                                    fontSize: "8px",
                                                    fontFamily: "inherit",
                                                },
                                            },

                                        }
                                    }
                                    series={[
                                        {
                                            name: "offstore",
                                            data: salesAllSr?.map(item => parseInt(item.offstore_sales)),
                                        },
                                        {
                                            name: "marketplace",
                                            data: salesAllSr?.map(item => parseInt(item.marketplace_sales)),
                                        }
                                    ]}
                                    height={350}
                                />
                            </Card.Body>
                        </Card>
                    </Col> */}
                    <Col xl={12} lg={12} md={12} sm={12} xs={12} className="mb-4">
                        <Card>
                            <Card.Body>
                                <Dimmer active={loadSalesDate} inverted>
                                    <Loader inverted>Loading</Loader>
                                </Dimmer>
                                {
                                    salesData ?
                                        <ReactApexChart
                                            type="bar"

                                            options={
                                                {
                                                    chart: {
                                                        type: "bar",
                                                        events: {
                                                            dataPointSelection: (event, chartContext, config) => {
                                                                const { dataPointIndex } = config
                                                                const dates = salesData
                                                                    .map(sale => sale.header.trx_date)
                                                                    .reduce((acc, date) => {
                                                                        if (!acc.includes(date)) acc.push(date);
                                                                        return acc;
                                                                    }, [])
                                                                    .sort((a, b) => new Date(a) - new Date(b));
                                                                // getTransactionByDate(dates[dataPointIndex])
                                                                setSelectedDate(dates[dataPointIndex])
                                                            }
                                                        },
                                                        stacked: true
                                                    },
                                                    title: {
                                                        text: 'Sales Data as of Date',
                                                        align: 'left'
                                                    },
                                                    subtitle: {
                                                        text: 'Sales data per date on selected date range.',
                                                        align: 'left',
                                                        offsetY: 20
                                                    },
                                                    plotOptions: {
                                                        bar: {
                                                            borderRadius: 0,
                                                            horizontal: false,
                                                        },
                                                    },
                                                    xaxis: {
                                                        categories: salesData
                                                            .map(sale => sale.header.trx_date)
                                                            .reduce((acc, date) => {
                                                                if (!acc.includes(date)) {
                                                                    acc.push(date);
                                                                }
                                                                return acc;
                                                            }, [])
                                                            .sort((a, b) => new Date(a) - new Date(b))
                                                            .map(date => new Date(date).toLocaleDateString('in-US', {
                                                                weekday: 'long',
                                                                month: 'short',
                                                                day: 'numeric'
                                                            }))
                                                    },
                                                    yaxis: {
                                                        labels: {
                                                            formatter: function (val) {
                                                                return curency.format(val);
                                                            },
                                                        },
                                                    },
                                                    dataLabels: {
                                                        enabled: true,
                                                        formatter: function (val, { dataPointIndex, seriesIndex }) {
                                                            const total = salesDatePercentage[dataPointIndex];
                                                            const percentage = (val / total) * 100;
                                                            return `${percentage.toFixed(1)}%`; // Format ke persentase 1 desimal
                                                        },
                                                    },
                                                }
                                            }
                                            series={salesSeriesData}
                                            height={350}
                                        />
                                        : <>Loading</>
                                }
                            </Card.Body>
                            <Card.Footer>
                                <div><span className="text-muted">Total Sales</span> : <span style={{ fontWeight: 'bold', color: '#2185d0' }}>{curency.format(salesData.reduce((total, cur) => total + parseFloat(cur.header.total_payment), 0))}</span></div>
                            </Card.Footer>
                        </Card>
                    </Col>
                    <Col xl={6} lg={12} md={12} sm={12} xs={12} className="mb-4">
                        <Card>
                            <Card.Body>
                                {
                                    salesPerMonth ?
                                        <ReactApexChart
                                            type="bar"
                                            options={
                                                {
                                                    plotOptions: {
                                                        bar: {
                                                            borderRadius: 0,
                                                            horizontal: false,
                                                            dataLabels: {
                                                                position: 'top', // top, center, bottom
                                                            },
                                                        },
                                                    },
                                                    xaxis: {
                                                        categories: salesPerMonth.map(item => { return `${monthNames[item.month - 1]} ${item.year}` })
                                                    },
                                                    yaxis: {
                                                        labels: {
                                                            formatter: function (val) {
                                                                return curency.format(val);
                                                            },
                                                        },
                                                    },
                                                    title: {
                                                        text: 'Monthly Sales',
                                                        align: 'left',
                                                    },
                                                    subtitle: {
                                                        text: 'Monthly sales data in the current year.',
                                                        align: 'left',
                                                        offsetY: 20
                                                    },
                                                    dataLabels: {
                                                        enabled: true,
                                                        textAnchor: "middle",
                                                        offsetY: -20,
                                                        style: {
                                                            fontSize: '9px',
                                                            colors: ['#333'],
                                                            fontWeight: 'bold',
                                                        },
                                                        formatter: function (val) {
                                                            return curencyNoSym.format(val);
                                                        },
                                                    },
                                                    tooltip: {
                                                        shared: false,
                                                        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                                                            const amount = curency.format(series[seriesIndex][dataPointIndex]);
                                                            const qty = salesPerMonth[dataPointIndex].qty;  // assuming qty is in second series

                                                            return (
                                                                `<div class="apexcharts-tooltip-title">
                                                                    <span>${w.config.xaxis.categories[dataPointIndex]}</span>
                                                                </div>
                                                                <div class="apexcharts-tooltip-items p-2">
                                                                    <div>
                                                                        <strong>Amount: </strong> ${amount}
                                                                    </div>
                                                                    <div>
                                                                        <strong>Qty: </strong> ${qty} Sold
                                                                    </div>
                                                                </div>`
                                                            );
                                                        },
                                                    },
                                                }
                                            }
                                            series={[
                                                {
                                                    name: 'Amount',
                                                    data: salesPerMonth.map(item => item.amount)
                                                }
                                            ]}
                                            height={350}
                                        />
                                        : <>Loading</>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xl={6} lg={12} md={12} sm={12} xs={12} className="mb-4">
                        <Card>
                            <Card.Body>
                                {
                                    bestSeler ?
                                        <ReactApexChart
                                            type="bar"
                                            options={
                                                {
                                                    plotOptions: {
                                                        bar: {
                                                            borderRadius: 0,
                                                            horizontal: false,
                                                        },
                                                    },
                                                    xaxis: {
                                                        categories: bestSeler.map(item => item.article)
                                                    },
                                                    yaxis: {
                                                        labels: {
                                                            formatter: function (val) {
                                                                return val + ' Items';
                                                            },
                                                        },
                                                    },
                                                    dataLabels: {
                                                        enabled: false,
                                                    },
                                                    title: {
                                                        text: 'The Most Purchased Product',
                                                        align: 'left',
                                                    },
                                                    subtitle: {
                                                        text: 'Most purchased product data, from most to least.',
                                                        align: 'left',
                                                        offsetY: 20
                                                    },
                                                    tooltip: {
                                                        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                                                            const qty = series[seriesIndex][dataPointIndex]
                                                            const desc = bestSeler[dataPointIndex].description
                                                            const price = curency.format(bestSeler[dataPointIndex].price)
                                                            return `<div style="padding: 10px; font-size:0.9em">
                                                                    <span>${desc}</span><br/>
                                                                    <span>Price : ${price}</span><br/>
                                                                    <span style="font-weight:bold">Sold : ${qty} </span><br/>
                                                                   
                                                                </div>`
                                                        }
                                                    }
                                                }
                                            }
                                            series={[{
                                                data: bestSeler.map(item => item.sale)
                                            }]}
                                            height={350}
                                        />
                                        : <>Loading</>
                                }
                            </Card.Body>
                        </Card>

                    </Col>
                    <Col xl={8} lg={12} md={12} sm={12} xs={12} className="mb-4">
                        <Card>
                            <Card.Body>
                                {
                                    visitor ?
                                        <ReactApexChart
                                            type="area"
                                            options={
                                                {
                                                    chart: {
                                                        type: 'area',
                                                        zoom: {
                                                            enabled: false
                                                        }
                                                    },
                                                    dataLabels: {
                                                        enabled: false
                                                    },
                                                    stroke: {
                                                        curve: 'smooth'
                                                    },

                                                    title: {
                                                        text: `Visitor (Total : ${visitor.reduce((acc, item) => {
                                                            const visitorCount = item.visitor ? parseInt(item.visitor) : 0;
                                                            return acc + visitorCount;
                                                        }, 0)})`,
                                                        align: 'left'
                                                    },
                                                    subtitle: {
                                                        text: 'Data is displayed based on the number of visitors from the selected date range.',
                                                        align: 'left'
                                                    },
                                                    labels: visitor.map(item => item.close_date),
                                                    xaxis: {
                                                        type: 'datetime',
                                                    },
                                                    yaxis: {
                                                        opposite: true
                                                    },
                                                    legend: {
                                                        horizontalAlign: 'left'
                                                    }
                                                }
                                            }
                                            series={[
                                                {
                                                    name: 'Amount',
                                                    data: visitor.map(item => item.visitor)
                                                }
                                            ]}
                                            height={202}
                                        />
                                        : <>Loading</>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xl={4} lg={6} md={12} sm={12} xs={12} className="mb-4">
                        <Card>
                            <Card.Body>
                                {
                                    salesPerBrand ?
                                        <ReactApexChart
                                            type="donut"
                                            options={
                                                {
                                                    chart: {
                                                        redrawOnParentResize: true,
                                                        redrawOnWindowResize: true
                                                    },
                                                    labels: salesPerBrand.map(item => item.description),
                                                    dataLabels: {
                                                        enabled: true,
                                                    },
                                                    responsive: [{
                                                        breakpoint: 480,
                                                        options: {
                                                            chart: {
                                                                width: '100%',
                                                            },
                                                            legend: {
                                                                show: true,
                                                            }
                                                        }
                                                    }],
                                                    legend: {
                                                        position: 'right',
                                                        offsetY: 30,
                                                        height: '100%',
                                                        width: 150
                                                    },
                                                    title: {
                                                        text: 'Sales Data per Brand of Goods',
                                                        align: 'left',
                                                    },
                                                    subtitle: {
                                                        text: 'Here it displays the percentage of sales based on the brand of goods.',
                                                        align: 'left',
                                                        offsetY: 20
                                                    },
                                                    plotOptions: {
                                                        pie: {
                                                            startAngle: 0,
                                                            endAngle: 360,
                                                            expandOnClick: true,
                                                            offsetX: 0,
                                                            offsetY: 0,
                                                            customScale: 1,
                                                            dataLabels: {
                                                                offset: 0,
                                                                minAngleToShowLabel: 10
                                                            },
                                                            donut: {
                                                                size: "50%",
                                                            }
                                                        }
                                                    },
                                                    tooltip: {
                                                        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                                                            const amount = curency.format(salesPerBrand[seriesIndex].total_final_price)
                                                            const qty = salesPerBrand[seriesIndex].total_qty
                                                            const brand = salesPerBrand[seriesIndex].description
                                                            return `<div style="padding: 10px; font-size:0.9em">
                                                                                <span>Brand : ${brand} </span><br/>
                                                                                <span>Amount : ${amount}</span><br/>
                                                                                <span>Qty : ${qty} Items</span>
                                                                            </div>`
                                                        }
                                                    },
                                                }
                                            }
                                            series={salesPerBrand.map(item => parseInt(item.total_final_price))}
                                            height={360}
                                        />
                                        : <>Loading</>
                                }
                            </Card.Body>
                        </Card>

                    </Col>
                    <Col xl={4} lg={6} md={12} sm={12} xs={12} className="mb-4">
                        <Card>
                            <Card.Body>
                                {
                                    salesPerBrandGroup ?
                                        <ReactApexChart
                                            type="donut"
                                            options={
                                                {
                                                    chart: {
                                                        redrawOnParentResize: true,
                                                        redrawOnWindowResize: true
                                                    },
                                                    labels: salesPerBrandGroup.map(item => item.brand_sub_group),
                                                    dataLabels: {
                                                        enabled: true,
                                                    },
                                                    title: {
                                                        text: 'Sales by Item Category',
                                                        align: 'left',
                                                    },
                                                    subtitle: {
                                                        text: 'Here displays sales data based on product category by Qty',
                                                        align: 'left',
                                                        offsetY: 20
                                                    },
                                                    responsive: [{
                                                        breakpoint: 480,
                                                        options: {
                                                            chart: {
                                                                width: '100%',
                                                            },
                                                            legend: {
                                                                show: true,
                                                            }
                                                        }
                                                    }],
                                                    legend: {
                                                        position: 'right',
                                                        offsetY: 30,
                                                        height: '100%',
                                                        width: 150
                                                    },
                                                    plotOptions: {
                                                        pie: {
                                                            startAngle: 0,
                                                            endAngle: 360,
                                                            expandOnClick: true,
                                                            offsetX: 0,
                                                            offsetY: 0,
                                                            customScale: 1,
                                                            dataLabels: {
                                                                offset: 0,
                                                                minAngleToShowLabel: 10
                                                            },
                                                            donut: {
                                                                size: "50%",
                                                            }
                                                        }
                                                    },
                                                    tooltip: {
                                                        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                                                            const amount = curency.format(salesPerBrandGroup[seriesIndex].transaction)
                                                            const qty = salesPerBrandGroup[seriesIndex].total_qty
                                                            const cat = salesPerBrandGroup[seriesIndex].brand_sub_group
                                                            return `<div style="padding: 10px; font-size:0.9em">
                                                                                <span>Category : ${cat} </span><br/>
                                                                                <span>Amount : ${amount}</span><br/>
                                                                                <span>Qty : ${qty} Items</span>
                                                                            </div>`
                                                        }
                                                    },
                                                }
                                            }
                                            series={salesPerBrandGroup.map(item => parseInt(item.total_qty))}
                                            height={360}
                                        />
                                        : <>Loading</>
                                }
                            </Card.Body>
                        </Card>

                    </Col>
                    <Col xl={4} lg={6} md={12} sm={12} xs={12} className="mb-4">
                        <Card>
                            <Card.Body>
                                {
                                    salesPerTarget ?
                                        <ReactApexChart
                                            type="donut"
                                            options={
                                                {
                                                    chart: {
                                                        redrawOnParentResize: true,
                                                        redrawOnWindowResize: true
                                                    },
                                                    labels: salesPerTarget.map(item => item.target),
                                                    dataLabels: {
                                                        enabled: true,
                                                    },
                                                    title: {
                                                        text: 'Sales Based on Targets',
                                                        align: 'left',
                                                    },
                                                    subtitle: {
                                                        text: 'Here displays sales data that is grouped based on targets.',
                                                        align: 'left',
                                                        offsetY: 20
                                                    },
                                                    responsive: [{
                                                        breakpoint: 480,
                                                        options: {
                                                            chart: {
                                                                width: '100%',
                                                            },
                                                            legend: {
                                                                show: true,
                                                            }
                                                        }
                                                    }],
                                                    legend: {
                                                        position: 'right',
                                                        offsetY: 30,
                                                        height: '100%',
                                                        width: 150
                                                    },
                                                    plotOptions: {
                                                        pie: {
                                                            startAngle: 0,
                                                            endAngle: 360,
                                                            expandOnClick: true,
                                                            offsetX: 0,
                                                            offsetY: 0,
                                                            customScale: 1,
                                                            dataLabels: {
                                                                offset: 0,
                                                                minAngleToShowLabel: 10
                                                            },
                                                            donut: {
                                                                size: "50%",
                                                            }
                                                        }
                                                    },
                                                    tooltip: {
                                                        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                                                            const amount = curency.format(salesPerTarget[seriesIndex].transaction)
                                                            const qty = salesPerTarget[seriesIndex].total_qty
                                                            const target = salesPerTarget[seriesIndex].target
                                                            return `<div style="padding: 10px; font-size:0.9em">
                                                                                <span>Target : ${target} </span><br/>
                                                                                <span>Amount : ${amount}</span><br/>
                                                                                <span>Qty : ${qty} Items</span>
                                                                            </div>`
                                                        }
                                                    },
                                                }
                                            }
                                            series={salesPerTarget.map(item => parseInt(item.total_qty))}
                                            height={360}
                                        />
                                        : <>Loading</>
                                }
                            </Card.Body>
                        </Card>

                    </Col>
                    <Col xl={4} lg={6} md={12} sm={12} xs={12} className="mb-4">
                        <Card>
                            <Card.Body>
                                {
                                    salesPerAgent ?
                                        <ReactApexChart
                                            type="donut"
                                            options={
                                                {
                                                    chart: {
                                                        redrawOnParentResize: true,
                                                        redrawOnWindowResize: true
                                                    },
                                                    labels: salesPerAgent.map(item => item.sales_name),
                                                    dataLabels: {
                                                        enabled: true,
                                                    },
                                                    title: {
                                                        text: 'Agent Sales Comparison',
                                                        align: 'left',
                                                    },
                                                    subtitle: {
                                                        text: 'The data displays a comparison of the percentage of sales amounts.',
                                                        align: 'left',
                                                        offsetY: 20
                                                    },
                                                    responsive: [{
                                                        breakpoint: 480,
                                                        options: {
                                                            chart: {
                                                                width: '100%',
                                                            },
                                                            legend: {
                                                                show: true,
                                                            }
                                                        }
                                                    }],
                                                    legend: {
                                                        position: 'right',
                                                        offsetY: 30,
                                                        height: '100%',
                                                        width: 150
                                                    },
                                                    plotOptions: {
                                                        pie: {
                                                            startAngle: 0,
                                                            endAngle: 360,
                                                            expandOnClick: true,
                                                            offsetX: 0,
                                                            offsetY: 0,
                                                            customScale: 1,
                                                            dataLabels: {
                                                                offset: 0,
                                                                minAngleToShowLabel: 10
                                                            },
                                                            donut: {
                                                                size: "50%",
                                                            }
                                                        }
                                                    },
                                                    tooltip: {
                                                        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                                                            const transaction = curency.format(salesPerAgent[seriesIndex].transaction)
                                                            const total_qty = salesPerAgent[seriesIndex].total_qty
                                                            const sales_name = salesPerAgent[seriesIndex].sales_name
                                                            return `<div style="padding: 10px; font-size:0.9em">
                                                                                <span>Agent : ${sales_name} </span><br/>
                                                                                <span>Amount : ${transaction}</span><br/>
                                                                                <span>Qty : ${total_qty} Items</span>
                                                                            </div>`
                                                        }
                                                    },
                                                }
                                            }
                                            series={salesPerAgent.map(item => parseInt(item.transaction))}
                                            height={360}
                                        />
                                        : <>Loading</>
                                }
                            </Card.Body>
                        </Card>

                    </Col>
                    <Col xl={4} lg={6} md={12} sm={12} xs={12} className="mb-4">
                        <Card>
                            <Card.Body>
                                {nettBrutoData ?
                                    <ReactApexChart
                                        type="donut"
                                        options={
                                            {
                                                chart: {
                                                    redrawOnParentResize: true,
                                                    redrawOnWindowResize: true
                                                },
                                                labels: ['Discounted', 'Normal Price'],
                                                dataLabels: {
                                                    enabled: true,
                                                },
                                                title: {
                                                    text: 'Sales Type Comparison',
                                                    align: 'left',
                                                },
                                                subtitle: {
                                                    text: 'Here shows a comparison of sales with and without discounts.',
                                                    align: 'left',
                                                    offsetY: 20
                                                },
                                                responsive: [{
                                                    breakpoint: 480,
                                                    options: {
                                                        chart: {
                                                            width: '100%',
                                                        },
                                                        legend: {
                                                            show: true,
                                                        }
                                                    }
                                                }],
                                                legend: {
                                                    position: 'right',
                                                    offsetY: 30,
                                                    height: '100%',
                                                    width: 150
                                                },
                                                plotOptions: {
                                                    pie: {
                                                        startAngle: 0,
                                                        endAngle: 360,
                                                        expandOnClick: true,
                                                        offsetX: 0,
                                                        offsetY: 0,
                                                        customScale: 1,
                                                        dataLabels: {
                                                            offset: 0,
                                                            minAngleToShowLabel: 10
                                                        },
                                                        donut: {
                                                            size: "50%",
                                                        }
                                                    }
                                                },
                                                tooltip: {
                                                    custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                                                        const label = w.globals.labels[seriesIndex];
                                                        const value = curencyNoSym.format(series[seriesIndex]);
                                                        return `<div style="padding: 10px; font-size:0.9em">
                                                                <span>${label}: ${value} Item's</span><br/>
                                                            </div>`;
                                                    }
                                                },
                                            }
                                        }
                                        series={[parseInt(nettBrutoData.with_discount_qty), parseInt(nettBrutoData.without_discount_qty)]}
                                        height={360}
                                    />
                                    : <>No Data</>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xl={8} lg={12} md={12} sm={12} xs={12} className="mb-4">
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col xl={9}>
                                        {
                                            percentTrx ?
                                                <ReactApexChart
                                                    type="line"
                                                    options={
                                                        {
                                                            chart: {
                                                                type: "bar",
                                                                stacked: true
                                                            },
                                                            title: {
                                                                text: 'Percentage Qty from Transaction',
                                                                align: 'left'
                                                            },
                                                            subtitle: {
                                                                text: 'Percentage of the total quantity of transactions.',
                                                                align: 'left',
                                                                offsetY: 20
                                                            },
                                                            plotOptions: {
                                                                bar: {
                                                                    borderRadius: 0,
                                                                    horizontal: false,
                                                                },
                                                            },
                                                            xaxis: {
                                                                categories: percentTrx.per_date
                                                                    .map(trx => trx.trx_date)
                                                                    .reduce((acc, date) => {
                                                                        if (!acc.includes(date)) {
                                                                            acc.push(date);
                                                                        }
                                                                        return acc;
                                                                    }, [])
                                                                    .sort((a, b) => new Date(a) - new Date(b))
                                                                    .map(date => new Date(date).toLocaleDateString('in-US', {
                                                                        weekday: 'long',
                                                                        month: 'short',
                                                                        day: 'numeric'
                                                                    }))
                                                            },
                                                            yaxis: {
                                                                labels: {
                                                                    formatter: function (val) {
                                                                        return curency.format(val);
                                                                    },
                                                                },
                                                            },
                                                            dataLabels: {
                                                                enabled: true,
                                                                formatter: function (val, { dataPointIndex, seriesIndex }) {
                                                                    const perDate = percentTrx.per_date
                                                                    return seriesIndex === 0
                                                                        ? Math.round(perDate[dataPointIndex].percentage_with_multiple_qty) + '%'
                                                                        : Math.round(perDate[dataPointIndex].percentage_with_single_qty) + '%';
                                                                },
                                                                style: {
                                                                    fontSize: '9px',
                                                                }
                                                            },
                                                        }
                                                    }
                                                    series={[
                                                        {
                                                            name: "Transaction Multiple Qty",
                                                            data: percentTrx.per_date.map(item => parseInt(item.transactions_with_multiple_qty, 10)) // Mengambil transaksi dengan multiple qty
                                                        },
                                                        {
                                                            name: "TransactionSingle Qty",
                                                            data: percentTrx.per_date.map(item => parseInt(item.transactions_with_single_qty, 10)) // Mengambil transaksi dengan single qty
                                                        }
                                                    ]}
                                                    height={500}
                                                />
                                                : <>No Data</>
                                        }
                                    </Col>
                                    <Col xl={3}>
                                        {
                                            percentTrx ?
                                                <ReactApexChart
                                                    type="donut"
                                                    options={
                                                        {
                                                            chart: {
                                                                redrawOnParentResize: true,
                                                                redrawOnWindowResize: true
                                                            },
                                                            title: {
                                                                text: 'All Date',
                                                                align: 'left',
                                                            },
                                                            subtitle: {
                                                                text: 'On Selected Showroom.',
                                                                align: 'left',
                                                                offsetY: 20
                                                            },
                                                            labels: ['Multiple Qty', 'Single Qty'],
                                                            dataLabels: {
                                                                enabled: true,
                                                            },
                                                            responsive: [{
                                                                breakpoint: 480,
                                                                options: {
                                                                    chart: {
                                                                        width: '100%',
                                                                    },
                                                                    legend: {
                                                                        show: true,
                                                                    }
                                                                }
                                                            }],
                                                            legend: {
                                                                show: false,
                                                                position: 'right',
                                                                offsetY: 30,
                                                                height: '100%',
                                                                width: 150
                                                            },
                                                            plotOptions: {
                                                                pie: {
                                                                    startAngle: 0,
                                                                    endAngle: 360,
                                                                    expandOnClick: true,
                                                                    offsetX: 0,
                                                                    offsetY: 20,
                                                                    customScale: 1,
                                                                    dataLabels: {
                                                                        offset: 0,
                                                                        minAngleToShowLabel: 10
                                                                    },
                                                                    donut: {
                                                                        size: "50%",
                                                                    }
                                                                }
                                                            },
                                                            tooltip: {
                                                                custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                                                                    const label = w.globals.labels[seriesIndex];
                                                                    const value = curencyNoSym.format(series[seriesIndex]);
                                                                    return `<div style="padding: 10px; font-size:0.9em">
                                                                    <span>${label}: ${value} Item's</span><br/>
                                                                </div>`;
                                                                }
                                                            },
                                                        }
                                                    }
                                                    series={[
                                                        parseInt(percentTrx.showroom.transactions_with_multiple_qty),
                                                        parseInt(percentTrx.showroom.transactions_with_single_qty)
                                                    ]}
                                                    height={360}
                                                />
                                                : <>No Data</>
                                        }
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>

            <Modal open={modalTrx} onClose={() => setModalTrx(false)} overflow={false} backdrop="static" children={false} size="md">
                <Modal.Header>
                    <Modal.Title>POS Transaction Data</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-2">
                        <span>Selected Date : </span>
                        <span className="fw-bold">{selectedDate}</span>
                    </div>
                    <Reacttable columns={columnsTrxData} data={trxByDate} pageRow={10} totals={['header.total_qty', 'header.total_payment']} />
                </Modal.Body>
            </Modal>

            <Modal open={modalTrxDetail} onClose={() => setModalTrxDetail(false)} overflow={false} backdrop="static" children={true} size="md">
                <Modal.Header>
                    <Modal.Title>POS Transaction Detail</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Reacttable columns={columnsTrxDataDetail} data={selectedTrxDetail?.detail} pageRow={10} totals={['qty_order', 'final_price']} />
                </Modal.Body>
            </Modal>
        </>
    )
}