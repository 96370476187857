import React, { useEffect, useMemo, useState } from "react";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Button, Dimmer, Dropdown, Form, FormCheckbox, FormDropdown, FormField, FormGroup, FormInput, FormRadio, Icon, Input, Loader, Popup } from 'semantic-ui-react'
import { useMyGlobal } from "../../component/Global";
import axios from "axios";
import { Toaster } from "../../component/Toaster";
import Modal from "rsuite/Modal";
import { applyDefaultFilter, Reacttable } from "../../component/Reacttable";
import Stack from "react-bootstrap/Stack";
import Tabs from "rsuite/Tabs";
import { curencyNoSym, currentDate } from "../../component/utils";


export const PostingPOS = () => {
    const [modalAdd, setModalAdd] = useState(false)
    const [modalEdit, setModalEdit] = useState(false)
    const { userProfile, host } = useMyGlobal()
    const [loadData, setLoadData] = useState(false)
    const [date, setDate] = useState(currentDate)
    const [salesGross, setSalesGross] = useState([])
    const [loadProc, setLoadProc] = useState(false)
    const [salesByPay, setSalesByPay] = useState([])
    const [pettyCashData, setPettyCashData] = useState([])
    const [voucherData, setVoucherData] = useState([])

    const colSalesGross = useMemo(() => applyDefaultFilter([
        {
            Header: "ID Showroom",
            accessor: "id_showroom"
        },
        {
            Header: "Code",
            accessor: "showroom_abr"
        },
        {
            Header: "Description",
            accessor: "showroom_name"
        },
        {
            Header: "Total Gross",
            accessor: "total_gross",
            Cell: ({ cell }) => curencyNoSym.format(cell.value)
        },
        {
            Header: "Total Return",
            accessor: "total_return",
            Cell: ({ cell }) => curencyNoSym.format(cell.value)
        },
        {
            Header: "Total Discount",
            accessor: "total_discount",
            Cell: ({ cell }) => curencyNoSym.format(cell.value)
        },
        {
            Header: "PPN",
            accessor: "ppn",
            Cell: ({ cell }) => curencyNoSym.format(cell.value)
        },
    ]))

    const colSalesByPay = useMemo(() => applyDefaultFilter([
        {
            Header: "ID Showroom",
            accessor: "id_showroom"
        },
        {
            Header: "Code",
            accessor: "showroom_abr"
        },
        {
            Header: "Description",
            accessor: "showroom_name"
        },
        {
            Header: "Pay Type",
            accessor: "payment_type"
        },
        {
            Header: "Amount",
            accessor: "amount",
            Cell: ({ cell }) => curencyNoSym.format(cell.value)
        },
    ]))

    const colPettyCash = useMemo(() => applyDefaultFilter([
        {
            Header: "Group",
            accessor: "group_name"
        },
        {
            Header: "ID SR",
            accessor: "id_showroom"
        },
        {
            Header: "Abr",
            accessor: "showroom_abr"
        },
        {
            Header: "Showroom",
            accessor: "showroom_name"
        },
        {
            Header: "COA ID",
            accessor: "code_coa"
        },
        {
            Header: "COA Number",
            accessor: "coa_number"
        },
        {
            Header: "COA Desc",
            accessor: "name_coa"
        },
        {
            Header: "Amount",
            accessor: "amount",
            Cell: ({ cell }) => {
                const sanitizedAmount = parseFloat(cell.value?.toString().replace(/-/g, '')) || 0;
                return curencyNoSym.format(sanitizedAmount);
            }
        },
    ]))

    const colVoucher = useMemo(() => applyDefaultFilter([
        {
            Header: "Group",
            accessor: "group_name"
        },
        {
            Header: "ID Showroom",
            accessor: "id_showroom"
        },
        {
            Header: "Showroom",
            accessor: "showroom_name"
        },
        {
            Header: "Voucher",
            accessor: "voucher_nominal"
        },
    ]))

    const handleClickAdd = () => {
        setModalAdd(true)
    }

    const handleClickGen = () => {
        saleGross(date)
        saleByPayement(date)
        pettyCash(date)
        voucher(date)
    }

    const saleGross = async (date) => {
        setLoadProc(true)
        await axios({
            method: "GET",
            url: `${host}/ledger/index.php?action=getPpSalesGross`,
            headers: {
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            params: {
                date: date
            }
        }).then(res => {
            if (res.data.status === 200) {
                setSalesGross(res.data.data)
            }
        }).finally(setLoadProc(false))
    }

    const saleByPayement = async (date) => {
        setLoadProc(true)
        await axios({
            method: "GET",
            url: `${host}/ledger/index.php?action=getPpSalesByPayment`,
            headers: {
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            params: {
                date: date
            }
        }).then(res => {
            if (res.data.status === 200) {
                setSalesByPay(res.data.data)
                Toaster.fire({
                    icon: 'success',
                    text: `Success generate data..`,
                    position: 'top-right'
                })
            }
        }).finally(setLoadProc(false))
    }

    const pettyCash = async (date) => {
        setLoadProc(true)
        await axios({
            method: "GET",
            url: `${host}/ledger/index.php?action=getPpPettyCash`,
            headers: {
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            params: {
                date: date
            }
        }).then (res => {
            if (res.data.status === 200){
                setPettyCashData(res.data.data)
            }
        }).finally(setLoadProc(false))
    }

    const voucher = async (date) => {
        setLoadProc(true)
        await axios({
            method: "GET",
            url: `${host}/ledger/index.php?action=getPpVoucher`,
            headers: {
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            params: {
                date: date
            }
        }).then (res => {
            if (res.data.status === 200){
                setVoucherData(res.data.data)
            }
        }).finally(setLoadProc(false))
    }

    return (
        <>
            <Container>
                <div className="mb-3 mt-2">
                    <div className="h4">Posting POS</div>
                </div>
                <Row className="d-flex justify-content-center">
                    <Dimmer active={loadData} inverted>
                        <Loader inverted>Loading...</Loader>
                    </Dimmer>
                    <Col xl={10} lg={12} md={12} className="mb-4">
                        <Button content="Add New Posting" icon="add" onClick={handleClickAdd} color="blue" />
                    </Col>
                </Row>
            </Container>

            <Modal open={modalAdd} onClose={() => setModalAdd(false)} overflow={false} backdrop="static" size="lg">
                <Modal.Header>
                    <Modal.Title>Create Posting POS</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xl={10} lg={12}>
                            <Form id="frmCreate">
                                <Form.Group>
                                    <FormField className="small">
                                        <label>Date</label>
                                        <Input
                                            type="date"
                                            value={date}
                                            onChange={(e) => setDate(e.target.value)}
                                            action={
                                                <Button content="Generate" icon="magic" color="blue" basic loading={loadProc} disabled={loadProc} onClick={handleClickGen} />
                                            }
                                        />
                                    </FormField>
                                </Form.Group>
                            </Form>
                        </Col>
                        <Col xl={10} lg={12} md={12} className="mb-3 w-100">
                            <Tabs defaultActiveKey="1" appearance="tabs" >
                                <Tabs.Tab eventKey="1" title="Sales Gross">
                                    <Reacttable
                                        data={salesGross}
                                        columns={colSalesGross}
                                        pagRow={10}
                                        noData={salesGross.length === 0 ? true : false}
                                        totals={['total_gross', 'total_discount', 'total_return', 'ppn']}
                                    />
                                </Tabs.Tab>
                                <Tabs.Tab eventKey="2" title="Sales by Payment">
                                    <Reacttable
                                        data={salesByPay}
                                        columns={colSalesByPay}
                                        pagRow={10}
                                        noData={salesByPay.length === 0 ? true : false}
                                        totals={['amount']}
                                    />
                                </Tabs.Tab>
                                <Tabs.Tab eventKey="3" title="Petty Cash">
                                    <Reacttable
                                        data={pettyCashData}
                                        columns={colPettyCash}
                                        pagRow={10}
                                        noData={pettyCashData.length === 0 ? true : false}
                                    />
                                </Tabs.Tab>
                                <Tabs.Tab eventKey="4" title="Voucher">
                                    <Reacttable
                                        data={voucherData}
                                        columns={colVoucher}
                                        pagRow={10}
                                        noData={voucherData.length === 0 ? true : false}
                                        totals={['voucher']}
                                    />
                                </Tabs.Tab>
                            </Tabs>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    )
}