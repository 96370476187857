import React, { useEffect, useMemo, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import { Button, Dimmer, Form, FormDropdown, FormInput, Loader, Popup } from "semantic-ui-react";
import { Modal } from "rsuite";
import { useMyGlobal } from "../component/Global";
import { Toaster } from "../component/Toaster";
import { Reacttable } from "../component/Reacttable";



export const ShowroomGroup = () => {
    const [modalAdd, setModalAdd] = useState(false)
    const [modalEdit, setModalEdit] = useState(false)
    const [name, setName] = useState('')
    const [desc, setDesc] = useState('')
    const [manager, setManager] = useState('')
    const { userProfile, host } = useMyGlobal()
    const [data, setData] = useState([])
    const [selectedRow, setSelectedRow] = useState(null)
    const [loader, setLoader] = useState(false)
    const [loadSubmit, setLoadSubmit] = useState(false)

    const columns = useMemo(() => [
        {
            Header: "ID",
            accessor: "id",
        },
        {
            Header: "Name",
            accessor: "group_name",
        },
        {
            Header: "Description",
            accessor: "description",
        },
        {
            Header: "Manager",
            accessor: "manager",
        },
        {
            Header: "User",
            accessor: "user_name",
        },
        {
            Header: "Action",
            accessor: null,
            Cell: ({ cell }) => (
                <Button.Group basic size="mini">
                    <Popup content="Edit" size="mini" trigger={
                        <Button icon={{ name: 'edit outline', color: 'blue' }} onClick={() => handleClickEdit(cell.row.original)} />
                    } />
                    <Popup content="Delete" size="mini" trigger={
                        <Button icon={{ name: 'trash alternate', color: 'blue' }} onClick={() => handleDelete(cell.row.original)} />
                    } />
                </Button.Group>
            )
        },
    ])

    const handleClickEdit = (data) => {
        setSelectedRow(data)
        setModalEdit(true)
        setName(data.group_name)
        setDesc(data.description)
        setManager(data.manager)
    }

    const handleDelete = (data) => {
        submitDelete(data.id)
    }

    const handleClickAdd = () => {
        setModalAdd(true)
    }

    const getGroup = async () => {
        setLoader(true)
        await axios({
            method: "GET",
            url: `${host}/showroom/index.php?action=showroomGroup`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            }
        }).then(res => {
            if (res.data.status === 200) {
                setData(res.data.data)
            }
        }).finally(() => setLoader(false))
    }

    const clearForm = () => {
        setName('')
        setDesc('')
        setManager('')
    }

    const submitEdit = () => {
        setLoadSubmit(true)
        axios({
            method: "PUT",
            url: `${host}/showroom/`,
            headers: {
                "Content-Type": "aplication/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            data: {
                action: "editShowroomGroup",
                id: selectedRow.id,
                group_name: name,
                desc: desc,
                manager: manager,
                id_user: userProfile.profile.id_user,
            }
        }).then(res => {
            if (res.data.status === 200) {
                Toaster.fire({
                    icon: 'success',
                    text: res.data.msg,
                    position: 'top-right'
                })
                clearForm()
                setModalEdit(false)
                getGroup()
            } else {
                Toaster.fire({
                    icon: 'error',
                    text: res.data.msg,
                    position: 'top-right'
                })
            }
        }).finally(() => setLoadSubmit(false))
    }

    const submitDelete = (id) => {
        const cfm = window.confirm("Are you sure want to delete this?")
        if (cfm) {
            axios({
                method: "DELETE",
                url: `${host}/showroom/index.php?action=removeShowroomGroup&id=${id}`,
                headers: {
                    "Content-Type": "aplication/json",
                    "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
                },
            }).then(res => {
                if (res.data.status === 200) {
                    Toaster.fire({
                        icon: 'success',
                        text: res.data.msg,
                        position: 'top-right'
                    })
                    getGroup()
                } else {
                    Toaster.fire({
                        icon: 'error',
                        text: res.data.msg,
                        position: 'top-right'
                    })
                }
            })
        }
    }

    const handleSubmit = (e) => {
        setLoadSubmit(true)
        const param = new FormData()
        param.append("group_name", name)
        param.append("desc", desc)
        param.append("manager", manager)
        param.append("id_user", userProfile.profile.id_user)
        param.append("action", "addShowroomGroup")

        axios({
            method: "POST",
            url: `${host}/showroom/`,
            headers: {
                "Content-Type": "aplication/x-www-form-urlencoded",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            data: param
        }).then(res => {
            if (res.data.status === 200) {
                Toaster.fire({
                    icon: "success",
                    text: res.data.msg,
                    Position: 'top-right'
                })
                clearForm()
                setModalAdd(false)
                getGroup()
            } else {
                Toaster.fire({
                    icon: "error",
                    text: res.data.msg,
                    Position: 'top-right'
                })
            }
        }).finally(() => setLoadSubmit(false))
    }

    useEffect(() => {
        getGroup()
    }, [])

    return (
        <>
            <Container>
                <div className="mb-3 mt-2">
                    <div className="h4">Showroom Group</div>
                </div>
                <Dimmer active={loader} inverted>
                    <Loader inverted>Loading</Loader>
                </Dimmer>
                <Row className="d-flex justify-content-center">
                    <Col xl={10} lg={12} md={12} sm={12} className="mb-3">
                        <Button content="Add New Group" icon="add" onClick={handleClickAdd} color="blue" />
                    </Col>
                    <Col xl={10} lg={12} md={12} sm={12}>
                        <Reacttable
                            data={data}
                            columns={columns}
                            pageRow={10}
                            noData={data.length === 0 ? "true" : false}
                        />
                    </Col>
                </Row>
            </Container>

            <Modal open={modalAdd} onClose={() => setModalAdd(false)} overflow={false} backdrop="static" size="xs">
                <Modal.Header>
                    <Modal.Title>Add New Group</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="frmAdd" onSubmit={handleSubmit}>
                        <Form.Group grouped>
                            <FormInput label="Group Name" maxLength={30} value={name} onChange={(e) => setName(e.target.value)} required />
                            <FormInput label="Description" maxLength={100} value={desc} onChange={(e) => setDesc(e.target.value)} />
                            <FormInput label="Manager" maxLength={30} value={manager} onChange={(e) => setManager(e.target.value)} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Submit" color="blue" form="frmAdd" type="submit" loading={loadSubmit} disabled={loadSubmit} />
                </Modal.Footer>
            </Modal>

            <Modal open={modalEdit} onClose={() => setModalEdit(false)} overflow={false} backdrop="static" size="xs">
                <Modal.Header>
                    <Modal.Title>Edit Group</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="frmEdit" onSubmit={submitEdit}>
                        <Form.Group grouped>
                            <FormInput label="Group Name" maxLength={30} value={name} onChange={(e) => setName(e.target.value)} required />
                            <FormInput label="Description" maxLength={100} value={desc} onChange={(e) => setDesc(e.target.value)} />
                            <FormInput label="Manager" maxLength={30} value={manager} onChange={(e) => setManager(e.target.value)} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Submit Change" color="blue" form="frmEdit" type="submit" loading={loadSubmit} disabled={loadSubmit} />
                </Modal.Footer>
            </Modal>
        </>
    )
}
