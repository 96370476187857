import React, { useEffect, useState } from "react";
import Table from 'react-bootstrap/Table'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Button, Dimmer, Dropdown, Form, FormDropdown, FormField, FormGroup, FormInput, Icon, Input, Loader, Popup } from 'semantic-ui-react'
import { useMyGlobal } from "../../component/Global";
import axios from "axios";
import { Toaster } from "../../component/Toaster";
import Tabs from 'rsuite/Tabs'
import Modal from "rsuite/Modal";
import { curencyNoSym } from "../../component/utils";

export const MasterCoa = () => {
    const [modalAdd, setModalAdd] = useState(false)
    const [modalEdit, setModalEdit] = useState(false)
    const { userProfile, host } = useMyGlobal()
    const [dataCoa, setDataCoa] = useState([])
    const [groupCoa, setGroupCoa] = useState(null)
    const [loadData, setLoadData] = useState(false)
    const [coaGroup, setCoaGroup] = useState([])
    const [groupOpt, setGroupOpt] = useState([])
    const [group, setGroup] = useState('')
    const [coaDesc, setCoaDesc] = useState('')
    const [coa, setCoa] = useState('')
    const [coaType, setCoaType] = useState('')
    const [coaLevel, setCoaLevel] = useState('')
    const [coaSaldo, setCoaSaldo] = useState(0)
    const [coaSaldoAhir, setCoaSaldoAhir] = useState(0)
    const [coaId, setCoaId] = useState('')
    const [selCoa, setSelCoa] = useState(null)
    const [loadProc, setLoadProc] = useState(false)

    const handleClickAdd = () => {
        setModalAdd(true)
    }

    const handleClickEdit = (data) => {
        setCoa(data.coa)
        setCoaDesc(data.description)
        setGroup(data.group_code)
        setCoaLevel(data.level)
        setCoaSaldo(data.saldo)
        setCoaSaldoAhir(data.saldo_ahir)
        setCoaType(data.type)
        setCoaId(data.coa_id)
        setModalEdit(true)
    }

    const renderRow = (row) => {
        const indent = " ".repeat(row.level * 2); // Memberikan indentasi berdasarkan level
        const isHeader = row.type === "Parent";

        // Menggabungkan COA dan Description dalam satu kolom
        const coaAndDescription = `${row.coa} - ${row.description}`;

        return (
            <tr key={row.id} className="align-middle" style={{ fontSize: '0.9em' }}>
                <td style={{ paddingLeft: `${row.level * 20}px`, fontWeight: isHeader ? 'bold' : 'normal' }}>
                    {indent}{coaAndDescription}
                </td>
                <td>{row.saldo}</td>
                <td>
                    <>
                        <Popup content="Edit" size="tiny" trigger={
                            <Icon name="edit" color="blue" className="me-2" style={{ cursor: 'pointer' }} onClick={() => handleClickEdit(row)} />
                        } />
                        <Popup content="Delete" size="tiny" trigger={
                            <Icon name="trash alternate" color="blue" className="me-2" style={{ cursor: 'pointer' }} onClick={() => handleClickRemove(row)} />
                        } />
                    </>
                </td>
            </tr>
        );
    };

    const handleClickRemove = (data) => {
        setCoaId(data.coa_id)
        handleDelete(data.coa_id)
    }

    const getMasterCoa = async () => {
        setLoadData(true)
        axios({
            method: "GET",
            url: `${host}/ledger/index.php`,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            params: {
                action: 'getMasterCoa'
            }
        }).then(res => {
            if (res.data.status === 200) {
                const data = res.data.data
                setDataCoa(data)

                const groupDataByGroupName = (data) => {
                    const groupedArray = [];
                    data.forEach(item => {
                        let group = groupedArray.find(g => g.group_name === item.group_name);                        // Jika grup belum ada, buat grup baru
                        if (!group) {
                            group = { group_name: item.group_name, items: [] };
                            groupedArray.push(group);
                        }
                        group.items.push(item);
                    });
                    return groupedArray;
                };
                setGroupCoa(groupDataByGroupName(data));
            } else {
                Toaster.fire({
                    icon: 'error',
                    text: res.data.msg,
                    position: 'top-right'
                })
            }
        }).finally(() => setLoadData(false))
    }

    const getCoaGroup = async () => {
        axios({
            method: "GET",
            url: `${host}/ledger/index.php`,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            params: {
                action: 'getCoaGroup'
            }
        }).then(res => {
            if (res.data.status === 200) {
                setCoaGroup(res.data.data)
                const opt = res.data.data.map(i => ({
                    key: i.id,
                    text: i.group_name,
                    value: i.group_code,
                }))
                setGroupOpt(opt)
            }
        })
    }

    const clearForm = () => {
        setCoa("")
        setCoaDesc("")
        setGroup("")
        setCoaLevel(0)
        setCoaSaldo(0)
        setCoaSaldoAhir(0)
        setCoaType("")
        setCoaId("")
    }

    const handleSubmit = () => {
        setLoadProc(true)
        const param = {
            group_code: group,
            coa_number: coa,
            desc: coaDesc,
            type: coaType,
            level: coaLevel,
            saldo: coaSaldo,
            saldo_ahir: coaSaldoAhir,
        }
        axios({
            method: "POST",
            url: `${host}/ledger/index.php?action=addCoa`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            data: param
        }).then(res => {
            if (res.data.status === 200) {
                Toaster.fire({
                    icon: 'success',
                    text: res.data.msg,
                    position: 'top-right'
                })
                clearForm()
                setModalAdd(false)
                getMasterCoa()
            } else {
                Toaster.fire({
                    icon: 'error',
                    text: res.data.msg,
                    position: 'top-right'
                })
            }
        }).finally(() => setLoadProc(false))
    }

    const handleSubmitEdit = () => {
        setLoadProc(true)
        const param = {
            group_code: group,
            coa_number: coa,
            desc: coaDesc,
            type: coaType,
            level: coaLevel,
            saldo: coaSaldo,
            saldo_ahir: coaSaldoAhir,
            id: coaId,
        }
        axios({
            method: "PUT",
            url: `${host}/ledger/index.php?action=editCoa`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            data: param
        }).then(res => {
            if (res.data.status === 200) {
                Toaster.fire({
                    icon: 'success',
                    text: res.data.msg,
                    position: 'top-right'
                })
                clearForm()
                setModalEdit(false)
                getMasterCoa()
            } else {
                Toaster.fire({
                    icon: 'error',
                    text: res.data.msg,
                    position: 'top-right'
                })
            }
        }).finally(() => setLoadProc(false))
    }

    const handleDelete = (id) => {
        const param = {
            coa_id: id
        }
        const cfm = window.confirm('Are you sure ?')
        
        if (cfm) {
            axios({
                method: "DELETE",
                url: `${host}/ledger/index.php?action=deleteCoa`,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
                },
                data: param
            }).then(res => {
                if (res.data.status === 200) {
                    Toaster.fire({
                        icon: 'success',
                        text: res.data.msg,
                        position: 'top-right'
                    })
                    getMasterCoa()
                } else {
                    Toaster.fire({
                        icon: 'error',
                        text: res.data.msg,
                        position: 'top-right'
                    })
                }
            })
        }
    }

    const formatCoa = (value) => {
        // Hapus semua karakter non-digit
        value = value.replace(/\D/g, '');

        // Tambahkan titik sesuai dengan format mask
        const segments = [
            value.slice(0, 1),
            value.slice(1, 3),
            value.slice(3, 6),
            value.slice(6, 9),
            value.slice(9, 12)
        ];

        // Gabungkan segmen dengan titik
        return segments.filter(segment => segment).join('.');
    };

    const handleCoaChange = (e) => {
        let newCoa = e.target.value;
        newCoa = formatCoa(newCoa);

        // Pastikan segmen pertama mengikuti group_code
        if (newCoa.startsWith(group)) {
            setCoa(newCoa);
        } else {
            setCoa(`${group}.${newCoa.split('.').slice(1).join('.')}`);
        }

        const segments = newCoa.split('.').filter(segment => segment.trim() !== '');
        setCoaLevel(segments.length);  // Set level berdasarkan jumlah segmen yang ada
    };

    const handleBeginBalance = (e) => {
        let newBgBlc = e.target.value
        newBgBlc = Number(newBgBlc.replace(/\D/g, ""))
        setCoaSaldo(newBgBlc)
    }

    const handleGroupChange = (e, { value }) => {
        const selectedGroup = coaGroup.find(group => group.group_code === value);
        setGroup(selectedGroup.group_code);
        setCoa(`${selectedGroup.group_code}.`);
        setCoaLevel(1)
    };

    useEffect(() => {
        getMasterCoa()
        getCoaGroup()
    }, [])

    return (
        <>
            <Container>
                <div className="mb-3 mt-2">
                    <div className="h4">Master COA</div>
                </div>
                <Row className="d-flex justify-content-center">
                    <Dimmer active={loadData} inverted>
                        <Loader inverted>Loading...</Loader>
                    </Dimmer>
                    <Col xl={10} lg={12} md={12} className="mb-4">
                        <Button content="Add New COA" icon="add" onClick={handleClickAdd} color="blue" />
                    </Col>
                    <Col xl={10} lg={12} md={12} className="mb-3">
                        <Tabs defaultActiveKey="Aktiva" vertical appearance="tabs">
                            {
                                groupCoa?.map((item, i) => { 
                                    return (
                                        <Tabs.Tab eventKey={item.group_name} key={i} title={item.group_name}>
                                            <Table hover responsive>
                                                <thead className="table-light">
                                                    <tr>
                                                        <th>Rekening</th>
                                                        <th>Saldo</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {item.items.map(renderRow)}
                                                </tbody>
                                            </Table>
                                        </Tabs.Tab>
                                    )
                                })
                            }
                        </Tabs>
                    </Col>
                </Row>
            </Container>

            <Modal open={modalAdd} onClose={() => setModalAdd(false)} overflow={false} backdrop="static" size="xs">
                <Modal.Header>
                    <Modal.Title>Add New COA</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="frmAddCoa" onSubmit={handleSubmit}>
                        <FormGroup grouped>
                            <FormDropdown
                                label="Group"
                                options={groupOpt}
                                selection
                                value={group}
                                maxLength="30"
                                required
                                onChange={handleGroupChange}
                            />
                            <FormDropdown
                                label="Position"
                                options={[{ key: '0', text: 'Parent', value: 'Parent' }, { key: '1', text: 'Child', value: 'Child' }]}
                                selection
                                value={coaType}
                                required
                                onChange={(e, { value }) => setCoaType(value)}
                            />
                            <FormField required>
                                <label>COA Number</label>
                                <Input
                                    maxLength="20"
                                    label={{ basic: true, content: `Level ${coaLevel}` }}
                                    value={coa}
                                    placeholder="1.01.001.001.002"
                                    labelPosition="right"
                                    onChange={handleCoaChange}
                                />
                            </FormField>
                            <FormInput
                                label="Description"
                                maxLength="100"
                                required
                                value={coaDesc}
                                placeholder="Kas Operasional"
                                onChange={(e) => setCoaDesc(e.target.value)}
                            />
                            <FormInput
                                value={curencyNoSym.format(coaSaldo)}
                                label="Beginning Balance"
                                onChange={handleBeginBalance}
                            />
                        </FormGroup>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Submit" icon="save" color="blue" form="frmAddCoa" type="submit" loading={loadProc} disabled={loadProc} />
                </Modal.Footer>
            </Modal>

            <Modal open={modalEdit} onClose={() => setModalEdit(false)} overflow={false} backdrop="static" size="xs">
                <Modal.Header>
                    <Modal.Title>Edit COA</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="frmEditCoa" onSubmit={handleSubmitEdit}>
                        <FormGroup grouped>
                            <FormDropdown
                                label="Group"
                                options={groupOpt}
                                selection
                                value={group}
                                onChange={handleGroupChange}
                            />
                            <FormDropdown
                                label="Position"
                                options={[{ key: '0', text: 'Parent', value: 'Parent' }, { key: '1', text: 'Child', value: 'Child' }]}
                                selection
                                value={coaType}
                                onChange={(e, { value }) => setCoaType(value)}
                            />
                            <FormField>
                                <label>COA Number</label>
                                <Input
                                    label={{ basic: true, content: `Level ${coaLevel}` }}
                                    value={coa}
                                    placeholder="1.01.001.001.002"
                                    labelPosition="right"
                                    onChange={handleCoaChange}
                                />
                            </FormField>
                            <FormInput
                                label="Description"
                                value={coaDesc}
                                placeholder="Kas Operasional"
                                onChange={(e) => setCoaDesc(e.target.value)}
                            />
                            <FormInput
                                value={curencyNoSym.format(coaSaldo)}
                                label="Beginning Balance"
                                onChange={handleBeginBalance}
                            />
                        </FormGroup>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Submit" icon="save" color="blue" form="frmEditCoa" type="submit" loading={loadProc} disabled={loadProc} />
                </Modal.Footer>
            </Modal>
        </>
    )
} 