import React, { useEffect, useMemo, useState } from "react";
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import axios from "axios";
import { Button, Dimmer, Form, FormDropdown, FormField, FormGroup, FormInput, FormTextArea, Icon, Input, Loader, Popup } from "semantic-ui-react";
import Modal from "rsuite/modal"
import { useMyGlobal } from "../../component/Global";
import { curencyNoSym, currentDate } from "../../component/utils";
import { applyDefaultFilter, Reacttable } from "../../component/Reacttable";
import Accordion from 'rsuite/Accordion';
import { Toaster } from "../../component/Toaster";
import moment from "moment";
import Stack from "react-bootstrap/Stack";
import { DateRangePicker } from "rsuite";

export const FeeCashBank = () => {
    const [modalCreate, setModalCreate] = useState(false)
    const [modalAccListDet, setModalAccListDet] = useState(false)
    const [modalAccList, setModalAccList] = useState(false)
    const [modalCost, setModalCost] = useState(false)
    const [modalCostDet, setModalCostDet] = useState(false)
    const [modalFcDetail, setModalFcDetail] = useState(false)
    const { userProfile, host, entityOpt, deptOpt } = useMyGlobal()
    const [feeCashData, setFeeCashData] = useState([])
    const [bankData, setBankData] = useState([])
    const [costData, setCostData] = useState([])
    const [entity, setEntity] = useState('1')
    const [date, setDate] = useState(currentDate)
    const [dept, setDept] = useState('1')
    const [bankCash, setBankCash] = useState('')
    const [bankCode, setBankCode] = useState('')
    const [selBank, setSelBank] = useState(null)
    const [selCost, setSelCost] = useState(null)
    const [selCostDet, setSelCostDet] = useState(null)
    const [selBankDet, setSelBankDet] = useState(null)
    const [selFc, setSelFc] = useState(null)
    const [coaCode, setCoaCode] = useState('')
    const [coaDesc, setCoaDesc] = useState('')
    const [costCode, setCostCode] = useState('')
    const [costDesc, setCostDesc] = useState('')
    const [costCodeDet, setCostCodeDet] = useState('')
    const [costDescDet, setCostDescDet] = useState('')
    const [nominal, setNominal] = useState(0)
    const [nominalDet, setNominalDet] = useState(0)
    const [ref, setRef] = useState('')
    const [note, setNote] = useState('')
    const [coaCodeDet, setCoaCodeDet] = useState('')
    const [coaDescDet, setCoaDescDet] = useState('')
    const [detailItem, setDetailItem] = useState([])
    const [loadProc, setLoadProc] = useState(false)
    const [loadData, setLoadData] = useState(false)
    const [openDet, setOpenDet] = useState(true)
    const [noteDet, setNoteDet] = useState('')

    const [dateRange, setDateRange] = useState([
        new Date(moment().startOf('month')),
        new Date(moment()),
    ])

    const columnFeeCash = useMemo(() => applyDefaultFilter([
        {
            Header: "Ledger Number",
            accessor: "header.ledger_no"
        },
        {
            Header: "Date",
            accessor: "header.date"
        },
        {
            Header: "Note",
            accessor: "header.note",
            Cell: ({ cell }) => <div style={{ width: '180px' }}>
                <Popup content={cell.value} size="tiny" trigger={
                    <div className="text-truncate">{cell.value}</div>
                } />
            </div>
        },
        {
            Header: "Nominal",
            accessor: "header.nominal",
            Cell: ({ cell }) => curencyNoSym.format(cell.value)
        },
        {
            Header: "Create At",
            accessor: "header.createAt"
        },
        {
            Header: "User",
            accessor: "header.user_name",
            Cell: ({ cell }) => (
                <Stack direction="vertical">
                    <div style={{ lineHeight: '0.8em' }}>{cell.value}</div>
                    <div className="text-muted" style={{ lineHeight: '1em' }}>{cell.row.original.header.role_name}</div>
                </Stack>
            )
        },
        {
            Header: "Action",
            accessor: null,
            Cell: ({ cell }) => (
                <Button.Group basic size="mini">
                    <Popup size="tiny" content="Edit" trigger={
                        <Button icon={{ name: 'edit', color: 'blue' }} onClick={() => handleClickEditFc(cell.row.original)} />
                    } />
                    <Popup size="tiny" content="View Detail" trigger={
                        <Button icon={{ name: 'list layout', color: 'blue' }} onClick={() => handleClickDetailFc(cell.row.original)} />
                    } />
                </Button.Group>
            )
        },
    ]))

    const columnFeeCashDet = useMemo(() => [
        {
            Header: "Code Rek",
            accessor: "code_rek"
        },
        {
            Header: "Rekening",
            accessor: "desc_rek",
            Cell: ({ cell }) => <div style={{ width: '170px' }}>
                <Popup content={cell.value} size="tiny" trigger={
                    <div className="text-truncate">{cell.value}</div>
                } />
            </div>
        },
        {
            Header: "Cost Center",
            accessor: "cost_code"
        },
        {
            Header: "Debit",
            accessor: "debit",
            Cell: ({ cell }) => curencyNoSym.format(cell.value)
        },
        {
            Header: "Credit",
            accessor: "credit",
            Cell: ({ cell }) => curencyNoSym.format(cell.value)
        },
        {
            Header: "Note",
            accessor: "note",
            Cell: ({ cell }) => <div style={{ width: '180px' }}>
                <Popup content={cell.value} size="tiny" trigger={
                    <div className="text-truncate">{cell.value}</div>
                } />
            </div>
        },
    ])

    const columnsBankList = useMemo(() => applyDefaultFilter([
        {
            Header: "Bank Code",
            accessor: "code_bank"
        },
        {
            Header: "Bank Name",
            accessor: "description"
        },
        {
            Header: "COA",
            accessor: "akun"
        },
        {
            Header: "COA Desc",
            accessor: "akun_desc"
        },
        {
            Header: "Group",
            accessor: "type"
        },
        {
            Header: "Action",
            accessor: null,
            Cell: ({ cell }) => (
                <>
                    <div className="text-center">
                        <Icon name="plus circle" color="blue" onClick={() => handleClickSelect(cell.row.original)} style={{ cursor: 'pointer' }} />
                    </div>
                </>
            )
        },
    ]))

    const columnsBankListDet = useMemo(() => applyDefaultFilter([
        {
            Header: "Bank Code",
            accessor: "code_bank"
        },
        {
            Header: "Bank Name",
            accessor: "description"
        },
        {
            Header: "COA",
            accessor: "akun"
        },
        {
            Header: "COA Desc",
            accessor: "akun_desc"
        },
        {
            Header: "Group",
            accessor: "type"
        },
        {
            Header: "Action",
            accessor: null,
            Cell: ({ cell }) => (
                <>
                    <div className="text-center">
                        <Icon name="plus circle" color="blue" onClick={() => handleClickSelectDet(cell.row.original)} style={{ cursor: 'pointer' }} />
                    </div>
                </>
            )
        },
    ]))


    const columnCost = useMemo(() => applyDefaultFilter([
        {
            Header: "Code",
            accessor: "child_code"
        },
        {
            Header: "Description",
            accessor: "description"
        },
        {
            Header: "Action",
            accessor: null,
            Cell: ({ cell }) => (
                <>
                    <div className="text-center">
                        <Icon name="plus circle" color="blue" onClick={() => handleClickSelectCost(cell.row.original)} style={{ cursor: 'pointer' }} />
                    </div>
                </>
            )
        },
    ]))

    const columnDetail = useMemo(() => [
        {
            Header: "Code Rekening",
            accessor: "code_rek"
        },
        {
            Header: "Description",
            accessor: "desc_rek"
        },
        {
            Header: "Cost Center",
            accessor: "cost_center"
        },
        {
            Header: "Nominal",
            accessor: "nominal",
            Cell: ({ cell }) => curencyNoSym.format(cell.value)
        },
        {
            Header: "Note",
            accessor: "note",
            Cell:({cell}) => (
                <div style={{width:'150px'}}>
                    <Popup content={cell.value} size="tiny" trigger={
                    <div className="text-truncate">{cell.value}</div>}/>
                </div>
            )
        },
        {
            Header: "Action",
            accessor: null,
            Cell: ({ cell, row: { index } }) => (
                <>
                    <div className="text-center">
                        <Icon name="close" color="red" onClick={() => handleRemoveDetail(index)} style={{ cursor: 'pointer' }} />
                    </div>
                </>
            )
        },
    ])

    const columnCostDet = useMemo(() => applyDefaultFilter([
        {
            Header: "Code",
            accessor: "child_code"
        },
        {
            Header: "Description",
            accessor: "description"
        },
        {
            Header: "Action",
            accessor: null,
            Cell: ({ cell }) => (
                <>
                    <div className="text-center">
                        <Icon name="plus circle" color="blue" onClick={() => handleClickSelectCostDet(cell.row.original)} style={{ cursor: 'pointer' }} />
                    </div>
                </>
            )
        },
    ]))

    const handleClickDetailFc = (data) => {
        setSelFc(data)
        setModalFcDetail(true)
    }

    const handleClickEditFc = (data) => {
        setSelFc(data)
    }

    const handleRemoveDetail = (index) => {
        setDetailItem(prev => prev.filter((_, i) => i !== index));
    }

    const handleClickSelectCost = (data) => {
        setSelCost(data)
        setCostCode(data.child_code)
        setCostDesc(data.description)
        setModalCost(false)
    }

    const handleClickSelectCostDet = (data) => {
        setSelCostDet(data)
        setCostCodeDet(data.child_code)
        setCostDescDet(data.description)
        setModalCostDet(false)
    }

    const handleClickSelect = (data) => {
        setSelBank(data)
        setBankCode(data.code_bank)
        setBankCash(data.description)
        setCoaCode(data.akun)
        setCoaDesc(data.akun_desc)
        setModalAccList(false)
    }

    const handleClickSelectDet = (data) => {
        setSelBankDet(data)
        setCoaCodeDet(data.akun)
        setCoaDescDet(data.akun_desc)
        setModalAccListDet(false)
    }

    const handleShowAccDet = () => {
        setModalAccListDet(true)
    }

    const handleClickAdd = () => {
        setModalCreate(true)
    }

    const handleShowAcc = () => {
        setModalAccList(true)
    }

    const handleShowCost = () => {
        setModalCost(true)
    }

    const handleShowCostDet = () => {
        setModalCostDet(true)
    }

    const getBankAcc = async () => {
        await axios({
            method: "GET",
            url: `${host}/finance/index.php`,
            headers: {
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            params: {
                action: 'getBankAcc'
            }
        }).then(res => {
            if (res.data.status === 200) {
                setBankData(res.data.data)
            }
        })
    }

    const getCostCenter = async () => {
        await axios({
            method: "GET",
            url: `${host}/ledger/index.php`,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            params: {
                action: 'getCostChild'
            }
        }).then(res => {
            if (res.data.status === 200) {
                setCostData(res.data.data)
            }
        })
    }

    const handleChangeNominal = (e) => {
        let newNom = e.target.value
        newNom = Number(newNom.replace(/\D/g, ""))
        setNominal(newNom)
    }

    const handleChangeNominalDet = (e) => {
        let newNom = e.target.value
        newNom = Number(newNom.replace(/\D/g, ""))
        setNominalDet(newNom)
    }

    const handleAddCost = () => {
        const newItem = {
            code_rek: coaCodeDet,
            desc_rek: coaDescDet,
            cost_center: costCodeDet,
            nominal: nominalDet,
            note : noteDet,
        };

        // Periksa apakah item sudah ada di array
        const itemExists = detailItem.some(item =>
            // item.code_rek === newItem.code_rek &&
            item.desc_rek === newItem.desc_rek
            // item.cost_center === newItem.cost_center
        );

        // Hitung total nominal dari detailItem
        const totalNominal = detailItem.reduce((sum, item) => sum + item.nominal, 0);

        // Jika total nominal ditambah nominalDet melebihi nominal, tampilkan toaster
        if (totalNominal + nominalDet > nominal) {
            Toaster.fire({
                icon: 'warning',
                text: 'Total cost will exceed, when these cost details are added!',
                position: 'top-right'
            });
        } else if (itemExists) {
            // Jika item sudah ada, tampilkan toaster
            Toaster.fire({
                icon: 'warning',
                text: 'Item already exists',
                position: 'top-right'
            });
        } else if (coaCodeDet === '' && coaDescDet === '' && costCodeDet === '' && costDescDet === '') {
            Toaster.fire({
                icon: 'warning',
                text: "Item is not complete. make sure all information is complete",
                position: 'top-right'
            });
        } else if (nominalDet === 0) {
            Toaster.fire({
                icon: 'warning',
                text: "Cost nominal must be greater than 0",
                position: 'top-right'
            });
        } else {
            // Jika item belum ada dan total nominal tidak melebihi nominal, tambahkan ke array
            setDetailItem(prev => [...prev, newItem]);
            setCoaCodeDet('')
            setCoaDescDet('')
            setCostCodeDet('')
            setCostDescDet('')
            setNominalDet(0)
        }
    };

    const clearForm = () => {
        setEntity(1)
        setDept(1)
        setDate(currentDate)
        setBankCode('')
        setBankCash('')
        setCoaCode('')
        setCoaDesc('')
        setNominal(0)
        setRef('')
        setNote('')
        setCoaCodeDet('')
        setCoaDescDet('')
        setCostCodeDet('')
        setCostDescDet('')
        setDetailItem([])
    }

    const handleSubmit = () => {
        if (!entity || !dept || !date || !bankCode || !bankCash || !coaCode || !coaDesc || !costCode || !costDesc || nominal <= 0 || detailItem.length === 0) {
            Toaster.fire({
                icon: 'warning',
                text: 'Please fill in all required fields and add at least one detail item.',
                position: 'top-right'
            });
            return; // Hentikan proses submit jika ada data yang tidak diisi
        }
        setLoadProc(true)
        const header = {
            entity: entity,
            dept: dept,
            date: date,
            bank_code: bankCode,
            bank_desc: bankCash,
            code_rek: coaCode,
            desc_rek: coaDesc,
            cost_code: costCode,
            cost_desc: costDesc,
            nominal: nominal,
            ref: ref,
            note: note,
            id_user: userProfile.profile.id_user
        }

        const details = detailItem

        const param = {
            header, details
        }

        axios({
            method: "POST",
            url: `${host}/finance/index.php?action=createFeeCash`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            data: param
        }).then(res => {
            if (res.data.status === 200) {
                Toaster.fire({
                    icon: 'success',
                    text: res.data.msg,
                    position: 'top-right'
                })
                clearForm()
                setModalCreate(false)
            } else {
                Toaster.fire({
                    icon: 'error',
                    text: res.data.msg,
                    position: 'top-right'
                })
            }
        }).finally(setLoadProc(false))
    }

    const getFeeCash = async () => {
        setLoadData(true)
        const formatDate = (date) => moment(date).format('YYYY-MM-DD');
        const [startDate, endDate] = dateRange.map(date => formatDate(date));
        const date_range = [startDate, endDate];

        await axios({
            method: "GET",
            url: `${host}/finance/index.php`,
            headers: {
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            params: {
                action: 'getFeeCash',
                date_range: date_range
            }
        }).then(res => {
            if (res.data.status === 200) {
                setFeeCashData(res.data.data)
            }
        }).finally(setLoadData(false))
    }

    const handleGenerate = () => {
        getFeeCash()
    }

    const handleChangeNote = (e) => {
        setNote(e.target.value)
        setNoteDet(e.target.value)
    }

    useEffect(() => {
        if (entity && dept && date && bankCode && bankCash && coaCode && coaDesc && costCode && costDesc && nominal > 0 ) {
            setOpenDet(false);
        } else {
            setOpenDet(true);
        }
    }, [entity, dept, date, bankCode, bankCash, coaCode, coaDesc, costCode, costDesc, nominal, ref, note]);

    useEffect(() => {
        getBankAcc()
        getCostCenter()
    }, [])

    return (
        <>
            <Container>
                <div className="mb-3 mt-2">
                    <div className="h4">Fee Cash Bank</div>
                </div>
                <Row className="d-flex justify-content-center">
                    <Col xl={10} lg={12} md={12} className="mb-3">
                        <DateRangePicker
                            className="me-2"
                            label="Date Range : "
                            format="yyyy-MM-dd"
                            placement="auto"
                            size="md"
                            cleanable={true}
                            defaultValue={dateRange}
                            value={dateRange}
                            onChange={(e) => setDateRange(e)}
                            onClean={() => setDateRange([
                                new Date(moment().startOf('month')),
                                new Date(moment()),
                            ])}
                        />
                        <Button content="Generate Data" color="blue" onClick={handleGenerate} loading={loadData} disabled={loadData} />
                    </Col>
                    <Col xl={10} lg={12} md={12} className="mb-3">
                        <Button content="Create New Fee" icon="add" onClick={handleClickAdd} color="blue" />
                    </Col>
                    <Col xl={10} lg={12} md={12}>
                        <Reacttable
                            data={feeCashData}
                            columns={columnFeeCash}
                            pageRow={10}
                            noData={feeCashData?.length === 0 ? true : false}
                            totals={['header.nominal']}
                        />
                    </Col>
                </Row>
            </Container>

            <Modal open={modalCreate} onClose={() => setModalCreate(false)} backdrop="static" overflow={false} size='lg'>
                <Modal.Header>
                    <Modal.Title>Create New Fee</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Accordion >
                                <Accordion.Panel header="Header Entry" defaultExpanded>
                                    <Form id="frmHead">
                                        <Row>
                                            <Col xl={4} lg={4}>
                                                <Form.Group grouped>
                                                    <FormDropdown
                                                        className="small"
                                                        options={entityOpt}
                                                        selection
                                                        value={entity}
                                                        label="Entity"
                                                        required
                                                        onChange={(e, { value }) => setEntity(value)}
                                                    />
                                                    <FormDropdown
                                                        className="small"
                                                        label="Departement"
                                                        selection
                                                        search
                                                        options={deptOpt}
                                                        value={dept}
                                                        required
                                                        onChange={(e, { value }) => setDept(value)}
                                                    />
                                                    <FormInput
                                                        className="small"
                                                        label="Date"
                                                        type="date"
                                                        value={date}
                                                        required
                                                        onChange={(e, { value }) => setDate(value)}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col xl={5} lg={5}>
                                                <FormGroup grouped>
                                                    <FormField className="small" required>
                                                        <label>Bank/Cash</label>
                                                        <Input
                                                            value={`${bankCode} - ${bankCash}`}
                                                            action={
                                                                <Button icon={{ name: 'ellipsis horizontal', color: 'blue' }} type="button" onClick={handleShowAcc} basic />
                                                            }
                                                        />
                                                    </FormField>
                                                    <FormInput
                                                        className="small"
                                                        label="Code Rekening"
                                                        readOnly
                                                        value={`${coaCode} - ${coaDesc}`}
                                                    />
                                                    <FormField className="small" required>
                                                        <label>Cost Center</label>
                                                        <Input
                                                            value={`${costCode} - ${costDesc}`}
                                                            action={
                                                                <Button icon={{ name: 'ellipsis horizontal', color: 'blue' }} type="button" onClick={handleShowCost} basic />
                                                            }
                                                        />
                                                    </FormField>
                                                </FormGroup>
                                            </Col>
                                            <Col xl={3} lg={3}>
                                                <FormGroup grouped>
                                                    <FormInput
                                                        className="small"
                                                        label="Nominal"
                                                        value={curencyNoSym.format(nominal)}
                                                        required
                                                        onChange={handleChangeNominal}
                                                    />
                                                    <FormInput
                                                        className="small"
                                                        label="Ref"
                                                        value={ref}
                                                        maxLength="30"
                                                        onChange={(e) => setRef(e.target.value)}
                                                    />
                                                    <FormInput
                                                        className="small"
                                                        label="Note"
                                                        value={note}
                                                        maxLength="100"
                                                        placeholder="Perbaikan Peralatan Komputer Hoops Surapati"
                                                        onChange={handleChangeNote}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Accordion.Panel>
                                <Accordion.Panel header="Detail Entry" disabled={openDet}>
                                    <Form id="frmDetail">
                                        <Row>
                                            <Col xl={4} lg={4}>
                                                <FormGroup grouped>
                                                    <FormField className="small">
                                                        <label>Code Rekening</label>
                                                        <Input
                                                            value={`${coaCodeDet} - ${coaDescDet}`}
                                                            action={
                                                                <Button icon={{ name: 'ellipsis horizontal', color: 'blue' }} type="button" basic onClick={handleShowAccDet} />
                                                            }
                                                        />
                                                    </FormField>
                                                </FormGroup>
                                            </Col>
                                            <Col xl={5} lg={5}>
                                                <FormGroup grouped>
                                                    <FormField className="small">
                                                        <label>Cost Center</label>
                                                        <Input
                                                            value={`${costCodeDet} - ${costDescDet}`}
                                                            action={
                                                                <Button icon={{ name: 'ellipsis horizontal', color: 'blue' }} type="button" basic onClick={handleShowCostDet} />
                                                            }
                                                        />
                                                    </FormField>
                                                </FormGroup>
                                            </Col>
                                            <Col xl={3} lg={3}>
                                                <FormGroup grouped>
                                                    <FormField className="small">
                                                        <label>Nominal</label>
                                                        <Input
                                                            fluid
                                                            required
                                                            value={curencyNoSym.format(nominalDet)}
                                                            onChange={handleChangeNominalDet}
                                                            action={
                                                                <Popup content="Click here to add to detail table below" size="tiny" trigger={
                                                                    <Button icon={{ name: 'arrow alternate circle down outline', color: 'orange' }} type="button" basic onClick={handleAddCost} />
                                                                } />
                                                            }
                                                        />
                                                    </FormField>
                                                </FormGroup>
                                            </Col>
                                            <Col md={9}>
                                                <FormGroup grouped>
                                                    <FormInput
                                                        className="small"
                                                        label="Note"
                                                        fluid
                                                        value={noteDet}
                                                        placeholder='Perbaikan Peralatan Komputer Hoops Surapati'
                                                        onChange={(e) => setNoteDet(e.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg={12} md={12} className="mt-3">
                                                <Reacttable
                                                    data={detailItem}
                                                    columns={columnDetail}
                                                    pageRow={10}
                                                    noData={detailItem.length === 0 ? true : false}
                                                />
                                            </Col>
                                        </Row>
                                    </Form>
                                </Accordion.Panel>
                            </Accordion>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Create" icon="save" color="blue" onClick={handleSubmit} form="frmHead" loading={loadProc} disabled={loadProc} />
                </Modal.Footer>
            </Modal>

            <Modal open={modalAccList} onClose={() => setModalAccList(false)} overflow={false} size="md">
                <Modal.Header>
                    <Modal.Title>Account List</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Reacttable
                        data={bankData}
                        columns={columnsBankList}
                        pageRow={10}
                        noData={bankData.length === 0 ? true : false}
                    />
                </Modal.Body>
            </Modal>

            <Modal open={modalAccListDet} onClose={() => setModalAccListDet(false)} overflow={false} size="md">
                <Modal.Header>
                    <Modal.Title>Account List</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Reacttable
                        data={bankData}
                        columns={columnsBankListDet}
                        pageRow={10}
                        noData={bankData.length === 0 ? true : false}
                    />
                </Modal.Body>
            </Modal>

            <Modal open={modalCost} onClose={() => setModalCost(false)} overflow={false} size="md">
                <Modal.Header>
                    <Modal.Title>Cost Center List</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Reacttable
                        data={costData}
                        columns={columnCost}
                        pageRow={10}
                        noData={costData.length === 0 ? true : false}
                    />
                </Modal.Body>
            </Modal>

            <Modal open={modalCostDet} onClose={() => setModalCostDet(false)} overflow={false} size="md">
                <Modal.Header>
                    <Modal.Title>Cost Center List</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Reacttable
                        data={costData}
                        columns={columnCostDet}
                        pageRow={10}
                        noData={costData.length === 0 ? true : false}
                    />
                </Modal.Body>
            </Modal>

            <Modal open={modalFcDetail} onClose={() => setModalFcDetail(false)} overflow={false} size='lg'>
                <Modal.Header>
                    <Modal.Title>Fee Cash Detail</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Reacttable
                        data={selFc?.detail}
                        columns={columnFeeCashDet}
                        pageRow={10}
                        noData={selFc?.detail.length === 0 ? true : false}
                        totals={['debit', 'credit']}
                    />
                </Modal.Body>
            </Modal>
        </>
    )
}