
import moment from 'moment-timezone';
import { Toaster } from './Toaster';

moment.tz.setDefault('Asia/Jakarta')

export const currentDate = moment.utc().tz('Asia/Jakarta').format('YYYY-MM-DD')
export const currentTime = moment.utc().tz('Asia/Jakarta').format('HH:mm:ss')
export const currentDateTime = moment.utc().tz('Asia/Jakarta').format('YYYY-MM-DD HH:mm:ss')
export const futureDate = (days) => moment.utc().tz('Asia/Jakarta').add(days, 'days').format('YYYY-MM-DD');

export const copyTextToClipboard = (text) => { 
    if (!navigator.clipboard) {
        // Jika browser tidak mendukung navigator.clipboard, gunakan metode fallback
        const textArea = document.createElement('textarea');
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.select();
        try {
            document.execCommand('copy');
            alert('Text copied to clipboard!');
        } catch (err) {
            console.error('Failed to copy text: ', err);
        }
        document.body.removeChild(textArea);
        return;
    }

    // Menggunakan API Clipboard jika tersedia
    navigator.clipboard.writeText(text).then(() => {
        Toaster.fire({
            icon:'info',
            text:'Text copied to clipboard!',
            position:'top-right'
        })
    }).catch(err => {
        Toaster.fire({
            icon:'warning',
            text:`Failed to copy text: ${err}`,
            position:'top-right'
        })
    });
};

export const sha256 = async (str) => {
    const encoder = new TextEncoder();
    const data = encoder.encode(str);
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    return hashHex;
}

export const tableBodyStyle = {
    width: 'auto',
    minWidth: 0,
    maxWidth: '200px',
    display: 'inlineBlock',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: '0.9em',
}

export const curency = new Intl.NumberFormat('IN', {
    style: 'currency',
    currency: 'IDR',
    minimumFractionDigits: 0,
    currencyDisplay: 'symbol'
});

export const curencyNoSym = new Intl.NumberFormat('IN', {
    style: 'decimal',
    minimumFractionDigits: 0,
});

export const removeDuplicatesArray = (array, key) => {
    const seen = {};
    return array.filter(item => {
        const value = item[key];
        return seen.hasOwnProperty(value) ? false : (seen[value] = true);
    });
};

export const badgeColor = (status) => {
    if (status === 'complete') {
        return 'cyan'
    } else if (status === 'partial') {
        return 'orange'
    } else {
        return 'whitesmoke'
    }
}

export const capitalize = (str) => {
    if (!str) return '';
    return str
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
}

export const setWithExpiry = (key, value, ttl) => {
    const now = new Date();

    // `item` adalah objek yang berisi nilai dan waktu kedaluwarsa
    const item = {
        value: value,
        expiry: now.getTime() + ttl,
    };
    localStorage.setItem(key, JSON.stringify(item));
};

export const getWithExpiry = (key) => {
    const itemStr = localStorage.getItem(key);

    // Jika item tidak ditemukan, kembalikan null
    if (!itemStr) {
        return null;
    }

    const item = JSON.parse(itemStr);
    const now = new Date();

    // Periksa apakah item sudah kedaluwarsa
    if (now.getTime() > item.expiry) {
        // Jika sudah kedaluwarsa, hapus item dari `localStorage` dan kembalikan null
        localStorage.removeItem(key);
        return null;
    }

    return item.value;
};
