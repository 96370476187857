import React, { useEffect, useMemo, useState } from "react";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Button, Dimmer, Dropdown, Form, FormDropdown, FormField, FormGroup, FormInput, Icon, Input, Loader, Popup } from 'semantic-ui-react'
import { useMyGlobal } from "../../component/Global";
import axios from "axios";
import { Toaster } from "../../component/Toaster";
import Modal from "rsuite/Modal";
import { applyDefaultFilter, Reacttable } from "../../component/Reacttable";
import { Stack } from "react-bootstrap";


export const CoaGroup = () => {
    const [modalAdd, setModalAdd] = useState(false)
    const [modalEdit, setModalEdit] = useState(false)
    const { userProfile, host } = useMyGlobal()
    const [groupData, setGroupData] = useState([])
    const [loadData, setLoadData] = useState(false)
    const [groupCode, setGroupCode] = useState('')
    const [groupName, setGroupName] = useState('')
    const [groupDesc, setGroupDesc] = useState('')
    const [groupId, setGroupId] = useState('')
    const [selGroup, setSelGroup] = useState(null)
    const [loadProc, setLoadProc] = useState(false)

    const columns = useMemo(() => applyDefaultFilter([
        {
            Header: "Code",
            accessor: "group_code"
        },
        {
            Header: "Name",
            accessor: "group_name"
        },
        {
            Header: "Description",
            accessor: "description"
        },
        {
            Header: "User",
            accessor: "user_name",
            Cell: ({ cell }) => (
                <Stack direction="vertical">
                    <div style={{ lineHeight: '0.8em' }}>{cell.value}</div>
                    <div className="text-muted" style={{ lineHeight: '1em' }}>{cell.row.original.role_name}</div>
                </Stack>
            )
        },
        {
            Header: "Create At",
            accessor: "createAt"
        },
        {
            Header: "Update At",
            accessor: "updateAt"
        },
        {
            Header: "Action",
            accessor: null,
            Cell: ({ cell }) => (
                <Button.Group basic size="mini">
                    <Popup size="tiny" content="Edit" trigger={
                        <Button icon={{ name: 'edit', color: 'blue' }} onClick={() => handleClickEdit(cell.row.original)} />
                    } />
                </Button.Group>
            )
        },
    ]))

    const handleClickAdd = () => {
        setModalAdd(true)
    }

    const handleClickEdit = (data) => {
        setSelGroup(data)
        setGroupCode(data.group_code)
        setGroupName(data.group_name)
        setGroupDesc(data.description)
        setModalEdit(true)
        setGroupId(data.id)
    }

    const getGroup = async () => {
        setLoadData(true)
        await axios({
            method: "GET",
            url: `${host}/ledger/index.php`,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            params: {
                action: 'getCoaGroup'
            }
        }).then(res => {
            if (res.data.status === 200) {
                setGroupData(res.data.data)
            }
        }).finally(() => setLoadData(false))
    }

    const handleChangeCode = (e) => {
        setGroupCode(e.target.value)
    }

    const clearForm = () => {
        setGroupCode('')
        setGroupName('')
        setGroupDesc('')
    }

    const handleGroupName = (e) => {
        setGroupName(e.target.value)
    }

    const handleSubmit = () => {
        setLoadProc(true)

        const existCode = groupData.some(i => i.group_code === groupCode)
        const existName = groupData.some(i => i.group_name.toLowerCase() === groupName.toLowerCase())

        if (existCode) {
            Toaster.fire({
                icon: 'warning',
                text: 'Group code exist',
                position: 'top-right',
            })
            setLoadProc(false)
        }

        if (existName) {
            Toaster.fire({
                icon: 'warning',
                text: 'Group name exist',
                position: 'top-right',
            })
            setLoadProc(false)
        }

        if (!existName && !existCode) {
            const param = {
                code: groupCode,
                name: groupName,
                desc: groupDesc,
                id_user: userProfile.profile.id_user
            }

            axios({
                method: "POST",
                url: `${host}/ledger/index.php?action=addGroup`,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
                },
                data: param
            }).then(res => {
                if (res.data.status === 200) {
                    Toaster.fire({
                        icon: 'success',
                        text: res.data.msg,
                        position: 'top-right'
                    })
                    clearForm()
                    setModalAdd(false)
                    getGroup()
                } else {
                    Toaster.fire({
                        icon: 'error',
                        text: res.data.msg,
                        position: 'top-right'
                    })
                }
            }).finally(() => setLoadProc(false))
        }
    }

    const handleSubmitEdit = () => {

        const existCode = groupData.some(i => i.group_code === groupCode)
        const existName = groupData.some(i => i.group_name.toLowerCase() === groupName.toLowerCase())

        if (existCode && selGroup.group_code !== groupCode) {
            Toaster.fire({
                icon: 'warning',
                text: 'Group code exist',
                position: 'top-right',
            })
            setLoadProc(false)
        } else if (existName && selGroup.group_name !== groupName) {
            Toaster.fire({
                icon: 'warning',
                text: 'Group name exist',
                position: 'top-right',
            })
            setLoadProc(false)
        } else {
            setLoadProc(true)

            const param = {
                code: groupCode,
                name: groupName,
                desc: groupDesc,
                id: groupId,
                id_user: userProfile.profile.id_user
            }

            axios({
                method: "PUT",
                url: `${host}/ledger/index.php?action=editGroup`,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
                },
                data: param
            }).then(res => {
                if (res.data.status === 200) {
                    Toaster.fire({
                        icon: 'success',
                        text: res.data.msg,
                        position: 'top-right'
                    })
                    clearForm()
                    setModalEdit(false)
                    getGroup()
                } else {
                    Toaster.fire({
                        icon: 'error',
                        text: res.data.msg,
                        position: 'top-right'
                    })
                }
            }).finally(() => setLoadProc(false))
        }
    }

    useEffect(() => {
        getGroup()
    }, [])

    return (
        <>
            <Container>
                <div className="mb-3 mt-2">
                    <div className="h4">Group COA</div>
                </div>
                <Row className="d-flex justify-content-center">
                    <Dimmer active={loadData} inverted>
                        <Loader inverted>Loading...</Loader>
                    </Dimmer>
                    <Col xl={8} lg={10} md={12} className="mb-4">
                        <Button content="Add New Group" icon="add" onClick={handleClickAdd} color="blue" />
                    </Col>
                    <Col xl={8} lg={10} md={12} className="mb-3">
                        <Reacttable
                            data={groupData}
                            columns={columns}
                            pageRow={10}
                            noData={groupData.length === 0 ? true : false}
                        />
                    </Col>
                </Row>
            </Container>

            <Modal open={modalAdd} onClose={() => setModalAdd(false)} overflow={false} backdrop="static" size="xs">
                <Modal.Header>
                    <Modal.Title>Add New COA Group</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="frmAdd" onSubmit={handleSubmit}>
                        <Form.Group grouped>
                            <FormInput
                                label="Group Code"
                                value={groupCode}
                                maxLength="10"
                                required
                                onChange={handleChangeCode}
                            />
                            <FormInput
                                label="Group Name"
                                value={groupName}
                                maxLength="30"
                                required
                                onChange={handleGroupName}
                            />
                            <FormInput
                                label="Group Description"
                                value={groupDesc}
                                required
                                maxLength="50"
                                onChange={(e) => setGroupDesc(e.target.value)}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Submit" icon="save" color="blue" form="frmAdd" type="submit" loading={loadProc} disabled={loadProc} />
                </Modal.Footer>
            </Modal>

            <Modal open={modalEdit} onClose={() => setModalEdit(false)} overflow={false} backdrop="static" size="xs">
                <Modal.Header>
                    <Modal.Title>Edit COA Group</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="frmEdit" onSubmit={handleSubmitEdit}>
                        <Form.Group grouped>
                            <FormInput
                                label="Group Code"
                                value={groupCode}
                                maxLength="10"
                                required
                                onChange={handleChangeCode}
                            />
                            <FormInput
                                label="Group Name"
                                value={groupName}
                                maxLength="30"
                                required
                                onChange={handleGroupName}
                            />
                            <FormInput
                                label="Group Description"
                                value={groupDesc}
                                required
                                maxLength="50"
                                onChange={(e) => setGroupDesc(e.target.value)}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Submit" icon="save" color="blue" form="frmEdit" type="submit" loading={loadProc} disabled={loadProc} />
                </Modal.Footer>
            </Modal>

        </>
    )
}