import React, { useEffect, useMemo, useRef, useState } from "react";
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import axios from "axios";
import Modal from "rsuite/Modal"
import { Button, Dimmer, Divider, Form, Icon, Input, Loader, Popup } from "semantic-ui-react";
import { useMyGlobal } from "../component/Global";
import DateRangePicker from 'rsuite/DateRangePicker';
import moment from "moment";
import { Reacttable } from "../component/Reacttable";
import { curencyNoSym } from "../component/utils";
import { Toaster } from "../component/Toaster";
import Stack from "react-bootstrap/Stack";
import Badge from "rsuite/Badge";
import Toggle from 'rsuite/Toggle';

export const ProductDiscount = () => {
    const [modalNewDsc, setModalNewDsc] = useState(false)
    const [modalProduct, setModalProduct] = useState(false)
    const [modalDetail, setModalDetail] = useState(false)
    const [modalEdit, setModalEdit] = useState(false)
    const { host, userProfile, showroomOpt } = useMyGlobal()
    const [source, setSource] = useState([])
    const tableRef = useRef()
    const [selectedItem, setSelectedItem] = useState([])
    const [selectedDiscount, setSelectedDiscount] = useState(null)
    const [idDiscount, setIdDiscount] = useState('')
    const [discountValue, setDiscountValue] = useState(0)
    const [discountValueEdit, setDiscountValueEdit] = useState(0)
    const [showroom, setShowroom] = useState([])
    const [browseBtn, setBrowseBtn] = useState(true)
    const [discountData, setDiscountData] = useState([])
    const [loadingArt, setLoadingArt] = useState(false)
    const [loadDsc, setLoadDsc] = useState(false)

    const [dateRange, setDateRange] = useState([
        new Date(moment().startOf('month')),
        new Date(moment()),
    ])
    const [dateRangeEdit, setDateRangeEdit] = useState([
        new Date(moment().startOf('month')),
        new Date(moment()),
    ])

    const columnsSource = useMemo(() => [
        {
            Header: "Brand",
            accessor: "brand"
        },
        {
            Header: "Article",
            accessor: "article"
        },
        {
            Header: "Description",
            accessor: "description"
        },
        {
            Header: "Price",
            accessor: "price",
            Cell: ({ cell }) => (
                curencyNoSym.format(cell.value)
            )
        },
        {
            Header: "Qty",
            accessor: "qty"
        },
    ])

    const columnsSelected = useMemo(() => [
        {
            Header: "Brand",
            accessor: "brand"
        },
        {
            Header: "Article",
            accessor: "article"
        },
        {
            Header: "Description",
            accessor: "description"
        },
        {
            Header: "Price",
            accessor: "price", Cell: ({ cell }) => (
                curencyNoSym.format(cell.value)
            )
        },
        {
            Header: "Qty",
            accessor: "qty"
        },
        {
            Header: "Action",
            accessor: null,
            Cell: ({ cell }) => (
                <Popup content="Remove" size="tiny" trigger={
                    <Icon name="close" color="red" onClick={() => handleRemove(cell.row.original)} style={{cursor:'pointer'}}/>
                } />
            )
        }
    ])

    const columnsDataDiscount = useMemo(() => [
        {
            Header: "ID Discount",
            accessor: "header.id_discount"
        },
        {
            Header: "Showroom",
            accessor: "header.showroom_name"
        },
        {
            Header: "Warehouse",
            accessor: "header.warehouse_name"
        },
        {
            Header: "Start",
            accessor: "header.start_date"
        },
        {
            Header: "End",
            accessor: "header.end_date"
        },
        {
            Header: "Discount",
            accessor: "header.discount_value",
            Cell: ({ cell }) => (cell.value + ' ' + '%')
        },
        {
            Header: "Active",
            accessor: "header.discount_state",
            Cell: ({ cell }) => (
                <Toggle size="sm" defaultChecked={cell.value === '1' ? true : false} onChange={() => handleChangeStatus(cell.value, cell.row.original.header.id_discount)} />
            )
        },
        {
            Header: "Status",
            accessor: "header.status",
            Cell: ({ cell }) => (
                <>
                    <Badge className="me-1" color={cell.value === 'running' ? 'green' : cell.value === 'expired' ? 'orange' : cell.value === 'upcoming' ? 'blue' : ''} /><span className="text-capitalize">{cell.value}</span>
                </>
            )
        },
        {
            Header: "User",
            accessor: "header.user_name",
            Cell: ({ cell }) => (
                <Stack direction="vertical">
                    <div>{cell.value}</div>
                    <div className="text-muted" style={{ lineHeight: '0.6em' }}>{cell.row.original.header.role_name}</div>
                </Stack>
            )
        },
        {
            Header: "Action",
            accessor: null,
            Cell: ({ cell }) => (
                <Button.Group size="mini" basic >
                    <Popup content="View Detail" size="tiny" trigger={
                        <Button icon={{ name: 'list layout', color: 'blue' }} onClick={() => handleClickDetail(cell.row.original)} />
                    } />
                    <Popup content="Edit" size="tiny" trigger={
                        <Button icon={{ name: 'edit', color: 'blue' }} onClick={() => handleClickEdit(cell.row.original)} />
                    } />
                </Button.Group>
            )
        },

    ])


    const columnsDetailDiscount = useMemo(() => [
        {
            Header: "Article",
            accessor: "id_product"
        },
        {
            Header: "Description",
            accessor: "description",
            Cell: ({ cell }) => (
                <div className="text-truncate" style={{ width: '180px' }}>{cell.value}</div>
            )
        },
        {
            Header: "Brand",
            accessor: "brand"
        },
        {
            Header: "Price",
            accessor: "price",
            Cell: ({ cell }) => (
                curencyNoSym.format(cell.value)
            )
        },
        {
            Header: "Discount",
            accessor: "discounted",
            Cell: ({ cell }) => (
                curencyNoSym.format(cell.value)
            )
        },
        {
            Header: "Final Price",
            accessor: "final_price",
            Cell: ({ cell }) => (
                curencyNoSym.format(cell.value)
            )
        },
        {
            Header: "Action",
            accessor: null,
            Cell: ({ cell }) => (
                <Button.Group size="mini" basic>
                    <Popup content="Delete" size="tiny" trigger={
                        <Button icon={{ name: 'trash alternate', color: 'blue' }} onClick={() => handleRemoveItem(cell.row.original)} />
                    } />
                </Button.Group>
            )
        },
    ])

    const handleChangeStatus = (value, idDisc) => {
        const status = value === '1' ? 0 : 1
        axios({
            method: "PUT",
            url: `${host}/product/index.php?action=setStatusDiscount`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            data: { id_discount: idDisc, status: status },
        }).then(res => {
            if (res.data.status === 200) {
                Toaster.fire({
                    icon: 'success',
                    text: 'Success change discount status',
                    position: 'top-right'
                })
                getProductDiscount()
            } else {
                Toaster.fire({
                    icon: 'error',
                    text: res.data.msg,
                    position: 'top-right'
                })
            }
        })
    }

    const handleClickEdit = (data) => {
        setIdDiscount(data.header.id_discount)
        setDiscountValueEdit(data.header.discount_value)
        setModalEdit(true)
    }

    const handleSubmitEdit = () => {
        const formatDate = (date) => moment(date).format('YYYY-MM-DD');
        const [startDate, endDate] = dateRangeEdit.map(date => formatDate(date));
        const date_range = [startDate, endDate];
        const newData = {
            id_discount: idDiscount,
            start: date_range[0],
            end: date_range[1],
            discount_value: discountValueEdit,
        }

        axios({
            method: "PUT",
            url: `${host}/product/index.php?action=editDiscount`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            data: newData,
        }).then(res => {
            if (res.data.status === 200) {
                Toaster.fire({
                    icon: 'success',
                    text: 'Success update data',
                    position: 'top-right'
                })
                getProductDiscount()
                setModalEdit(false)
            } else {
                Toaster.fire({
                    icon: 'error',
                    text: res.data.msg,
                    position: 'top-right'
                })
            }
        })
    }

    const handleClickDetail = (data) => {
        setSelectedDiscount(data)
        setModalDetail(true)
    }

    const handleRemove = (data) => {
        const newSelect = selectedItem.filter(i => i.article !== data.article)
        setSelectedItem(newSelect)
    }

    const handleGetSelected = () => {
        if (tableRef.current) {
            const selectedRows = tableRef.current.getSelectedRows()
            const newRows = selectedRows.filter(row =>
                !selectedItem.some(selected => selected.article === row.article && selected.size === row.size)
            )
            setSelectedItem(prevSelectedItem => [...prevSelectedItem, ...newRows])
            setModalProduct(false)
        }
    }

    const handleNewDsc = () => {
        setModalNewDsc(true)
    }

    const handleCloseNewDsc = () => {
        setModalNewDsc(false)
    }

    const handleChangeDate = (e) => {
        setDateRange(e)
    }

    const handleChangeDateEdit = (e) => {
        setDateRangeEdit(e)
    }

    const handleChangeShowroom = (idSh) => {
        setShowroom(idSh)
        setBrowseBtn(false)
        setSelectedItem([])
    }

    const stockByShowroom = async (showrooms) => {
        setLoadingArt(true)
        await axios({
            method: "GET",
            url: `${host}/stock/index.php`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            params: {
                action: 'stockByShowroomArr',
                id_showroom: showrooms
            }
        }).then(res => {
            const groupDataByArticle = (data) => {
                const groupedData = data.reduce((acc, curr) => {
                    const { article, qty } = curr
                    if (!acc[article]) {
                        acc[article] = { ...curr, qty: Number(qty) }
                    } else {
                        acc[article].qty += Number(qty)
                    }
                    return acc
                }, {});
                return Object.values(groupedData)
            }

            if (res.data.status === 200) {
                setSource(groupDataByArticle(res.data.data))
            }
        }).finally(() => setLoadingArt(false))
    }

    const handleRemoveItem = (data) => {
        const param = {
            id_warehouse: data.id_warehouse,
            id_showroom: data.id_showroom,
            article: data.id_product
        }
        axios({
            method: "DELETE",
            url: `${host}/product/index.php?action=removeDiscountItem`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            data: param
        }).then(res => {
            if (res.data.status === 200) {
                Toaster.fire({
                    icon: 'success',
                    text: 'Success delete item',
                    position: 'top-right',
                })
            } else {
                Toaster.fire({
                    icon: 'error',
                    text: res.data.msg,
                    position: 'top-right',
                })
            }
        })
    }

    const clearForm = () => {
        setDateRange([
            new Date(moment().startOf('month')),
            new Date(moment()),
        ])
        setDiscountValue(0)
        setShowroom([])
        setSelectedItem([])
    }

    const handleSubmit = () => {
        const formatDate = (date) => moment(date).format('YYYY-MM-DD');
        const [startDate, endDate] = dateRange.map(date => formatDate(date));
        const date_range = [startDate, endDate];
        const header = JSON.stringify({
            discount: discountValue,
            showroom: showroom,
            date_range: date_range,
            user: userProfile.profile.id_user
        })

        const detail = JSON.stringify(selectedItem)
        const param = new FormData()
        param.append('header', header)
        param.append('details', detail)

        axios({
            method: "POST",
            url: `${host}/product/index.php?action=createDiscountArr`,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            data: param,
        }).then(res => {
            if (res.data.status === 200) {
                Toaster.fire({
                    icon: 'success',
                    text: 'Success creating new discount',
                    position: 'top-right'
                })
                getProductDiscount()
                clearForm()
                setModalNewDsc(false)
            } else {
                Toaster.fire({
                    icon: 'error',
                    text: res.data.msg,
                    position: 'top-right'
                })
            }
        })

    }

    const getProductDiscount = async () => {
        setLoadDsc(true)
        axios({
            method: "GET",
            url: `${host}/product/index.php?action=productDiscount`,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
        }).then(res => {
            console.log(res.data.data)
            if (res.data.status === 200) {
                setDiscountData(res.data.data)
            }
        }).finally(() => setLoadDsc(false))
    }

    const handleClickBrowse = () => {
        if (showroom.length > 0) {
            stockByShowroom(showroom)
            setModalProduct(true)
        } else {
            alert("Please select showroom first")
        }
    }

    useEffect(() => {
        getProductDiscount()
    }, [])

    return (
        <>
            <Container>
                <div className="mb-3 mt-2">
                    <div className="h4">Discount</div>
                </div>
                <Dimmer active={loadDsc} inverted>
                    <Loader inverted>Loading</Loader>
                </Dimmer>
                <Row className="d-flex justify-content-center">
                    <Col xl={10} lg={12} md={12} sm={12} className="mb-3">
                        <Button content="Create New Discount" icon="add" onClick={handleNewDsc} color="blue" />
                    </Col>
                    <Col xl={10} lg={12} md={12} sm={12}>
                        <Reacttable data={discountData} columns={columnsDataDiscount} pageRow={10} noData={discountData.length === 0 ? true : false} />
                    </Col>
                </Row>
            </Container>

            <Modal open={modalNewDsc} onClose={() => handleCloseNewDsc()} overflow={false} backdrop="static" size="lg">
                <Modal.Header>
                    <Modal.Title>Create New Discount</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col lg={12} className="mb-3">
                            <Form id="frmDsc" onSubmit={handleSubmit}>
                                <Form.Group>
                                    <Form.Input
                                        className="small"
                                        width={3}
                                        label="Discount"
                                        type="number"
                                        icon="percent"
                                        value={discountValue}
                                        onChange={(e) => setDiscountValue(e.target.value)}
                                    />
                                    <Form.Dropdown
                                        className="small"
                                        width={9}
                                        value={showroom}
                                        options={showroomOpt}
                                        selection
                                        label="Showroom"
                                        size="mini"
                                        multiple
                                        onChange={(e, { value }) => handleChangeShowroom(value)}
                                    />
                                    <Form.Field>
                                        <div className="fw-bold mb-1" style={{ fontSize: '0.9em' }}>Periode</div>
                                        <DateRangePicker
                                            className="small"
                                            appearance="subtle"
                                            format="yyyy-MM-dd"
                                            placement="auto"
                                            cleanable={false}
                                            defaultValue={dateRange}
                                            value={dateRange}
                                            onOk={handleChangeDate}
                                            style={{ border: 'none' }}
                                            onClean={() => setDateRange([
                                                new Date(moment().startOf('month')),
                                                new Date(moment()),
                                            ])}
                                        />
                                    </Form.Field>
                                </Form.Group>
                            </Form>
                            <Button content="Browse Product" icon="search" disabled={browseBtn} onClick={handleClickBrowse} color="orange" circular />
                        </Col>
                        <Col lg={12}>
                            <Reacttable data={selectedItem} columns={columnsSelected} pageRow={10} noData={selectedItem.length === 0 ? true : false} />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" content="Create Discount" form="frmDsc" labelPosition="right" icon="arrow alternate circle right outline" color="blue" />
                </Modal.Footer>
            </Modal>

            <Modal open={modalProduct} onClose={() => setModalProduct(false)} backdrop="static" overflow={false}>
                <Modal.Header>
                    <Modal.Title>Product List</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Dimmer active={loadingArt} inverted>
                        <Loader inverted>Loading</Loader>
                    </Dimmer>
                    <Reacttable data={source.filter(cs => !selectedItem.some(si => si.article === cs.article))} columns={columnsSource} pageRow={10} checkbox={true} ref={tableRef} noData={source.length === 0 ? true : false} />
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Add Selected Article" onClick={handleGetSelected} color="blue" icon="plus" />
                </Modal.Footer>
            </Modal>

            <Modal open={modalDetail} onClose={() => setModalDetail(false)} overflow={false} size="md">
                <Modal.Header>
                    <Modal.Title>Product Discount</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col lg={6} style={{ fontSize: '0.9em' }} className="mb-3">
                            <Stack direction="horizontal">
                                <Stack direction="vertical">
                                    <div>ID Discount</div>
                                    <div>Discount Periode</div>
                                    <div>Discount Percent</div>
                                    <div>Status</div>
                                </Stack>
                                <Stack direction="vertical" className="fw-bold">
                                    <div>: {selectedDiscount?.header.id_discount}</div>
                                    <div>: {selectedDiscount?.header.start_date} - {selectedDiscount?.header.end_date}</div>
                                    <div>: {selectedDiscount?.header.discount_value} %</div>
                                    <div>: <Badge color={selectedDiscount?.header.status === 'running' ? 'green' : selectedDiscount?.header.status === 'expired' ? 'orange' : selectedDiscount?.header.status === 'upcoming' ? 'blue' : ''} className="me-1 " /><span className="text-capitalize">{selectedDiscount?.header.status}</span></div>
                                </Stack>
                            </Stack>
                        </Col>
                        <Col lg={12}>
                            <Reacttable data={selectedDiscount?.detail} columns={columnsDetailDiscount} pageRow={10} />
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

            <Modal open={modalEdit} onClose={() => setModalEdit(false)} overflow={false} backdrop="static" size="xs">
                <Modal.Header>
                    <Modal.Title>Edit Selected Discount</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col lg={12} className="mb-4">
                            <Form id="frmEditDsc" onSubmit={handleSubmitEdit}>
                                <Form.Input
                                    label="Discount"
                                    type="number"
                                    icon="percent"
                                    value={discountValueEdit}
                                    onChange={(e) => setDiscountValueEdit(e.target.value)}
                                />
                                <div className="fw-bold mb-1" style={{ fontSize: '0.9em' }}>Periode</div>
                                <DateRangePicker
                                    appearance="subtle"
                                    format="yyyy-MM-dd"
                                    placement="auto"
                                    cleanable={false}
                                    defaultValue={dateRangeEdit}
                                    value={dateRangeEdit}
                                    onOk={handleChangeDateEdit}
                                    style={{ border: 'none', width: '100%' }}
                                    onClean={() => setDateRangeEdit([
                                        new Date(moment().startOf('month')),
                                        new Date(moment()),
                                    ])}
                                />
                            </Form>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Save Change" type="submit" icon="edit" form="frmEditDsc" color="blue" />
                </Modal.Footer>
            </Modal>
        </>
    )
}