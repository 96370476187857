import React, { useEffect, useMemo, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import { Button, Dimmer, Form, FormDropdown, FormInput, Loader, Popup } from "semantic-ui-react";
import { Modal } from "rsuite";
import { useMyGlobal } from "../component/Global";
import { Toaster } from "../component/Toaster";
import { Reacttable } from "../component/Reacttable";
import Toggle from "rsuite/Toggle"

export const Showroom = () => {
    const [modalAdd, setModalAdd] = useState(false)
    const [modalEdit, setModalEdit] = useState(false)
    const [name, setName] = useState('')
    const [abr, setAbr] = useState('')
    const [city, setCity] = useState('')
    const [province, setProvince] = useState('')
    const { cityOpt, provOpt, host } = useMyGlobal()
    const [address, setAddress] = useState('')
    const [supervisor, setSupervisor] = useState('')
    const [manager, setManager] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [warehouse, setWarehouse] = useState('')
    const [category, setCategory] = useState('Mono Brand')
    const [status, setStatus] = useState('Y')
    const [warehouseOpt, setWarehouseOpt] = useState([])
    const [idShowroom, setIdShowroom] = useState('')
    const [loaderAdd, setLoaderAdd] = useState(false)
    const [loaderEdit, setLoaderEdit] = useState(false)
    const [dataShowroom, setDataShowroom] = useState([])
    const [spvPass, setSpvPass] = useState('')
    const [cfmSpvPass, setCfmSpvPass] = useState('')
    const [currentPass, setCurrentPass] = useState('')
    const [modalAddPasskey, setModalAddPasskey] = useState(false)
    const [selectedRow, setSelectedRow] = useState(null)
    const [loader, setLoader] = useState(false)
    const [groupName, setGroupName] = useState('')
    const [groupOpt, setGroupOpt] = useState([])

    const columns = useMemo(() => [
        {
            Header: "ID",
            accessor: "id_showroom"
        },
        {
            Header: "Group",
            accessor: "group_name"
        },
        {
            Header: "Name",
            accessor: "showroom_name"
        },
        {
            Header: "Abr",
            accessor: "showroom_abr"
        },
        {
            Header: "Category",
            accessor: "category"
        },
        {
            Header: "City",
            accessor: "city"
        },
        {
            Header: "Phone",
            accessor: "phone"
        },
        {
            Header: "Active",
            accessor: "status",
            Cell: ({ cell }) => (
                <Toggle size="sm" defaultChecked={cell.value === 'Y' ? true : false} onChange={() => handleChangeStatus(cell.value, cell.row.original.id_showroom)} />
            )
        },
        {
            Header: "Action",
            accessor: null,
            Cell: ({ cell }) => (
                <Button.Group basic size="mini">
                    <Popup content="Edit" size="mini" trigger={
                        <Button icon={{ name: 'edit outline', color: 'blue' }} onClick={() => handleClickEdit(cell.row.original)} />
                    } />
                    <Popup content={cell.row.original.spv_pwd ? "Edit Passkey" : "Add Passkey"} size="mini" trigger={
                        <Button icon={{ name: 'key', color: cell.row.original.spv_pwd ? 'blue' : 'orange' }} onClick={() => handleClickPasskey(cell.row.original)} />
                    } />
                </Button.Group>
            )
        },

    ])

    const handleClickPasskey = (data) => {
        setModalAddPasskey(true)
        setSelectedRow(data)
    }

    const handleChangeStatus = (value, idShowroom) => {
        const status = value === 'Y' ? 'N' : 'Y'
        axios({
            method: "PUT",
            url: `${host}/showroom/index.php`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            data: { id_showroom: idShowroom, status: status, action: 'changeStatus' },
        }).then(res => {
            if (res.data.status === 200) {
                Toaster.fire({
                    icon: 'success',
                    text: 'Success change status',
                    position: 'top-right'
                })
            } else {
                Toaster.fire({
                    icon: 'error',
                    text: res.data.msg,
                    position: 'top-right'
                })
            }
        })
    }

    const handleClickAdd = () => {
        setModalAdd(true)
        getDataWarehouse()
    }

    const handleChangeCity = (id) => {
        setCity(id)
        setProvince(provOpt?.find(item => item.value === id).text)
    }

    const getDataWarehouse = async () => {
        await axios({
            method: "GET",
            url: `${host}/showroom/index.php?action=warehouse`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            }
        }).then(res => {
            if (res.data.status === 200) {
                setWarehouseOpt(res.data.data.map(i => {
                    return {
                        key: i.id_warehouse,
                        text: i.desc,
                        value: i.id_warehouse,
                    }
                }))
            }
        })
    }

    const getDataShowroom = async () => {
        setLoader(true)
        await axios({
            method: "GET",
            url: `${host}/showroom/index.php?action=showroom`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            }
        }).then(res => {
            if (res.data.status === 200) {
                setDataShowroom(res.data.data)
            }
        }).finally(() => setLoader(false))
    }

    const handleChangeCat = (e, { value }) => setCategory(value)
    const handleChangeType = (e, { value }) => setStatus(value)

    const handleSubmit = () => {
        if (spvPass === cfmSpvPass) {
            setLoaderAdd(true)
            const param = new FormData()

            param.append('id_warehouse', warehouse)
            param.append('prov', province)
            param.append('city', cityOpt?.find(item => item.value === city).text)
            param.append('abr', abr)
            param.append('name', name)
            param.append('category', category)
            param.append('status', status)
            param.append('address', address)
            param.append('phone', phone)
            param.append('email', email)
            param.append('supervisor', supervisor)
            param.append('manager', manager)
            param.append('action', 'addShowroom')
            param.append('spv_pwd', spvPass)

            axios({
                method: 'POST',
                url: `${host}/showroom/index.php?action=addShowroom`,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
                },
                data: param
            }).then(res => {
                setLoaderAdd(false)
                if (res.data.status === 200) {
                    Toaster.fire({
                        icon: 'success',
                        text: 'Success adding new showroom',
                        position: 'top-right'
                    })
                    setModalAdd(false)
                    getDataShowroom()
                } else {
                    Toaster.fire({
                        icon: 'error',
                        text: 'Failed adding new showroom',
                        position: 'top-right'
                    })
                }
            })
        } else {
            Toaster.fire({
                icon: 'warning',
                text: 'SPV Passkey is inconsistent',
                position: 'top-right',
            })
        }
    }

    const handleClickEdit = (row) => {
        setIdShowroom(row.id_showroom)
        setWarehouse(row.id_warehouse)
        setName(row.showroom_name)
        setAbr(row.showroom_abr)
        setCity(cityOpt?.find(i => i.text === row.city).value)
        setProvince(row.province)
        setAddress(row.address)
        setSupervisor(row.supervisor)
        setPhone(row.phone)
        setEmail(row.email)
        setManager(row.manager)
        setCategory(row.category)
        setStatus(row.status)
        setSpvPass(row.spv_pwd)
        setCfmSpvPass(row.spv_pwd)
        setGroupName(row.group_name)

        setModalEdit(true)
    }

    const handleUpdate = () => {
        if (spvPass === cfmSpvPass) {
            setLoaderEdit(true)
            const param = {
                id_showroom: idShowroom,
                id_warehouse: warehouse,
                prov: province,
                city: cityOpt?.find(item => item.value === city).text,
                abr: abr,
                name: name,
                category: category,
                status: status,
                address: address,
                phone: phone,
                email: email,
                supervisor: supervisor,
                manager: manager,
                spv_pwd: spvPass,
                action: 'editShowroom',
                group: groupName
            }

            axios({
                method: 'PUT',
                url: `${host}/showroom/index.php?action=editShowroom`,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
                },
                data: param
            }).then(res => {
                setLoaderEdit(false)
                if (res.data.status === 200) {
                    Toaster.fire({
                        icon: 'success',
                        text: 'Success update showroom',
                        position: 'top-right'
                    })
                    setModalEdit(false)
                    getDataShowroom()
                } else {
                    Toaster.fire({
                        icon: 'error',
                        text: 'Failed update showroom',
                        position: 'top-right'
                    })
                }
            })
        } else {
            Toaster.fire({
                icon: 'warning',
                text: 'SPV Passkey is inconsistent',
                position: 'top-right',
            })
        }
    }

    const clearForm = () => {
        setSpvPass("")
        setCfmSpvPass("")
        setCurrentPass("")
    }

    const handleSubmitPasskey = () => {
        const param = {
            id_showroom: selectedRow.id_showroom,
            spv_pwd: spvPass,
            action: 'editAddPasskey'
        }

        const dataForm = new FormData()
        dataForm.append('id_showroom', selectedRow.id_showroom)
        dataForm.append('spv_pwd', currentPass)

        let pass = ''
        if (selectedRow?.spv_pwd) {
            axios({
                method: 'POST',
                url: `${host}/pos/?action=cekSpvPasskeyValid`,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
                },
                data: dataForm
            }).then(res => {
                pass = res.data.key

                if (selectedRow.spv_pwd === pass) {
                    if (spvPass !== "" && cfmSpvPass !== "") {
                        axios({
                            method: 'PUT',
                            url: `${host}/showroom/`,
                            headers: {
                                "Content-Type": "application/json",
                                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
                            },
                            data: param
                        }).then(res => {
                            if (res.data.status === 200) {
                                Toaster.fire({
                                    icon: 'success',
                                    text: 'Success update passkey',
                                    position: 'top-right'
                                })
                            }
                            getDataShowroom()
                            setModalAddPasskey(false)
                            clearForm()
                        })
                    } else {
                        Toaster.fire({
                            icon: 'warning',
                            text: 'Password cannot be empty',
                            position: 'top-right'
                        })
                    }
                } else {
                    Toaster.fire({
                        icon: 'error',
                        text: 'The previous Passkey is incorrect',
                        position: 'top-right'
                    })
                }

            })
        } else {
            if (spvPass !== "" && cfmSpvPass !== "") {
                axios({
                    method: 'PUT',
                    url: `${host}/showroom/`,
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
                    },
                    data: param
                }).then(res => {
                    if (res.data.status === 200) {
                        Toaster.fire({
                            icon: 'success',
                            text: 'Success update passkey',
                            position: 'top-right'
                        })
                    }
                    getDataShowroom()
                    setModalAddPasskey(false)
                    clearForm()
                })
            } else {
                Toaster.fire({
                    icon: 'warning',
                    text: 'Password cannot be empty',
                    position: 'top-right'
                })
            }
        }
    }

    useEffect(() => {
        getDataShowroom()
        const getGroup = async () => {
            setLoader(true)
            await axios({
                method: "GET",
                url: `${host}/showroom/index.php?action=showroomGroup`,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
                }
            }).then(res => {
                if (res.data.status === 200) {
                    setGroupOpt(res.data.data.map(i => {
                        return { key: i.id, text: i.group_name, value: i.group_name }
                    }))
                }
            }).finally(() => setLoader(false))
        }
        getGroup()
    }, [])

    return (
        <>
            <Container>
                <div className="mb-3 mt-2">
                    <div className="h4">Showroom</div>
                </div>
                <Dimmer active={loader} inverted>
                    <Loader inverted>Loading</Loader>
                </Dimmer>
                <Row className="d-flex justify-content-center">
                    <Col xl={10} lg={12} md={12} sm={12} className="mb-3">
                        <Button content="Add New Showroom" icon="add" onClick={handleClickAdd} color="blue" />
                    </Col>
                    <Col xl={10} lg={12} md={12} sm={12}>
                        <Reacttable data={dataShowroom} columns={columns} pageRow={10} noData={dataShowroom.length === 0 ? true : false} />
                    </Col>
                </Row>
            </Container>

            <Modal open={modalAdd} backdrop="static" onClose={() => setModalAdd(false)} overflow={false}>
                <Modal.Header>
                    <Modal.Title>Add New Showroom</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group grouped>
                            <Form.Dropdown
                                fluid
                                required
                                options={groupOpt}
                                label="Showroom Group"
                                selection
                                value={groupName}
                                onChange={(e, { value }) => setGroupName(value)}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Input type="text" value={name} label="Showroom Name" width={11} maxLength={50} placeholder="Hoops Homecourt" onChange={(e) => setName(e.target.value)} required />
                            <Form.Input type="text" value={abr} label="Abr" width={5} placeholder="HHCT" onChange={(e) => setAbr(e.target.value)} required />
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Select
                                search
                                options={cityOpt}
                                required={true}
                                value={city}
                                label="City"
                                onChange={(e, { value }) => handleChangeCity(value)}
                            />
                            <Form.Input type="text" value={province} label="Province" readOnly />
                        </Form.Group>
                        <Form.Input type="text" value={address} maxLength={100} label="Address" onChange={(e) => setAddress(e.target.value)} />
                        <Form.Group group widths="equal">
                            <Form.Input type="text" label="Supervisor" value={supervisor} onChange={(e) => setSupervisor(e.target.value)} />
                            <Form.Input type="text" label="Manager" value={manager} onChange={(e) => setManager(e.target.value)} />
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Input type="phone" label="Phone" value={phone} onChange={(e) => setPhone(e.target.value)} />
                            <Form.Input type="email" label="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Input type="password" label="SPV Passkey" value={spvPass} onChange={(e) => setSpvPass(e.target.value)} required />
                            <Form.Input type="password" label="Confirm SPV Passkey" value={cfmSpvPass} onChange={(e) => setCfmSpvPass(e.target.value)} required />
                        </Form.Group>
                        <Form.Select
                            required
                            search
                            options={warehouseOpt}
                            label="Warehouse"
                            value={warehouse}
                            onChange={(e, { value }) => setWarehouse(value)}
                        />
                        <Row>
                            <Col lg={6} md={6} sm={6} sx={12}>
                                <Form.Group grouped>
                                    <label>Category</label>
                                    <Form.Radio
                                        label="Mono Brand"
                                        value="Mono Brand"
                                        checked={category === "Mono Brand"}
                                        onChange={handleChangeCat}
                                    />
                                    <Form.Radio
                                        label="Multi Brand"
                                        value="Multi Brand"
                                        checked={category === "Multi Brand"}
                                        onChange={handleChangeCat}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={6} md={6} sm={6} sx={12}>
                                <Form.Group grouped>
                                    <label>Status</label>
                                    <Form.Radio
                                        label="Active"
                                        value="Y"
                                        checked={status === "Y"}
                                        onChange={handleChangeType}
                                    />
                                    <Form.Radio
                                        label="Inactive"
                                        value="N"
                                        checked={status === "N"}
                                        onChange={handleChangeType}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <div className="text-end mt-3">
                            <Button content="Save" color="blue" type="submit" icon="save outline" loading={loaderAdd} disabled={loaderAdd} />
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>

            <Modal open={modalEdit} backdrop="static" onClose={() => setModalEdit(false)} overflow={false}>
                <Modal.Header>
                    <Modal.Title>Edit Showroom</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleUpdate}>
                        <Form.Group grouped>
                            <Form.Dropdown
                                fluid
                                required
                                options={groupOpt}
                                label="Showroom Group"
                                selection
                                value={groupOpt.find(i => i.value === groupName)?.value}
                                onChange={(e, { value }) => setGroupName(value)}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Input type="text" value={name} label="Showroom Name" width={11} placeholder="Hoops Homecourt" onChange={(e) => setName(e.target.value)} required />
                            <Form.Input type="text" value={abr} label="Abr" width={5} placeholder="HHCT" onChange={(e) => setAbr(e.target.value)} required />
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Select
                                search
                                options={cityOpt}
                                required={true}
                                value={city}
                                label="City"
                                onChange={(e, { value }) => handleChangeCity(value)}
                            />
                            <Form.Input type="text" value={province} label="Province" readOnly />
                        </Form.Group>
                        <Form.Input type="text" value={address} label="Address" onChange={(e) => setAddress(e.target.value)} />
                        <Form.Group group widths="equal">
                            <Form.Input type="text" label="Supervisor" value={supervisor} onChange={(e) => setSupervisor(e.target.value)} />
                            <Form.Input type="text" label="Manager" value={manager} onChange={(e) => setManager(e.target.value)} />
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Input type="phone" label="Phone" value={phone} onChange={(e) => setPhone(e.target.value)} />
                            <Form.Input type="email" label="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </Form.Group>
                        <Form.Select
                            required
                            search
                            options={warehouseOpt}
                            label="Warehouse"
                            value={warehouse}
                            onChange={(e, { value }) => setWarehouse(value)}
                        />
                        <Row>
                            <Col lg={6} md={6} sm={6} sx={12}>
                                <Form.Group grouped>
                                    <label>Category</label>
                                    <Form.Radio
                                        label="Mono Brand"
                                        value="Mono Brand"
                                        checked={category === "Mono Brand"}
                                        onChange={handleChangeCat}
                                    />
                                    <Form.Radio
                                        label="Multi Brand"
                                        value="Multi Brand"
                                        checked={category === "Multi Brand"}
                                        onChange={handleChangeCat}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={6} md={6} sm={6} sx={12}>
                                <Form.Group grouped>
                                    <label>Status</label>
                                    <Form.Radio
                                        label="Active"
                                        value="Y"
                                        checked={status === "Y"}
                                        onChange={handleChangeType}
                                    />
                                    <Form.Radio
                                        label="Inactive"
                                        value="N"
                                        checked={status === "N"}
                                        onChange={handleChangeType}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <div className="text-end mt-3">
                            <Button content="Update" type="submit" icon="edit outline" loading={loaderEdit} disabled={loaderEdit} color="blue" />
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>

            <Modal open={modalAddPasskey} onClose={() => setModalAddPasskey(false)} backdrop="static" overflow={false} size="xs">
                <Modal.Header>
                    <Modal.Title>Edit / Add Passkey</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="editAddKey" onSubmit={handleSubmitPasskey}>
                        <Form.Group grouped>
                            {
                                selectedRow?.spv_pwd ?
                                    <FormInput type="password" label="Current Passkey" value={currentPass} onChange={(e) => setCurrentPass(e.target.value)} />
                                    : <></>
                            }
                            <FormInput type="password" label="New SPV Passkey" value={spvPass} onChange={(e) => setSpvPass(e.target.value)} />
                            <FormInput type="password" label="Confirm SPV Passkey" value={cfmSpvPass} onChange={(e) => setCfmSpvPass(e.target.value)} />
                        </Form.Group>
                        {!selectedRow?.spv_pwd ? <i>There is no passkey set in this showroom, please apply a new passkey.</i> : <></>}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" content="Apply Passkey" form="editAddKey" icon="save" color="blue" />
                </Modal.Footer>
            </Modal>

        </>
    )
}