import React, { useEffect, useMemo, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import axios from "axios";
import { useMyGlobal } from "../component/Global";
import Tabs from 'rsuite/Tabs';
import moment from "moment";
import { BsBagCheck, BsBagX } from "react-icons/bs";
import DateRangePicker from "rsuite/DateRangePicker";
import { Button, ButtonGroup, Dropdown, Form, Label, Popup } from "semantic-ui-react";
import { curencyNoSym } from "../component/utils";
import Badge from "rsuite/Badge";
import { applyDefaultFilter, Reacttable } from "../component/Reacttable";
import Modal from "rsuite/Modal"
import Table from "react-bootstrap/Table"
import { curency } from "../component/utils";
import { Toaster } from "../component/Toaster";
import ReactApexChart from "react-apexcharts";

export const ReportDiscountedPrd = () => {
    const { host, userProfile, showroomOpt } = useMyGlobal()
    const [dataPrd, setDataPrd] = useState(null)
    const [showroom, setShowroom] = useState([])
    const [filteredShr, setFilteredShr] = useState([])
    const [loadData, setLoadData] = useState(false)


    const [dateRange, setDateRange] = useState([
        new Date(moment().startOf('month')),
        new Date(moment()),
    ])

    const columns = useMemo(() => applyDefaultFilter([
        {
            Header: "Group",
            accessor: "group_name"
        },
        {
            Header: "Showroom",
            accessor: "showroom_name",
            Cell: ({ cell }) => (
                <div style={{ width: '100px' }}>
                    <Popup content={cell.value} size="tiny" trigger={
                        <div className="text-truncate">{cell.value}</div>
                    } />
                </div>
            )
        },
        {
            Header: "Date",
            accessor: "trx_date"
        },
        {
            Header: "Article",
            accessor: "article"
        },
        {
            Header: "Description",
            accessor: "description",
            Cell: ({ cell }) => (
                <div style={{ width: '100px' }}>
                    <Popup content={cell.value} size="tiny" trigger={
                        <div className="text-truncate">{cell.value}</div>
                    } />
                </div>
            )
        },
        {
            Header: "Size",
            accessor: "size"
        },
        {
            Header: "Qty",
            accessor: "qty"
        },
        {
            Header: "Price",
            accessor: "price",
            Cell: ({ cell }) => curencyNoSym.format(cell.value)
        },
        {
            Header: "Final Price",
            accessor: "final_price",
            Cell: ({ cell }) => curencyNoSym.format(cell.value)
        },
        {
            Header: "Add Dsc",
            accessor: "add_persentase",
            Cell: ({ cell }) => cell.value > 0 ? cell.value + '%' : ''
        },
        {
            Header: "Dsc",
            accessor: "dsc_persentase",
            Cell: ({ cell }) => cell.value > 0 ? cell.value + '%' : ''
        },
        {
            Header: "Add Dsc Desc.",
            accessor: "add_discount_desc",
        },
    ]))

    const columnsGroup = useMemo(() => applyDefaultFilter([
        {
            Header: "Group Name",
            accessor: "group_name"
        },
        {
            Header: "Showroom",
            accessor: "showroom_name"
        },
        {
            Header: "Add Discount",
            accessor: "add_persentase",
            Cell:({cell}) => cell.value != '' ? cell.value + '%' : '-'
        },
        {
            Header: "Discount",
            accessor: "dsc_persentase",
            Cell:({cell}) => cell.value != 0 ? cell.value + '%' : '-'
        },
        {
            Header: "Description",
            accessor: "add_discount_desc"
        },
        {
            Header: "Item's",
            accessor: "total"
        },
    ]))

    const getProductDsc = async () => {
        setLoadData(true)

        const formatDate = (date) => moment(date).format('YYYY-MM-DD')
        const [startDate, endDate] = dateRange.map(date => formatDate(date))
        const date_range = [startDate, endDate]

        await axios({
            method: "GET",
            url: `${host}/sales/index.php`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            params: {
                action: 'discountedReport',
                showroom: showroom,
                date_range: date_range,
            }
        }).then(res => {
            if (res.data.status === 200) {
                setDataPrd(res.data.data)
                Toaster.fire({
                    icon: 'success',
                    text: res.data.msg,
                    position: 'top-right',
                })
            } else {
                Toaster.fire({
                    icon: 'error',
                    text: res.data.msg,
                    position: 'top-right',
                })
            }
        }).finally(() => setLoadData(false))
    }

    const handleSelectShr = (value) => {
        setShowroom(value)
    }

    const handleChangeDate = (e) => {
        setDateRange(e)
    }

    const handleExc = () => {
        getProductDsc()
    }

    useEffect(() => {
        const filteredShr = showroomOpt.filter(opt =>
            userProfile.group.some(group => group.id_showroom === opt.value)
        )
        setFilteredShr(filteredShr)
    }, [userProfile])

    return (
        <>
            <Container>
                <div className="mb-3 mt-2">
                    <div className="h4">Sales By Discount</div>
                </div>
                <Row className="d-flex justify-content-center">
                    <Col xl={12} lg={12} md={12} sm={12} className="mb-3">
                        <DateRangePicker
                            className="mb-3"
                            label="Date Range : "
                            format="yyyy-MM-dd"
                            placement="bottomStart"
                            cleanable={true}
                            defaultValue={dateRange}
                            value={dateRange}
                            onChange={handleChangeDate}
                            onClean={() => setDateRange([
                                new Date(moment().startOf('month')),
                                new Date(moment()),
                            ])}
                        />
                        <Form onSubmit={handleExc}>
                            <Form.Group inline>
                                <Form.Dropdown
                                    style={{ width: '100%' }}
                                    placeholder="Select Showroom"
                                    options={filteredShr}
                                    selection
                                    value={showroom}
                                    clearable
                                    search
                                    multiple
                                    onChange={(e, { value }) => handleSelectShr(value)}
                                />
                                <Button content="Generate Data" loading={loadData} color="blue" icon="arrow alternate circle right" labelPosition="right" type="submit" disabled={showroom.length === 0 || loadData ? true : false} />
                            </Form.Group>
                        </Form>
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={12} className="mb-3" >
                        <Tabs defaultActiveKey="1" appearance="tabs">
                            <Tabs.Tab eventKey="1" title="Dicounted Product">
                                <Reacttable
                                    data={Array.isArray(dataPrd?.detailed) ? dataPrd.detailed : []}
                                    columns={columns}
                                    pageRow={10}
                                    noData={dataPrd ? Array.isArray(dataPrd?.detailed) && dataPrd.length === 0 : true}
                                    filterColumn
                                    totals={['qty', 'price', 'final_price']}
                                />
                            </Tabs.Tab>
                            <Tabs.Tab eventKey="2" title="Discount Group">
                                <Reacttable
                                    data={Array.isArray(dataPrd?.grouped) ? dataPrd.grouped : []}
                                    columns={columnsGroup}
                                    pageRow={10}
                                    filterColumn
                                    noData={dataPrd ? Array.isArray(dataPrd?.grouped) && dataPrd?.length === 0 : true}
                                />
                            </Tabs.Tab>
                        </Tabs>
                    </Col>
                </Row>
            </Container>
        </>
    )
}